import { FontIcon, IDetailsColumnProps } from "@fluentui/react";
import { ISorting } from "hooks/useSorting";
import { Sorting } from "constants/enums";

interface ISortingColumnHeaderProps {
  headerText: string;
  field: string;
  sorting: ISorting;
}

const SortingColumnHeader = (props: ISortingColumnHeaderProps) => {
  const { state, onSortingChange } = props.sorting;
  const onColumnHeaderClick = () => onSortingChange(props.field);
  return (
    <div onClick={onColumnHeaderClick}>
      {state.field === props.field ? (
        <FontIcon iconName={state.type === Sorting.DESC ? "SortUp" : "SortDown"} />
      ) : (
        <FontIcon iconName="Sort" />
      )}
      {props.headerText}
    </div>
  );
};

export const getSortingColumnHeader = (sorting: ISorting) => {
  const onRenderHeader = (props?: IDetailsColumnProps) => {
    return (
      <SortingColumnHeader
        field={props?.column.fieldName || ""}
        headerText={props?.column.name || ""}
        sorting={sorting}
      />
    );
  };
  return onRenderHeader;
};
