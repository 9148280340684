export const getDeviceZpl = (serial: string, company?: string) => {
  return `^XA^FO10,13^AEN,9,9^FD${company}^FS^FO25,50^BEN,70,500^FD${serial}^FS^PQ1^XZ`;
};

export const getDeviceBeltexZpl = (serial: string) => {
  return `^XA^FO50,20^A0,30,30^FDSerial No:^FS^FO30,50^BY2,3.0,10^BEN,80,Y,N^FD${serial}^FS^PQ2^XZ`;
};

export const getDeviceServiceZpl = (serial: string) => {
  const number = serial.slice(-9);
  return `^XA^FO30,15^ABN,15,15^FDSerial No:^FS^FO40,30^AEN,15,15^FD${number}^FS^FO80,65^BXN,7,200^FD${number}^FS^PQ1^XZ`;
};
