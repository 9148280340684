import classNames from "classnames";
import styles from "./styles.module.scss";

interface IChildrenProps {
  children: JSX.Element | JSX.Element[];
}

const BasicModalContent = (props: IChildrenProps) => {
  return <div className={classNames(styles.basicModalContent, "basic-modal-content")}>{props.children}</div>; 
};

const BasicModalColumns = (props: IChildrenProps) => {
  return <div className={styles.basicModalColumns}>{props.children}</div>;
};

const BasicModalColumn = (props: IChildrenProps) => {
  return <div className={styles.basicModalColumn}>{props.children}</div>;
};

export { BasicModalContent, BasicModalColumns, BasicModalColumn };
