import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { AxiosResponse } from "axios";
import { ConfirmModal } from "components/common/ConfirmModal";
import { useApi, useHttp } from "hooks";
import loc from "localization";

enum ActionUrl {
  Defect = "apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsDelete",
  Replaced = "apiOpDevicesDeviceDeviceIdFirmFirmIdReplacedCeDelete",
  Work = "apiOpDevicesDeviceDeviceIdFirmFirmIdWorksDelete",
  Battery = "apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdDelete",
  Added = "apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeDelete"
}

interface IDeleteModalProps {
  text: string;
  type: "defect" | "replaced" | "work" | "battery" | "added";
  basicPayload: { firmId: number; deviceId: number, batteryId?: number };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
  updateState: () => void;
  onDismiss: () => void;
}

export const DeleteModal = (props: IDeleteModalProps) => {
  const { devicesApi } = useApi();
  const deviceHttp = useHttp();
  const getAction = () => {
    if (props.type === "added") return { url: ActionUrl.Added, payloadName: "deviceDeleteAddedCEPayload"};
    if (props.type === "defect") return  { url: ActionUrl.Defect, payloadName: "deviceDeleteDefectsPayload" };
    if (props.type === "replaced") return { url: ActionUrl.Replaced, payloadName: "deviceDeleteReplacedCEPayload" };
    if (props.type === "work") {
      return { url: ActionUrl.Work, payloadName: "deviceDeleteWorksPayload" };
    } else {
      return { url: ActionUrl.Battery, payloadName: "deviceDeleteDefectsPayload" };
    };
  };
  const handleDelete = (actionUrl: ActionUrl, payloadName?: string) => {
    deviceHttp
      .request(() => devicesApi[actionUrl]({
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...props.basicPayload as any,
        ...(payloadName ? { [payloadName]: props.payload } : {})
      }))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          props.updateState();
          props.onDismiss();
        }
      });
  };
  const submitHandler = () => {
    const { url, payloadName } = getAction();
    if (url === ActionUrl.Battery) {
      handleDelete(url);
    } else {
      handleDelete(url, payloadName);
    };
  };
  return (
    <ConfirmModal
      isLoading={deviceHttp.isLoading}
      text={props.text}
      leftButton={<PrimaryButton text={loc.buttons.delete} onClick={submitHandler} />}
      rightButton={<DefaultButton text={loc.buttons.cancel} onClick={props.onDismiss} />}
      errors={deviceHttp.errorMessages}
      onDismiss={props.onDismiss}
    />
  );
};
