import { CommandbarCommands } from "constants/enums";
import { UserForBadgeBrowseDto } from "generated-sources/openapi";
import loc from "localization";

export const getColumns = () => [
  {
    key: "lastName",
    name: loc.columns.lastName,
    fieldName: "lastName",
    minWidth: 80,
    maxWidth: 80,
    isResizable: true,
  },
  {
    key: "firstName",
    name: loc.columns.firstName,
    fieldName: "firstName",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "tn",
    name: loc.columns.tabNumber,
    fieldName: "tn",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "company",
    name: loc.columns.company,
    fieldName: "company",
    minWidth: 90,
    maxWidth: 90,
    isResizable: true,
  },
  {
    key: "area",
    name: loc.columns.area,
    fieldName: "area",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
];

export const getActions = (selectedItem?: UserForBadgeBrowseDto) => [
  {
    key: "create",
    text: loc.buttons.create,
    command: CommandbarCommands.Create,
    disabled: false,
    display: true
  },
  {
    key: "donwload",
    text: loc.buttons.download,
    command: CommandbarCommands.Download,
    disabled: !selectedItem,
    display: true,
  },
];
