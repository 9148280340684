import { PositionType } from "components/common/Legend";
import { Marker } from "components/common/Marker";

export const getMarker = (
  display: boolean,
  text: string,
  color: string,
  isStripes?: boolean,
  position?: PositionType,
) => {
  return (
    display && (
      <Marker key={text} color={color} text={text} isStripes={isStripes} position={position} />
    )
  );
};
