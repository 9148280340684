import { Navigate } from "react-router-dom";
import { ACCESS_DENIED_PAGE_PATH } from "constants/paths";

interface IProtectedRouteProps {
  children: JSX.Element;
  isAllowed: boolean;
}

export const ProtectedRoute = (props: IProtectedRouteProps) => {
  if (!props.isAllowed) {
    return <Navigate to={ACCESS_DENIED_PAGE_PATH} />;
  }
  return props.children;
};
