import { FontIcon, TextField } from "@fluentui/react";
import { ceCodeMask } from "constants/strings";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";

interface ICodeCeInputProps {
  value: string;
  onChange: (val: string) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  reset?: boolean;
}

export const CodeCeInput = (props: ICodeCeInputProps) => {
  const [value, setValue] = useState(props.value);
  const onResetValueClick = () => {
    props.onChange("");
    setValue("");
  };
  const mask = "99.99999";

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <InputMask
      mask={mask}
      maskChar="-"
      value={value}
      onChange={(e: { target: { value: string; }; }) => {
        if (e.target.value === ceCodeMask) {
          props.onChange("");
        } else {
          props.onChange(e.target.value);
        }
        setValue(e.target.value);
      }}
    >
      <TextField
        className={props.className}
        onRenderSuffix={() =>
          props.reset ? <FontIcon iconName="ChromeClose" onClick={onResetValueClick} /> : null
        }
        placeholder={props.placeholder}
      />
    </InputMask>
  );
};
