import { IColumn } from "@fluentui/react";
import { AppLanguage } from "constants/enums";
import { DeviceReplacedSeDto } from "generated-sources/openapi";
import loc from "localization";

export const getColumns = (language: AppLanguage): IColumn[] => [
  {
    key: "seK",
    name: loc.columns.se,
    fieldName: "seK",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
  {
    key: "name",
    name: language === AppLanguage.Russian ? loc.columns.nameRu : loc.columns.nameEn,
    fieldName: language === AppLanguage.Russian ? "nameRu" : "nameEn",
    minWidth: 250,
    maxWidth: 250,
    isResizable: true,
  },
  {
    key: "kol",
    name: loc.columns.qty,
    fieldName: "kol",
    minWidth: 85,
    maxWidth: 85,
    isResizable: true,
  },

  {
    key: "price",
    name: loc.columns.price,
    fieldName: "price",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true
  },
  {
    key: "obozn",
    name: loc.columns.currency,
    fieldName: "obozn",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true
  },
  {
    key: "sum",
    name: loc.columns.amount,
    fieldName: "price",
    minWidth: 250,
    maxWidth: 250,
    isResizable: true,
    onRender: (item: DeviceReplacedSeDto) => <>{Number(item.kol) * Number(item.price)}</>
  }
];