import { useEffect, useRef } from "react";

export function useEventListener(
  eventName: string,
  handler: (e: Event) => void,
  selector?: string,
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const savedHandler: any = useRef();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let element: any = (getLayer() || document).querySelector(
      selector || ".ms-ScrollablePane--contentContainer",
    );
    if (!element) element = window;

    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event: Event) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [handler]);
}

const getLayer = () => {
  const layers = document.querySelectorAll(".ms-Layer");
  const doc = layers[layers.length - 1];
  return doc;
};
