import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { isEqual, isNull, last, map } from "lodash-es";
import jwt_decode from "jwt-decode";
import { getUnixTime, fromUnixTime } from "date-fns";
import { HubConnectionBuilder, HubConnection } from "@microsoft/signalr";
import { useApi, useHttp } from "hooks";
import { APP_VERSION } from "constants/appConstants";
import { SignalRNotificationType } from "constants/enums";
import { editSuccessNotification, startTimer } from "utils";
import { AuthContext } from "./authContext";

export interface Props {
  children: JSX.Element | JSX.Element[];
}

interface ICheckUpdatesContext {
  haveUpdates: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  connection: HubConnection | null;
}

export interface ISignalRNotificationData {
  metadata: {
    correlationId: string;
    isAuthenticated: boolean;
    isSystemAccount: boolean;
    occurredAt: Date;
    userId: string;
  };
  payload: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
    devices?: {
      deviceId: number;
      firmId: number;
    }[],
  },
}

export interface INotificationData {
  type: SignalRNotificationType;
  data: ISignalRNotificationData;
}

export const CheckUpdatesContext = createContext<ICheckUpdatesContext>({
  haveUpdates: false,
  connection: null,
});

export const CheckUpdatesContextProvider = (props: Props) => {
  const { requestAccessToken } = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const { userDirectoryApi } = useApi();
  const { request } = useHttp();
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [haveUpdates, setHaveUpdates] = useState<boolean>(false);

  const checkToken = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const token: any = await requestAccessToken();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const decodeToken: any = jwt_decode(token);
    const timeToExpire: boolean = decodeToken.exp - getUnixTime(new Date()) < 300;
    console.log("Token expire time: ", fromUnixTime(decodeToken.exp));
    if (timeToExpire && connection) {
      connection.stop().finally(connectToSignalR);
    }
    startTimer(checkToken, 300);
  };

  const checkAppVersion = () => {
    const storageAppVersion = sessionStorage.getItem("appVersion");
    console.log("storage app version: ", storageAppVersion, APP_VERSION);
    if (!isEqual(storageAppVersion, APP_VERSION)) {
      updateAppVersion();
    };
  };

  const checkUpdates = () => {
    axios
      .get(window.location.origin, {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      })
      .then((res) => {
        const storageHashes = sessionStorage.getItem("hashes");
        const currentHashes = storageHashes ? JSON.parse(storageHashes) : null;
        const hashes = map(res.data.split("/static/js/").slice(1), (item) =>
          last(item.substring(0, item.indexOf(".js")).split(".")),
        );
        console.log("storage hashes: ", currentHashes, !isNull(currentHashes));
        console.log("server hashes: ", hashes);
        if (!isEqual(currentHashes, hashes)) {
          sessionStorage.setItem("hashes", JSON.stringify(hashes));
          if (!isNull(currentHashes)) {
            setHaveUpdates(true);
          }
        }
        startTimer(checkUpdates, 300);
      })
      .catch((err) => console.log(err));
  };

  const updateAppVersion = () => {
    const clientAppVersion = APP_VERSION || "";
    request(() => userDirectoryApi.apiUserDirectoryUsersTnUpdateClientAppVersionPost({
      tn: user?.workNumber || "",
      updateClientAppVersionPayload: {
        clientAppVersion,
      }
    }))
      .then(res => {
        sessionStorage.setItem("appVersion", clientAppVersion);
        editSuccessNotification(res, "App Version was updated successfully");
      });
  };

  const connectToSignalR = async () => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL}/notification-hub`, {
        accessTokenFactory: async () => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const token: any = await requestAccessToken();
          return token;
        },
      })
      .withAutomaticReconnect()
      .build();
    // console.log("Connection: ", newConnection);
    setConnection(newConnection);
  };

  useEffect(() => {
    if (connection) {
      const startConnection = () => {
        console.log("Try to connect");
        connection
          .start()
          .then(() => console.log("Signal R connected!"))
          .catch((e: Error) => {
            console.log("Signal R connection failed: ", e);
            startTimer(startConnection, 30);
          });
      };
      startConnection();

      connection.onclose((err?: Error) => {
        console.log("Onclose error: ", err);
        connectToSignalR();
      });
    }
    return () => {
      if (connection) {
        connection.stop();
      };
    };
  }, [connection]);

  useEffect(() => {
    checkUpdates();
    checkAppVersion();
    checkToken();
    connectToSignalR();
  }, []);

  return (
    <CheckUpdatesContext.Provider value={{ haveUpdates, connection }}>
      {props.children}
    </CheckUpdatesContext.Provider>
  );
};
