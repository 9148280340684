import { RepairAreaDto } from "generated-sources/openapi";
import { useApi, useHttp } from "hooks";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "./authContext";
import { find } from "lodash-es";
import { companyOptions } from "constants/options";
import { LanguageContext } from "./languageContext";
import { AppLanguage } from "constants/enums";

export interface IProps {
  children: JSX.Element;
}

export interface IRepairAreasContext {
  repairAreas: RepairAreaDto[];
  reapairAreasOptions: {
    key: string;
    text: string;
  }[];
  isRepairAreasLoading: boolean;
  errorRepairAreasMessage: string | undefined;
  reloadAreas: () => void;
}

export const RepairAreasContext = createContext<IRepairAreasContext>({
  errorRepairAreasMessage: "",
  isRepairAreasLoading: false,
  reapairAreasOptions: [],
  reloadAreas: () => { throw new Error("Not implemented"); },
  repairAreas: [],
});

export const RepairAreasContextProvider = (props: IProps) => {
  const { language } = useContext(LanguageContext);
  const repairAreasHttp = useHttp<RepairAreaDto[]>();
  const { repairAreasApi } = useApi();
  const { company } = useContext(AuthContext);
  const userCounterparty = find(companyOptions, { text: company });
  const firmId = Number(userCounterparty?.key);

  const [repairAreas, setRepairAreas] = useState<RepairAreaDto[]>([]);

  const loadAreas = () => {
    repairAreasHttp.request(() => repairAreasApi.apiOpRepairAreasGlobalFilterRepairAreasGet({
      firmId
    })).then(res => setRepairAreas(res.data));
  };

  useEffect(() => {
    loadAreas();
  }, [company]);

  const reapairAreasOptions = useMemo(() => repairAreas.map(repairArea => ({
    key: repairArea.areaRepair || "",
    text: `${repairArea.areaRepair} - ${(language === AppLanguage.English ? repairArea.naimEn : repairArea.naimRu) || ""} (${repairArea.kol})`
  })), [repairAreas, language]);

  return (
    <RepairAreasContext.Provider
      value={{
        errorRepairAreasMessage: repairAreasHttp.errorMessages,
        isRepairAreasLoading: repairAreasHttp.isLoading,
        reapairAreasOptions,
        reloadAreas: loadAreas,
        repairAreas: repairAreas
      }}
    >
      {props.children}
    </RepairAreasContext.Provider>
  );
};