import { useMemo, useState, useEffect, useContext } from "react";
import { AxiosResponse } from "axios";
import { find, map } from "lodash-es";
import { DefaultButton, IComboBoxOption, TooltipHost } from "@fluentui/react";
import { BasicCheckbox } from "components/common/BasicCheckbox";
import { BasicModalColumn, BasicModalColumns } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { DeviceBatteryDto, DeviceBatteryTypeBrowseDto } from "generated-sources/openapi";
import loc from "localization";
import { DatePickerInput } from "components/common/DatePickerInput";
import { BasicDropdown } from "components/common/BasicDropdown";
import { LanguageContext } from "context/languageContext";
import { AppLanguage, CommandbarCommands } from "constants/enums";
import { allCompaniesOptions } from "constants/options";
import { transformDateFormat } from "utils";
import { useApi, useHttp } from "hooks";
import { CommandWrapper } from "components/common/CommandWrapper";
import { SerialNumberBatteryModal } from "../SerialNumberBatteryModal";
import { CorrectSerialNumberBatteryModal } from "../CorrectSerialNumberBatteryModal";
import { DeleteModal } from "../DeleteModal";

interface IBatteriesTabProps {
  basicPayload: { firmId: number, deviceId: number };
  batteries: DeviceBatteryDto[];
  setBatteries: (callback: (defects: DeviceBatteryDto[]) => DeviceBatteryDto[]) => void;
  activeCommand: CommandbarCommands;
  setActiveCommand: (command: CommandbarCommands) => void;
  updateState: () => void;
  readonly?: boolean;
}

export const BatteriesTab = (props: IBatteriesTabProps) => {
  const { language } = useContext(LanguageContext);
  const { devicesApi } = useApi();
  const devicesHttp = useHttp();
  const [types, setTypes] = useState<DeviceBatteryTypeBrowseDto[]>([]);
  const typesOptions = useMemo(() => map(types, type => ({
    key: `${type.se}`,
    text: `${type.se}`,
  })), [types, language]);

  const handleModalDismiss = () => props.setActiveCommand(CommandbarCommands.None);

  const onChange = (index: number, name: string, val?: string | number | boolean) => {
    props.setBatteries((batteries) =>
      map(batteries, (battery, i) => (i === index ? { ...battery, [name]: val } : battery)),
    );
  };

  const updateBattery = (battery: DeviceBatteryDto) => {
    devicesHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdPut({
      ...props.basicPayload,
      batteryId: Number(battery.partsId),
      updateBatteryPayload: {
        seK: battery.seK,
        proiz: battery.proiz,
        dataProiz: transformDateFormat(battery.dataProiz),
        garantia: battery.warranty,
      },
    }));
  };

  const getBatteryTypes = () => {
    devicesHttp.request(() => devicesApi.apiOpDevicesBatteriesTypesGet())
      .then((res: AxiosResponse) => setTypes(res.data));
  };

  useEffect(() => {
    getBatteryTypes();
  }, []);

  return (
    <BasicModalColumn>
      <BasicModalColumns>
        <div className="actions">
          <DefaultButton
            text={loc.buttons.add}
            onClick={() => props.setActiveCommand(CommandbarCommands.AddBattery)}
            disabled={props.batteries.length > 2 || props.readonly}
          />
        </div>
      </BasicModalColumns>
      <br />
      <BasicModalColumns>
        <div className="flex-column">
          {map(props.batteries, (battery: DeviceBatteryDto, i: number) => (
            <div className="battery-block" key={battery.partsId}>
              <div className="battery-block__header">
                <b>{loc.labels.battery} {battery.partsId}</b>
                <TooltipHost content={loc.tooltipContent.deleteBattery}>
                  <DefaultButton
                    iconProps={{ iconName: "delete" }}
                    onClick={() => props.setActiveCommand(CommandbarCommands.DeleteBattery)}
                    disabled={props.readonly}
                  />
                </TooltipHost>
                <TooltipHost content={loc.tooltipContent.replaceSerial}>
                  <DefaultButton
                    iconProps={{ iconName: "Combine" }}
                    onClick={() => props.setActiveCommand(CommandbarCommands.SerialBattery)}
                    disabled={props.readonly}
                  />
                </TooltipHost>
                <TooltipHost content={loc.tooltipContent.correctSerial}>
                  <DefaultButton
                    iconProps={{ iconName: "Source" }}
                    onClick={() => props.setActiveCommand(CommandbarCommands.CorrectBattery)}
                    disabled={props.readonly}
                  />
                </TooltipHost>
                <CommandWrapper isActive={props.activeCommand === CommandbarCommands.SerialBattery}>
                  <SerialNumberBatteryModal
                    basicPayload={{ ...props.basicPayload, batteryId: Number(battery.partsId) }}
                    serial={battery.nomer || ""}
                    serialOld={battery.oldNomer || ""}
                    updateState={props.updateState}
                    onDismiss={handleModalDismiss}
                    readonly={props.readonly}
                  />
                </CommandWrapper>
                <CommandWrapper isActive={props.activeCommand === CommandbarCommands.CorrectBattery}>
                  <CorrectSerialNumberBatteryModal
                    basicPayload={{ ...props.basicPayload, batteryId: Number(battery.partsId) }}
                    battery={battery}
                    updateState={props.updateState}
                    types={types}
                    onDismiss={handleModalDismiss}
                  />
                </CommandWrapper>
                <CommandWrapper isActive={props.activeCommand === CommandbarCommands.DeleteBattery}>
                  <DeleteModal
                    text={loc.deviceModal.deleteBatteryTitle}
                    type="battery"
                    payload={{}}
                    basicPayload={{ ...props.basicPayload, batteryId: Number(battery.partsId) }}
                    updateState={props.updateState}
                    onDismiss={handleModalDismiss}
                  />
                </CommandWrapper>
              </div>
              <BasicModalColumns>
                <BasicModalColumn>
                  <InfoContainer label={loc.columns.se}>
                    <BasicDropdown
                      clearButtonOff
                      itemKey={`${battery.seK}`}
                      options={typesOptions}
                      onChange={(_, option?: IComboBoxOption) => {
                        onChange(i, "seK", option?.key);
                        updateBattery({ ...battery, seK: `${option?.key}` });
                      }}
                    />
                  </InfoContainer>
                  <InfoContainer label={loc.columns.productionDate}>
                    <div className="flex-row flex-start">
                      <DatePickerInput
                        target="dataProizBattery"
                        name={"dataProiz"}
                        value={`${battery.dataProiz}`}
                        onChangeValue={(name: string, val: string) => {
                          onChange(i, name, val);
                          if (transformDateFormat(val) || val === "") {
                            updateBattery({ ...battery, dataProiz: val });
                          };
                        }}
                        errorMessage={(transformDateFormat(battery.dataProiz) || battery.dataProiz === "")
                          ? ""
                          : loc.warnings.invalidDate
                        }
                      />
                      <BasicDropdown
                        clearButtonOff
                        itemKey={`${battery.proiz}`}
                        options={allCompaniesOptions}
                        onChange={(_, option?: IComboBoxOption) => {
                          onChange(i, "proiz", option?.key);
                          updateBattery({ ...battery, proiz: Number(option?.key) });
                        }}
                      />
                    </div>
                  </InfoContainer>
                  <InfoContainer label={loc.labels.warranty}>
                    <BasicCheckbox
                      checked={battery?.warranty}
                      onChange={(checked) => {
                        onChange(i, "warranty", checked);
                        updateBattery({ ...battery, warranty: checked });
                      }}
                    />
                  </InfoContainer>
                  <InfoContainer label={loc.columns.name}>
                    <div>
                      {language === AppLanguage.Russian
                        ? find(types, { se: battery.seK })?.nameRu
                        : find(types, { se: battery.seK })?.nameEn
                      }
                    </div>
                  </InfoContainer>
                </BasicModalColumn>
                <BasicModalColumn>
                  <InfoContainer label={loc.columns.serialN}>
                    <b>{battery.nomer || "-"}</b>
                  </InfoContainer>
                  <InfoContainer label={loc.columns.serialOld}>
                    <b>{battery.oldNomer || "-"}</b>
                  </InfoContainer>
                </BasicModalColumn>
              </BasicModalColumns>
            </div>
          ))}
        </div>
      </BasicModalColumns>
    </BasicModalColumn>
  );
};
