import { Spinner, SpinnerSize } from "@fluentui/react";
import { isArray } from "lodash-es";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface ILoadingProps {
  children: JSX.Element;
  isLoading: boolean;
  errors?: string | string[];
}

export const Loading = (props: ILoadingProps) => (
  <div
    className={classNames({ [styles.loadingBlock]: props.isLoading }, "loading-block")}
    style={{ height: `calc(100% - ${32 * (isArray(props.errors) ? props.errors.length : (props.errors?.length ? 1 : 0))}px)` }}
  >
    {props.children}
    {props.isLoading && <Spinner size={SpinnerSize.large} />}
  </div>
);
