import { useState } from "react";
import { Sorting } from "constants/enums";

export interface ISortingState {
  field: string;
  type: Sorting;
}

export interface ISorting {
	state: ISortingState;
	onSortingChange: (fieldName: string) => void;
}

export const useSorting = (defaultSorting?: ISortingState) => {
  const [sorting, setSorting] = useState<ISortingState>(
    defaultSorting || { field: "", type: Sorting.ASC },
  );
  const onSortingChange = (fieldName: string) => {
    setSorting((sorting) => ({
      field: fieldName,
      type: sorting.field === fieldName && sorting.type === Sorting.ASC ? Sorting.DESC : Sorting.ASC,
    }));
  };
  return { state: sorting, onSortingChange };
};
