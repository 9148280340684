import { useContext, useState, useEffect, useMemo } from "react";
import { useFormik } from "formik";
import { AxiosResponse } from "axios";
import { IComboBoxOption, TooltipHost } from "@fluentui/react";
import { isNull, map } from "lodash-es";
import * as Yup from "yup";
import { useApi, useHttp } from "hooks";
import { LanguageContext } from "context/languageContext";
import { AppLanguage } from "constants/enums";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { BasicDropdown } from "components/common/BasicDropdown";
import { DatePickerInput } from "components/common/DatePickerInput";
import { BasicCheckbox } from "components/common/BasicCheckbox";
import { DeviceBatteryTypeBrowseDto } from "generated-sources/openapi";
import { allCompaniesOptions } from "constants/options";
import { getLcid, transformDateFormat, transformDateToString, transformStringToDate } from "utils";
import { BarcodeInput } from "components/common/BarcodeInput";

export interface IAddBatteryModalProps {
  basicPayload: { firmId: number; deviceId: number };
  updateState: () => void;
  onDismiss: () => void;
}

export const AddBatteryModal = (props: IAddBatteryModalProps) => {
  const { devicesApi } = useApi();
  const devicesHttp = useHttp();
  const { loc, language } = useContext(LanguageContext);
  const [types, setTypes] = useState<DeviceBatteryTypeBrowseDto[]>([]);
  const [barcode, setBarcode] = useState("");
  const [scannedDeviceSerial, setScannedDeviceSerial] = useState<string>("");
  const [serialsFromBarcodes, setSerialsFromBarcodes] = useState<string[]>([]);

  const typesOptions = useMemo(
    () =>
      map(types, (type) => ({
        key: `${type.se}`,
        text: `${type.se}`,
        title: language === AppLanguage.Russian ? type.nameRu : type.nameEn
      })),
    [types, language],
  );

  const submitHandler = () => {
    devicesHttp.request(() =>
      devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesPost({
        ...props.basicPayload,
        addBatteryPayload: {
          seK: formik.values.seK,
          proiz: Number(formik.values.proiz) || null,
          dataProiz: transformDateFormat(formik.values.date),
          garantia: formik.values.garantia,
          serial: scannedDeviceSerial
        },
      }),
    )
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          props.updateState();
          props.onDismiss();
        };
      });
  };

  const getBatteryTypes = () => {
    devicesHttp
      .request(() => devicesApi.apiOpDevicesBatteriesTypesGet())
      .then((res: AxiosResponse) => setTypes(res.data));
  };

  const getBatteryByBarcode = () => {
    devicesHttp
      .request(() => devicesApi.apiOpDevicesGeneralProductionDeviceBySerialGet(
        {
          serial: barcode,
          lcid: getLcid(language)
        }
      ))
      .then((res: AxiosResponse) => {
        setTypes(types.concat([{
          se: res.data.sek,
          nameEn: res.data.deviceName,
          nameRu: res.data.deviceName,
          nameL: ""
        }]));
        formik.setValues({
          ...formik.values,
          seK: res.data.sek,
          date: transformDateToString(res.data.productionDate),
          proiz: String(res.data.productionId),
          garantia: res.data.warranty,
          deviceName: res.data.deviceName
        });
        setScannedDeviceSerial(res.data?.serial);
      });
  };
  useEffect(() => {
    getBatteryTypes();
  }, []);

  const formik = useFormik({
    initialValues: {
      seK: "",
      proiz: "",
      date: "",
      garantia: false,
      deviceName: ""
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      seK: Yup.string().required(loc.warnings.requiredField),
    }),
    validate: (values) => {
      const date = transformStringToDate(values.date);
      if (values.date.length && isNull(date)) {
        return { date: loc.warnings.invalidDate };
      };
    },
    onSubmit: submitHandler,
  });

  return (
    <BasicModal
      isLoading={devicesHttp.isLoading}
      title={loc.deviceModal.addBatteryTitle}
      errors={devicesHttp.errorMessages}
      submitButtonText={loc.buttons.save}
      dismissButtonText={loc.buttons.cancel}
      onSubmit={formik.handleSubmit}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <InfoContainer label={loc.columns.serialN}>
          <BarcodeInput
            value={barcode}
            onChange={(value) => {
              setBarcode(value);
            }}
            onEnterPressed={() => getBatteryByBarcode()}
            onReset={() => {
              setSerialsFromBarcodes([]);
            }}
            active={!!serialsFromBarcodes.length}
          />
        </InfoContainer>
        {scannedDeviceSerial?.length ? (
          <InfoContainer label=" ">
            <>{scannedDeviceSerial}</>
          </InfoContainer>
        ) : <></>}
        <InfoContainer isRequired label={loc.columns.se}>
          <BasicDropdown
            clearButtonOff
            itemKey={formik.values.seK}
            options={typesOptions}
            onChange={(_, option?: IComboBoxOption) => {
              formik.setFieldValue("seK", option?.key);
              formik.setFieldValue("deviceName", option?.title);
            }}
            errorMessage={formik.errors.seK}
          />
        </InfoContainer>
        <InfoContainer label={loc.columns.name}>
          <TooltipHost content={formik.values.deviceName}>{formik.values.deviceName || ""}</TooltipHost>
        </InfoContainer>
        <InfoContainer label={loc.columns.productionDate}>
          <div className="flex-row flex-start">
            <DatePickerInput
              name={"date"}
              value={formik.values.date}
              onChangeValue={formik.setFieldValue}
              errorMessage={formik.errors.date}
            />
            <BasicDropdown
              clearButtonOff
              itemKey={formik.values.proiz}
              options={allCompaniesOptions}
              onChange={(_, option?: IComboBoxOption) => {
                formik.setFieldValue("proiz", option?.key);
                props.updateState();
              }}
            />
          </div>
        </InfoContainer>
        <InfoContainer label={loc.labels.warranty}>
          <BasicCheckbox
            checked={formik.values.garantia}
            onChange={(checked) => formik.setFieldValue("garantia", checked)}
          />
        </InfoContainer>
      </BasicModalContent>
    </BasicModal>
  );
};
