import styles from "./styles.module.scss";
import { BasicModal, BasicModalContent } from "../BasicModal";
import { BasicInput } from "../BasicInput";
import { useContext, useEffect, useState } from "react";
import { DefaultButton, Icon, SelectionMode, Stack, Text } from "@fluentui/react";
import { ILegend, Legend } from "../Legend";
import { GREEN_COLOR, RED_COLOR, SKY_BLUE_COLOR, YELLOW_COLOR } from "constants/colors";
import { LanguageContext } from "context/languageContext";
import { useApi, useHttp, useSelection } from "hooks";
import { ScrollableTableWrapper, Table } from "../Table";
import { getCompaniesColumns, getMarkerLegend, getWarehousesColumns } from "./config";
import { SearchDeviceBrowseDto, SearchDeviceDocumentBrowseDto } from "generated-sources/openapi";
import { companyOptions } from "constants/options";
import { AuthContext } from "context/authContext";
import { find } from "lodash-es";
import classNames from "classnames";
import invoiceStyles from "components/pages/InvoicesPage/styles.module.scss";
import { useFormik } from "formik";
import registerIcon from "images/register-icon.svg";
import * as Yup from "yup";
import { onRenderRowWithMarkers } from "utils/onRenderRowWithMarkers";
import { CommandWrapper } from "../CommandWrapper";
import { CommandbarCommands } from "constants/enums";
import { DeviceModal } from "../DeviceModal";
import { Loading } from "../LoadingWrapper";

interface ISearchBySerialProps {
	onDismiss: () => void;
}

export const SearchBySerial = (props: ISearchBySerialProps) => {
  const { company } = useContext(AuthContext);
  const warehousesHttp = useHttp<SearchDeviceBrowseDto[]>();
  const companiesHttp = useHttp<SearchDeviceDocumentBrowseDto[]>();
  const { loc, language } = useContext(LanguageContext);
  const { searchApi } = useApi();
  const [warehouses, setWarehouses] = useState<SearchDeviceBrowseDto[]>([]);
  const [companies, setCompanies] = useState<SearchDeviceDocumentBrowseDto[]>([]);
  const firmId = find(companyOptions, { text: company })?.key || "";
  const { selection, selectedItem } = useSelection<SearchDeviceBrowseDto>();
  const [activeCommand, setActiveCommand] = useState<CommandbarCommands>(CommandbarCommands.None);
  const handleModalDismiss = () => setActiveCommand(CommandbarCommands.None);
  const [deviceIdForDisplay, setDeviceIdForDisplay] = useState(0);
  const [firmIdForDisplay, setFirmIdForDisplay] = useState(0);
  const [areaForDisplay, setAreaForDisplay] = useState<string>("");

  const warehousesLegend: ILegend[] = [{
    color: YELLOW_COLOR,
    text: loc.searchBySerial.externalSc
  }];

  const companiesLegend: ILegend[] = [{
    color: GREEN_COLOR,
    text: loc.searchBySerial.rma
  }, {
    color: SKY_BLUE_COLOR,
    text: loc.searchBySerial.sentToDistributor
  }, {
    color: RED_COLOR,
    text: loc.searchBySerial.returnFromCustomer
  }];

  const openDeviceModal = (deviceId: number, firmId: number, area: string) => {
    setDeviceIdForDisplay(deviceId);
    setFirmIdForDisplay(firmId);
    setAreaForDisplay(area);
    setActiveCommand(CommandbarCommands.Display);
  };

  const warehousesColumns = getWarehousesColumns(firmId, language, openDeviceModal);
  const companiesColumns = getCompaniesColumns();

  const search = () => {
    warehousesHttp.request(() => searchApi.apiOpSearchBrowseSerialGet({
      serial: formik.values.serialNumber,
    })).then(res => {
      setWarehouses(res.data);
    });
  };

  const formik = useFormik({
    initialValues: {
      serialNumber: ""
    },
    validateOnChange: true,
    onSubmit: search,
    validationSchema: Yup.object().shape({
      serialNumber: Yup.string().required(loc.searchBySerial.serialIsMissing)
    })
  });

  const loadCompanies = () => {
    if (selectedItem) {
      companiesHttp.request(() => searchApi.apiOpSearchBrowseDeviceIdDeviceFirmIdGet({
        deviceFirmId: selectedItem?.firmId || 0,
        deviceId: selectedItem?.deviceId || 0
      })).then(res => {
        setCompanies(res.data);
      });
    } else {
      setCompanies([]);
    }
  };

  useEffect(() => {
    loadCompanies();
  }, [selectedItem]);

  return (
    <BasicModal
      additionalClassName={styles.searchBySerial}
      dismissButtonText={loc.buttons.close}
      isLoading={warehousesHttp.isLoading}
      onDismiss={props.onDismiss}
      onSubmit={(e) => { e.preventDefault();search(); }}
      title={loc.searchBySerial.title}
      errors={warehousesHttp.errorMessages}
    >
      <BasicModalContent>
        <Stack horizontal>
          <span className={styles.label}>{loc.columns.serialN}:</span>
          <BasicInput
            className={styles.input}
            value={formik.values.serialNumber}
            onChange={(val) => formik.setFieldValue("serialNumber", val)}
            errorMessage={formik.errors.serialNumber}
          />
          <DefaultButton
            disabled={!formik.values.serialNumber.length}
            className={styles.findButton}
            type="submit"
            text={loc.buttons.find}
          />
        </Stack>
        <div className={styles.tableSection}>
          <Stack horizontal horizontalAlign="space-between">
            <b>{loc.searchBySerial.warehouses}</b>
            <Legend
              legends={[{ type: "point", items: warehousesLegend }]}
            />
          </Stack>
          <div className={styles.tableWrapper}>
            <ScrollableTableWrapper noData={!warehouses.length}>
              <Table
                columns={warehousesColumns}
                items={warehousesHttp.errorMessages ? [] : warehouses}
                selectionMode={SelectionMode.single}
                selection={selection}
                onRenderRow={onRenderRowWithMarkers(getMarkerLegend(firmId))}
              />
            </ScrollableTableWrapper>
          </div>
        </div>
        <>{ selectedItem && (
          <div className={styles.tableSection}>
            <Stack horizontal horizontalAlign="space-between">
              <b>{loc.searchBySerial.docs}</b>
              <Legend
                legends={[{ type: "point", items: companiesLegend }]}
                onLegendRender={(defaultRender) => (
                  <div className={invoiceStyles.legend}>
                    <div className={styles.dotsLegendWrapper}>
                      {defaultRender()}
                    </div>
                    <div className={invoiceStyles.legendItem}>
                      <Icon
                        className={classNames(invoiceStyles.docStatusIcon, invoiceStyles.docStatusIconPlus)}
                        iconName="CirclePlus"
                      />
                      <Text>{loc.invoicesPage.incomingInvoices}</Text>
                    </div>
                    <div className={invoiceStyles.legendItem}>
                      <Icon
                        className={classNames(invoiceStyles.docStatusIcon, invoiceStyles.docStatusIconMinus)}
                        iconName="SkypeCircleMinus"
                      />
                      <Text>{loc.invoicesPage.outgoingInvoices}</Text>
                    </div>
                    <div className={invoiceStyles.legendItem}>
                      <img src={registerIcon} width={20}/>
                      <Text>{loc.invoicesPage.rma}</Text>
                    </div>
                  </div>
                )}
              />
            </Stack>
            <div className={styles.tableWrapper}>
              <ScrollableTableWrapper noData={!companies.length}>
                <Loading isLoading={companiesHttp.isLoading}>
                  <Table
                    columns={companiesColumns}
                    items={companiesHttp.errorMessages ? [] : companies}
                    selectionMode={SelectionMode.none}
                  />
                </Loading>
              </ScrollableTableWrapper>
            </div>
          </div>
        )}
        </>
        <CommandWrapper isActive={activeCommand === CommandbarCommands.Display}>
          <DeviceModal
            updateState={search}
            firmId={firmIdForDisplay}
            deviceId={deviceIdForDisplay}
            onDismiss={handleModalDismiss}
            selectedRepariArea={areaForDisplay}
          />
        </CommandWrapper>
      </BasicModalContent>
    </BasicModal>
  );
};
