import { useContext, useState, useMemo } from "react";
import { AxiosResponse } from "axios";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { useFormik } from "formik";
import { useApi, useHttp } from "hooks";
import { LanguageContext } from "context/languageContext";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { SerialNumInput } from "components/common/SerialNumInput";
import { editSuccessNotification, transformDateFormat, transformDateToString } from "utils";
import { ConfirmModal } from "components/common/ConfirmModal";
import { CommandWrapper } from "components/common/CommandWrapper";
import { DeviceBySerialDto } from "generated-sources/openapi";
import { getLcid } from "utils";
import * as Yup from "yup";

export interface ICorrectSerialNumberModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  basicPayload: { firmId: number, deviceId: number };
  updateState: () => void;
  onDismiss: () => void;
}

export const CorrectSerialNumberModal = (props: ICorrectSerialNumberModalProps) => {
  const { devicesApi } = useApi();
  const { isLoading, request, errorMessages } = useHttp();
  const { loc, language } = useContext(LanguageContext);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [deviceBySerialFound, setDeviceBySerialFound] = useState<DeviceBySerialDto | null>(null);

  const correctSerial = (deviceBySerialFound?: DeviceBySerialDto, confirm?: boolean) => {
    const confirmPayload = deviceBySerialFound ? {
      sek: deviceBySerialFound.se,
      proiz: deviceBySerialFound.proiz,
    } : {};
    const notConfirmPayload = deviceBySerialFound ? {
      proiz: deviceBySerialFound.proiz,
    } : {};
    request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCorrectSerialPost({
      ...props.basicPayload,
      correctSerialPayload: {
        serial: formik.values.serial,
        dataProiz: deviceBySerialFound
          ? transformDateFormat(transformDateToString(deviceBySerialFound.productionDate))
          : "",
        ...(confirm ? confirmPayload : notConfirmPayload),
      },
    })
    ).then((res: AxiosResponse) => {
      if (res.status === 200) {
        editSuccessNotification(res);
        props.updateState();
        props.onDismiss();
      };
    });
  };

  const searchCorrectSerial = () => {
    request(() =>
      devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGet({
        ...props.basicPayload,
        serial: formik.values.serial,
        lcid: getLcid(language),
      }),
    ).then((res: AxiosResponse) => {
      if (res.status === 200) {
        if (res.data.deviceBySerial) {
          setDeviceBySerialFound(res.data.deviceBySerial);
          setConfirmModal(true);
        } else {
          correctSerial();
        }
      }
    });
  };

  const checkSerialExist = () => {
    request(() => devicesApi.apiOpDevicesGeneralCheckSerialExistGet({ serial: formik.values.serial }))
      .then((res: AxiosResponse) => {
        if (res.data.isUnique) {
          searchCorrectSerial();
        } else {
          setConfirmModal(true);
        };
      });
  };

  const confirmSubmit = () => !deviceBySerialFound ? searchCorrectSerial() : correctSerial(deviceBySerialFound, true);
  const confirmDecline = () => !deviceBySerialFound ? setConfirmModal(false) : correctSerial(deviceBySerialFound, false);

  const formik = useFormik({
    initialValues: {
      serial: props.formik.values.nomer || "",
    },
    validationSchema: Yup.object().shape({
      serial: Yup.string().required(loc.warnings.requiredField)
    }),
    validateOnChange: true,
    onSubmit: () => checkSerialExist(),
  });

  const confirmText = useMemo(() => {
    return !deviceBySerialFound
      ? loc.formatString(loc.deviceModal.checkSerialExist, formik.values.serial)
      : `${loc.columns.serialN} ${formik.values.serial}
        ${loc.columns.sku}: ${deviceBySerialFound?.sku}
        ${deviceBySerialFound?.name}
        ${loc.buttons.replace}?`;
  }, [deviceBySerialFound, formik.values.serial]);

  return (
    <BasicModal
      isLoading={isLoading}
      title={loc.deviceModal.correctSerialTitle}
      errors={confirmModal ? [] : errorMessages}
      submitButtonText={loc.buttons.save}
      dismissButtonText={loc.buttons.cancel}
      onSubmit={formik.handleSubmit}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <InfoContainer label={loc.columns.serialN}>
          <SerialNumInput
            name="serial"
            value={formik.values.serial}
            onChange={(value: string) => formik.setFieldValue("serial", value)}
            errorMessage={formik.errors.serial?.toString()}
          />
        </InfoContainer>
        
        <CommandWrapper isActive={confirmModal}>
          <ConfirmModal
            preLine
            isLoading={isLoading}
            text={confirmText}
            leftButton={<PrimaryButton text={loc.buttons.yes} onClick={confirmSubmit} />}
            rightButton={<DefaultButton text={loc.buttons.no} onClick={confirmDecline} />}
            errors={errorMessages}
            onDismiss={props.onDismiss}
          />
        </CommandWrapper>
      </BasicModalContent>
    </BasicModal>
  );
};
