import { Permissions } from "constants/enums";
import { companyOptions } from "constants/options";
import { AuthUserDto } from "generated-sources/openapi/api";
import { find } from "lodash-es";

export interface IPermissions {
  dashboardPage: boolean;
  defectsPage: boolean;
  defectsPageAction: boolean;
  serialNumsTrackingPage: boolean;
  serialNumsTrackingAction: boolean;
  normHoursPage: boolean;
  normHoursAction: boolean;
  maintainabilityPage: boolean;
  maintainabilityAction: boolean;
  requestsRMAPage: boolean;
  permissionsRMAPage: boolean;
  devicesPage: boolean;
  invoicesPage: boolean;
  devicesAction: boolean;
  servicePage: boolean;
  repairPlanPage: boolean;
  serviceCentersDatauploadPage: boolean;
  manageWarehousePermission: boolean;
}

export const getPermissionScopes = (user: AuthUserDto | null, company?: string): IPermissions => {
  const firmId = Number(find(companyOptions, { text: company })?.key);
  const mainPagePermissions = Boolean(
    checkPermission(user, Permissions.FullControl)
    || checkPermission(user, Permissions.ManageWarehouse)
    || checkPermission(user, Permissions.ManageOtk)
    || checkPermission(user, Permissions.TransferDocumentsToSalesModule)
  );
  const mainActionPermissions = Boolean(
    checkPermission(user, Permissions.FullControl)
    || checkPermission(user, Permissions.ManageWarehouse)
    || checkPermission(user, Permissions.TransferDocumentsToSalesModule)
  );

  const serviceCentersDatauploadPageEnabled = firmId === 6;

  return {
    dashboardPage: true,
    defectsPage: Boolean(
      checkPermission(user, Permissions.ManageDefects)
      || checkPermission(user, Permissions.FullControl)
      || checkPermission(user, Permissions.ManageOtk)
      || checkPermission(user, Permissions.ManageWarehouse)
    ), // spec skl otk defect
    defectsPageAction: Boolean(
      checkPermission(user, Permissions.ManageDefects)
      || checkPermission(user, Permissions.FullControl)
    ), // defect spec
    serialNumsTrackingPage: mainPagePermissions, // spec skl otk sbyt
    serialNumsTrackingAction: mainActionPermissions, // spec skl sbyt
    normHoursPage: mainPagePermissions, // spec skl otk sbyt
    normHoursAction: mainActionPermissions, // spec skl sbyt
    maintainabilityPage: mainPagePermissions, // spec skl otk sbyt
    maintainabilityAction: mainActionPermissions, // spec skl sbyt
    requestsRMAPage: true,
    permissionsRMAPage: true,
    devicesPage: mainPagePermissions, // spec skl otk sbyt
    devicesAction: mainPagePermissions, // spec skl otk sbyt,
    invoicesPage: true, // TODO: check permissions
    servicePage: true, // TODO: check permissions
    repairPlanPage: true,
    serviceCentersDatauploadPage: serviceCentersDatauploadPageEnabled,
    manageWarehousePermission: Boolean(checkPermission(user, Permissions.ManageWarehouse))
  };
};

export const checkPermission = (user: AuthUserDto | null, permission: string) => {
  return user?.permissions?.includes(permission);
};
