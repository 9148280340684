import { useContext } from "react";
import { map } from "lodash-es";
import { AxiosResponse } from "axios";
import { useFormik } from "formik";
import { useApi, useHttp } from "hooks";
import { downloadFile, getLcid, getPdf } from "utils";
import { LanguageContext } from "context/languageContext";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { BasicRadioGroup } from "components/common/BasicRadioGroup";
import { getChoiseGroupLangOptions } from "constants/options";
import { AppLanguage } from "constants/enums";

export interface IGetDocumentModalProps {
  basicPayload: { firmId: number, deviceId: number };
  onDismiss: () => void;
  typeOfDocument: "pdf" | "excel";
}

enum ActionUrl {
  Excel = "apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportExcelPost",
  Pdf = "apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationExportPdfPost"
};

export const GetDocumentModal = (props: IGetDocumentModalProps) => {
  const { devicesApi } = useApi();
  const { isLoading, request, errorMessages } = useHttp();
  const { loc } = useContext(LanguageContext);

  const choiseGroupOptions = map(getChoiseGroupLangOptions(), option => ({
    ...option,
    disabled: option.key === AppLanguage.Russian,
  }));

  const getUrl = () => {
    if (props.typeOfDocument === "pdf") {
      return ActionUrl.Pdf;
    } else {
      return ActionUrl.Excel;
    };
  };

  const submitHandler = () => {
    const url = getUrl();
    request(() => devicesApi[url]({
      ...props.basicPayload,
      lcid: getLcid(formik.values.lang),
    }, { responseType: "blob" })
    ).then((res: AxiosResponse) => {
      url === ActionUrl.Pdf ? getPdf(res) : downloadFile(res);
      props.onDismiss();
    });
  };

  const formik = useFormik({
    initialValues: {
      lang:  AppLanguage.English,
    },
    validateOnChange: true,
    onSubmit: submitHandler,
  });

  return (
    <BasicModal
      isLoading={isLoading}
      title={loc.main.selectLanguage}
      errors={errorMessages}
      submitButtonText={loc.buttons.print}
      dismissButtonText={loc.buttons.cancel}
      onSubmit={formik.handleSubmit}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <InfoContainer label={loc.labels.documentLang}>
          <BasicRadioGroup
            name="lang"
            selectedKey={formik.values.lang}
            options={choiseGroupOptions}
            onChange={formik.setFieldValue}
          />
        </InfoContainer>
      </BasicModalContent>
    </BasicModal>
  );
};
