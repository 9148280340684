import { filter, includes, map } from "lodash-es";
import { Dispatch, SetStateAction } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UpdateItemType = (obj: any, field: string, value?: string) => void;

export const updateTableItems = <T>(
  fieldId: string,
  setItems: Dispatch<SetStateAction<T[]>>,
): UpdateItemType => {
  return (obj: T, field: string, value?: string) => {
    setItems((items: T[]) =>
      map(items, (item: T) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (item as any)[fieldId] === (obj as any)[fieldId] ? { ...item, [field]: value } : item,
      ),
    );
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AddSelectedItemsType = (obj: any) => void;

export const addSelectedItems = <T>(
  fieldId: string,
  selectedDevices: T[],
  setSelectedDevices: Dispatch<SetStateAction<T[]>>,
): AddSelectedItemsType => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (item: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectedDevicesSes = map(selectedDevices, (device: any) => device[fieldId]);
    const deviceAlreadySelected = includes(selectedDevicesSes, item[fieldId]);
    if (deviceAlreadySelected) {
      setSelectedDevices((selectedDevices) =>
        Number(item.count) > 0
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ? map(selectedDevices, (device: any) => device[fieldId] === item[fieldId] ? item : device)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          : filter(selectedDevices, (device: any) => device[fieldId] !== item[fieldId]),
      );
    } else if (Number(item.count) > 0) {
      setSelectedDevices((selectedDevices) => [...selectedDevices, item]);
    }
  };
};
