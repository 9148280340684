import { useContext, useState } from "react";
import { AxiosResponse } from "axios";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { useFormik } from "formik";
import { useApi, useHttp } from "hooks";
import { LanguageContext } from "context/languageContext";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { SerialNumInput } from "components/common/SerialNumInput";
import { CommandWrapper } from "components/common/CommandWrapper";
import { ConfirmModal } from "components/common/ConfirmModal";
import * as Yup from "yup";

export interface ISerialNumberModalProps {
  basicPayload: { firmId: number, deviceId: number };
  serial: string;
  serialOld: string;
  updateState: () => void;
  onDismiss: () => void;
}

export const SerialNumberModal = (props: ISerialNumberModalProps) => {
  const { devicesApi } = useApi();
  const { isLoading, request, errorMessages } = useHttp();
  const { loc } = useContext(LanguageContext);

  const [confirmModal, setConfirmModal] = useState<boolean>(false);

  const changeSerial = () => {
    request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSerialPost({
      ...props.basicPayload,
      changeSerialPayload: formik.values,
    })
    ).then((res: AxiosResponse) => {
      if (res.status === 200) {
        props.updateState();
        props.onDismiss();
      };
    });
  };

  const checkSerialExist = () => {
    request(() => devicesApi.apiOpDevicesGeneralCheckSerialExistGet({ serial: formik.values.serial }))
      .then((res: AxiosResponse) => {
        if (res.data.isUnique) {
          changeSerial();
        } else {
          setConfirmModal(true);
        };
      });
  };

  const formik = useFormik({
    initialValues: {
      serial: props.serial,
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      serial: Yup.string().required(loc.warnings.requiredField)
    }),
    onSubmit: checkSerialExist,
  });

  return (
    <BasicModal
      isLoading={isLoading}
      title={loc.deviceModal.changeSerialTitle}
      errors={errorMessages}
      submitButtonText={loc.buttons.save}
      dismissButtonText={loc.buttons.cancel}
      onSubmit={formik.handleSubmit}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <InfoContainer label={loc.columns.serialN}>
          <SerialNumInput
            name="serial"
            value={formik.values.serial}
            onChange={(value: string) => formik.setFieldValue("serial", value)}
            errorMessage={formik.errors.serial}
          />
        </InfoContainer>
        <InfoContainer label={loc.columns.serialOld}>
          <div>{props.serialOld || "-"}</div>
        </InfoContainer>
        
        <CommandWrapper isActive={confirmModal}>
          <ConfirmModal
            preLine
            isLoading={isLoading}
            text={loc.formatString(loc.deviceModal.checkSerialExist, formik.values.serial)}
            leftButton={<PrimaryButton text={loc.buttons.yes} onClick={changeSerial} />}
            rightButton={<DefaultButton text={loc.buttons.no} onClick={props.onDismiss} />}
            errors={errorMessages}
            onDismiss={props.onDismiss}
          />
        </CommandWrapper>
      </BasicModalContent>
    </BasicModal>
  );
};
