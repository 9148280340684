import { ICalendarStrings } from "@fluentui/react";
import { AppLanguage } from "constants/enums";
import { map } from "lodash-es";

export const getModifiedCalendarStrings = (strings: ICalendarStrings, language: AppLanguage) => ({
  ...strings,
  shortMonths: map(new Array(12), (_, i: number) => `${i + 1}`),
  shortDays:
    language === AppLanguage.English
      ? strings.shortDays
      : ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"],
});
