import { DeviceWorkBrowseDto } from "generated-sources/openapi";
import { filter, map, reduce } from "lodash-es";
import { IDeviceWork } from ".";

export const modifyWorks = (data: DeviceWorkBrowseDto[]): IDeviceWork[] =>
  map(data, (item) => {
    const { level, isParent } = defineInfo(item, data);
    const code =
      `${item.u1 ? `${item.u1}` : ""}` +
      `${item.u2 ? `.${item.u2}` : ""}` +
      `${item.u3 ? `.${item.u3}` : ""}` +
      `${item.u4 ? `.${item.u4}` : ""}` +
      `${item.u5 ? `.${item.u5}` : ""}`;
    return {
      ...item,
      code,
      isCollapsed: true,
      level,
      isParent,
    };
  });

const defineInfo = (item: DeviceWorkBrowseDto, data: DeviceWorkBrowseDto[]) => {
  if (item.u1 && !item.u2) {
    const isParent = !!filter(data, work => work.u1 == item.u1 && work.u2).length;
    return { level: 0, isParent };
  } else if (item.u1 && item.u2 && !item.u3) {
    const isParent = !!filter(data, work => work.u1 == item.u1 && work.u2 === item.u2 && work.u3).length;
    return { level: 1, isParent };
  } else if (item.u1 && item.u2 && item.u3 && !item.u4) {
    const isParent = !!filter(data, work => work.u1 == item.u1 && work.u2 === item.u2 && work.u3 === item.u3 && work.u4).length;
    return { level: 2, isParent };
  } else if (item.u1 && item.u2 && item.u3 && item.u4 && !item.u5) {
    const isParent = !!filter(data, work => work.u1 == item.u1 && work.u2 === item.u2 && work.u3 === item.u3 && work.u4 === item.u4 && work.u5).length;
    return { level: 3, isParent };
  } else {
    return { level: 4, isParent: false };
  };
};

export const getGroupedItems = (works: IDeviceWork[]): IDeviceWork[] => {
  const parents = filter(works, (work) => !work.u2);
  const items = defineChildren(works, parents);
  return items;
};

const defineChildren = (works: IDeviceWork[], items: IDeviceWork[]): IDeviceWork[] => {
  return reduce(
    items,
    (prev: IDeviceWork[], next: IDeviceWork) => {
      const children = next.isCollapsed
        ? []
        : filter(works, work => work.code.startsWith(next.code) && work.level === next.level + 1);
      return [...prev, next, ...defineChildren(works, children)];
    },
    [],
  );
};
