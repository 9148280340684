import React, { useContext, useState } from "react";
import { filter, map } from "lodash-es";
import { Callout, DirectionalHint, FontIcon, IconButton, TooltipHost } from "@fluentui/react";
import loc from "localization";
import classNames from "classnames";
import { APP_ENV, APP_VERSION } from "constants/appConstants";
import { AppLanguage, Permissions } from "constants/enums";
import { companyOptions } from "constants/options";
import { AuthContext } from "context/authContext";
import { LanguageContext } from "context/languageContext";
import { checkPermission } from "utils";
import { IAlert } from "typings";
import { CompanyFlag } from "../CompanyFlag";
import styles from "./styles.module.scss";
import { SearchBySerial } from "../SearchBySerial";
import { SettingsMenu } from "../SettingsMenu";

export const Navbar = () => {
  const jsonAlerts = sessionStorage.getItem("alerts");
  const alertsArr = jsonAlerts ? JSON.parse(jsonAlerts) : [];
  const { name, isAuthenticated, handleLogout, user, company, setCompany } =
    useContext(AuthContext);
  const { language, changeAppLanguage } = useContext(LanguageContext);
  const [alerts, setAlerts] = useState<IAlert[]>(alertsArr);
  const [alertsIsVisible, setAlertsIsVisible] = useState<boolean>(false);
  const [calloutIsVisible, setCalloutIsVisible] = useState<boolean>(false);
  const [searchBySerialIsOpend, setSearchBySerialIsOpend] = useState<boolean>(false);
  const switchPermission = checkPermission(user, Permissions.BrowseOrganizations);
  const toggleAlertsIsVisible = () => setAlertsIsVisible(!alertsIsVisible);
  const copyToClipboard = (alert: IAlert) => {
    const copied = `
			Error code: ${alert.errorCode};
			Message: ${alert.message};
			CorrelationId: ${alert.correlationId};
		`;
    navigator.clipboard.writeText(copied);
  };
  const onClear = () => {
    setAlerts([]);
    sessionStorage.removeItem("alerts");
  };
  const onCompanyClick = () => {
    if (switchPermission) {
      setCalloutIsVisible(!calloutIsVisible);
    }
  };

  return (
    <>
      <nav className={styles.navbar}>
        <div className={styles.title}>
          {loc.title} {APP_ENV?.length ? `- ${APP_ENV}` : ""} - v{APP_VERSION}
        </div>
        {isAuthenticated && (
          <div className={styles.user}>
            <div>
              <IconButton
                className={styles.navbarSearchButton}
                iconProps={{
                  iconName: "Search"
                }}
                onClick={() => setSearchBySerialIsOpend(true)}
              />
            </div>
            {company && (
              <>
                <span
                  id="company"
                  onClick={onCompanyClick}
                  className={switchPermission ? styles.switchBtn : ""}
                >
                  <CompanyFlag companyName={company} />
                </span>
                {calloutIsVisible && (
                  <Callout
                    target="#company"
                    isBeakVisible={false}
                    onDismiss={() => setCalloutIsVisible(false)}
                  >
                    <div className="browse-organizations-callout">
                      {map(
                        filter(companyOptions, (option) => option.text !== company),
                        (company) => (
                          <div
                            key={company.key}
                            onClick={() => {
                              setCompany(company.text);
                              localStorage.setItem("company", company.text);
                              setCalloutIsVisible(false);
                            }}
                          >
                            <CompanyFlag companyName={company.text} />
                            {company.title}
                          </div>
                        ),
                      )}
                    </div>
                  </Callout>
                )}
              </>
            )}
            <div>
              <ul className={styles.langList}>
                <li
                  className={classNames(styles.langItem, {
                    [styles.activeLang]: language === AppLanguage.English,
                  })}
                  onClick={() => changeAppLanguage(AppLanguage.English)}
                >
                En
                </li>
                <li
                  className={classNames(styles.langItem, {
                    [styles.activeLang]: language === AppLanguage.Russian,
                  })}
                  onClick={() => changeAppLanguage(AppLanguage.Russian)}
                >
                Ru
                </li>
              </ul>
            </div>
            {alerts.length ? (
              <div className={styles.alerts}>
                <TooltipHost
                  content={loc.tooltipContent.receivedErrors}
                  directionalHint={DirectionalHint.rightCenter}
                >
                  <FontIcon id="alert-icon" iconName="Error" onClick={toggleAlertsIsVisible} />
                  <div className="count">{alerts.length}</div>
                </TooltipHost>
                {alertsIsVisible && (
                  <Callout
                    className={styles.alertsCallout}
                    target="#alert-icon"
                    isBeakVisible={false}
                    setInitialFocus
                    directionalHint={DirectionalHint.topLeftEdge}
                    onDismiss={toggleAlertsIsVisible}
                  >
                    <span className={styles.alertsClear} onClick={onClear}>
                      {loc.buttons.clear}
                    </span>
                    {map(alerts, (alert) => (
                      <div className={styles.alertsAlert} key={alert.correlationId}>
                        <div className={styles.alertsColumn}>
                          <span className={styles.alertsColumnTime}>{alert.time}</span>
                          <TooltipHost content={loc.tooltipContent.copy}>
                            <FontIcon iconName="copy" onClick={() => copyToClipboard(alert)} />
                          </TooltipHost>
                        </div>
                        <div className={styles.alertsColumn}>
                          <div className={styles.alertsColumnDate}>{alert.date}</div>
                          <div className={styles.alertsColumnTitle}>
                          Error code: &quot;{alert.errorCode}&ldquo;
                          </div>
                          <div className={styles.alertsColumnMessage}>
                          Message: &quot;{alert.message}&ldquo;
                          </div>
                        </div>
                      </div>
                    ))}
                  </Callout>
                )}
              </div>
            ) : null}
            <SettingsMenu />
            <div className={styles.userName}>{name}</div>
            <button className={styles.logoutButton} onClick={handleLogout}>
              {loc.buttons.signOut}
            </button>
          </div>
        )}
      </nav>
      { searchBySerialIsOpend && <SearchBySerial
        onDismiss={() => setSearchBySerialIsOpend(false)}
      />}
    </>
  );
};
