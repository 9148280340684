import { BasicCheckbox } from "components/common/BasicCheckbox";
import { ICommandBarAction } from "components/common/CommandBar";
import { UncontrolledValueInput } from "components/common/UncontrolledValueInput";
import { CommandbarCommands } from "constants/enums";
import { UserBrowseDto, UserPermissions } from "generated-sources/openapi";
import loc from "localization";

export const getActions = (
  selectedItem: UserBrowseDto | undefined
): ICommandBarAction[] => [{
  key: "add",
  text: loc.buttons.add,
  command: CommandbarCommands.Add,
  disabled: false,
  display: true
}, {
  key: "delete",
  text: loc.buttons.delete,
  command: CommandbarCommands.Delete,
  disabled: !(selectedItem && !selectedItem.deleted),
  display: true,
}, {
  key: "excel",
  text: "Excel",
  command: CommandbarCommands.Excel,
  disabled: false,
  display: true,
}];

export const getColumns = (
  onCheckboxChange: (item: UserBrowseDto, field: keyof UserPermissions, value: boolean) => void,
  isPermissionsLoading: boolean,
  onLastNameBlur: (item: UserBrowseDto, value: string) => void,
  onFirstNameBlur: (item: UserBrowseDto, value: string) => void,
  isNameLoading: boolean,
) => [
  {
    key: "tabNumber",
    name: loc.columns.tabNumber,
    fieldName: "tn",
    isResizable: true,
    minWidth: 65,
    maxWidth: 75
  },
  {
    key: "company",
    name: loc.columns.company,
    fieldName: "company",
    isResizable: true,
    minWidth: 30,
    maxWidth: 70
  },
  {
    key: "lastName",
    name: loc.columns.lastName,
    fieldName: "lastName",
    isResizable: true,
    minWidth: 100,
    maxWidth: 100,
    onRender: (item: UserBrowseDto) => (
      <UncontrolledValueInput
        disabled={isNameLoading || item.deleted}
        value={item.lastName || ""}
        onBlur={(value) => onLastNameBlur(item, value)}
      />
    )
  },
  {
    key: "firstName",
    name: loc.columns.firstName,
    fieldName: "firstName",
    isResizable: true,
    minWidth: 100,
    maxWidth: 100,
    onRender: (item: UserBrowseDto) => (
      <UncontrolledValueInput
        disabled={isNameLoading || item.deleted}
        value={item.firstName || ""}
        onBlur={(value) => onFirstNameBlur(item, value)}
      />
    )
  },
  {
    key: "spek",
    name: loc.columns.spek,
    isResizable: true,
    minWidth: 30,
    maxWidth: 35,
    onRender: (item: UserBrowseDto) => (
      <BasicCheckbox
        disabled={isPermissionsLoading}
        checked={item.permissions?.fullControl}
        onChange={(value: boolean) => {
          onCheckboxChange(item, "fullControl", value);
        }}
      />
    ),
  },
  {
    key: "changeOfCompany",
    name: loc.columns.changeOfCompany,
    fieldName: "",
    isResizable: true,
    minWidth: 30,
    maxWidth: 40,
    onRender: (item: UserBrowseDto) => (
      <BasicCheckbox
        disabled={isPermissionsLoading}
        checked={item.permissions?.browseOrganizations}
        onChange={(value: boolean) => {
          onCheckboxChange(item, "browseOrganizations", value);
        }}
      />
    ),
  },
  {
    key: "wh",
    name: loc.columns.wh,
    isResizable: true,
    minWidth: 30,
    maxWidth: 40,
    onRender: (item: UserBrowseDto) => (
      <BasicCheckbox
        disabled={isPermissionsLoading}
        checked={item.permissions?.skl}
        onChange={(value: boolean) => {
          onCheckboxChange(item, "skl", value);
        }}
      />
    ),
  },
  {
    key: "rmControl",
    name: loc.columns.rmControl,
    fieldName: "",
    isResizable: true,
    minWidth: 30,
    maxWidth: 40,
    onRender: (item: UserBrowseDto) => (
      <BasicCheckbox
        disabled={isPermissionsLoading}
        checked={item.permissions?.otk}
        onChange={(value: boolean) => {
          onCheckboxChange(item, "otk", value);
        }}
      />
    ),
  },
  {
    key: "planRepair",
    name: loc.columns.planRepair,
    isResizable: true,
    minWidth: 40,
    maxWidth: 45,
    onRender: (item: UserBrowseDto) => (
      <BasicCheckbox
        disabled={isPermissionsLoading}
        checked={item.permissions?.planRem}
        onChange={(value: boolean) => {
          onCheckboxChange(item, "planRem", value);
        }}
      />
    ),
  },
  {
    key: "ws",
    name: loc.columns.ws,
    fieldName: "",
    isResizable: true,
    minWidth: 40,
    maxWidth: 50,
    onRender: (item: UserBrowseDto) => (
      <BasicCheckbox
        disabled={isPermissionsLoading}
        checked={item.permissions?.skl}
        onChange={(value: boolean) => {
          onCheckboxChange(item, "skl", value);
        }}
      />
    ),
  },
  {
    key: "defects",
    name: loc.columns.defects,
    fieldName: "",
    isResizable: true,
    minWidth: 50,
    maxWidth: 60,
    onRender: (item: UserBrowseDto) => (
      <BasicCheckbox
        disabled={isPermissionsLoading}
        checked={item.permissions?.defect}
        onChange={(value: boolean) => {
          onCheckboxChange(item, "defect", value);
        }}
      />
    ),
  },
  {
    key: "send",
    name: loc.columns.send,
    fieldName: "",
    isResizable: true,
    minWidth: 50,
    maxWidth: 60,
    onRender: (item: UserBrowseDto) => (
      <BasicCheckbox
        disabled={isPermissionsLoading}
        checked={item.permissions?.otpr}
        onChange={(value: boolean) => {
          onCheckboxChange(item, "otpr", value);
        }}
      />
    ),
  },
  {
    key: "deleted",
    name: loc.columns.deleted,
    isResizable: true,
    minWidth: 50,
    maxWidth: 60,
    onRender: (item: UserBrowseDto) => (
      <BasicCheckbox
        disabled
        checked={item.deleted}
      />
    ),
  },
  {
    key: "emailSettings",
    name: loc.columns.emailSettings,
    fieldName: "email",
    isResizable: true,
    minWidth: 100,
    maxWidth: 194
  }
];