import { useContext, useState, useEffect } from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import { every } from "lodash-es";
import { useApi, useHttp } from "hooks";
import { LanguageContext } from "context/languageContext";
import { CommandbarCommands } from "constants/enums";
import { BasicModal, BasicModalColumn, BasicModalColumns, BasicModalContent } from "components/common/BasicModal";
import { CommandWrapper } from "components/common/CommandWrapper";
import { InfoContainer } from "components/common/InfoContainer";
import { DeviceAuditBrowseDto, DeviceHistoryBrowseDto, SearchDeviceBrowseDto, UserShortProfileDto } from "generated-sources/openapi";
import styles from "../AddDefectModal/styles.module.scss";
import { WorkshopMovementsTab } from "./WorkshopMovementsTab";
import { SearchBySerialTab } from "./SearchBySerialTab";
import classNames from "classnames";
import { AuditTab } from "./AuditTab";
import { DeviceModal } from "..";

export interface IDeviceHistoryModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  basicPayload: { firmId: number; deviceId: number };
  updateState: () => void;
  onDismiss: () => void;
}

export const DeviceHistoryModal = (props: IDeviceHistoryModalProps) => {
  const { devicesApi, searchApi, userDirectoryApi } = useApi();
  const warehousesHttp = useHttp<SearchDeviceBrowseDto[]>();
  const devicesHttp = useHttp<DeviceHistoryBrowseDto[]>();
  const auditHistoryHttp = useHttp<DeviceAuditBrowseDto[]>();
  const usersHttp = useHttp<UserShortProfileDto[]>();

  const { loc } = useContext(LanguageContext);
  const [workshopMovementsHistory, setWorkshopMovementsHistory] = useState<DeviceHistoryBrowseDto[]>([]);
  const [warehouses, setWarehouses] = useState<SearchDeviceBrowseDto[]>([]);
  const [usersList, setUsersList] = useState<UserShortProfileDto[]>([]);
  const [auditEvents, setAuditEvents] = useState<DeviceAuditBrowseDto[]>([]);

  const [deviceIdForDisplay, setDeviceIdForDisplay] = useState(0);
  const [firmIdForDisplay, setFirmIdForDisplay] = useState(0);

  const [activeCommand, setActiveCommand] = useState<CommandbarCommands>(CommandbarCommands.None);

  const handleModalDismiss = () => setActiveCommand(CommandbarCommands.None);

  const isDisabledSearchBySerial = every(props.formik.values.nomer, el => !Number(el));

  const sortHistoryByDate = (events: DeviceHistoryBrowseDto[]) => {
    return [...events].sort((event1, event2) => {
      return (new Date(event2.soDate || "")?.getTime() || 0) - (new Date(event1.soDate || "")?.getTime() || 0);
    });
  };

  const openDeviceModal = (deviceId: number, firmId: number) => {
    setDeviceIdForDisplay(deviceId);
    setFirmIdForDisplay(firmId);
    setActiveCommand(CommandbarCommands.Display);
  };

  const getUsers = () => {
    usersHttp
      .request(() => userDirectoryApi.apiUserDirectoryUsersGet())
      .then((res) => {
        setUsersList(res.data);
      });
  };

  const loadAuditEvents = () => {
    auditHistoryHttp
      .request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralAuditGet({
        ...props.basicPayload
      }))
      .then((res) => {
        setAuditEvents(res.data);
      });
  };

  const loadHistory = () => {
    devicesHttp
      .request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralHistoryGet({
        ...props.basicPayload,
      }))
      .then((res) => {
        const sortedHistory = sortHistoryByDate(res.data);
        setWorkshopMovementsHistory(sortedHistory);
      });
  };

  const search = () => {
    if (!isDisabledSearchBySerial) {
      warehousesHttp.request(() => searchApi.apiOpSearchBrowseSerialGet({
        serial: props.formik.values.nomer,
      })).then(res => {
        setWarehouses(res.data);
      });
    }
  };

  useEffect(() => {
    loadHistory();
    search();
    getUsers();
    loadAuditEvents();
  }, []);

  return (
    <BasicModal
      additionalClassName={classNames(styles.addDefectModal, { [styles.deviceHistoryModal]: true })}
      isLoading={devicesHttp.isLoading || warehousesHttp.isLoading || auditHistoryHttp.isLoading}
      title={loc.buttons.deviceHistory}
      errors={devicesHttp.errorMessages || warehousesHttp.errorMessages || auditHistoryHttp.errorMessages}
      dismissButtonText={loc.buttons.close}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <BasicModalColumns>
          <BasicModalColumn>
            <InfoContainer label={loc.columns.se}>{props.formik.values.seK || "-"}</InfoContainer>
            <InfoContainer label={loc.columns.sku}>{props.formik.values.sku || "-"}</InfoContainer>
          </BasicModalColumn>
          <BasicModalColumn>
            <InfoContainer label={loc.columns.serialN}>{props.formik.values.nomer || "-"}</InfoContainer>
            <InfoContainer label={loc.columns.serialOld}>{props.formik.values.oldNomer || "-"}</InfoContainer>
          </BasicModalColumn>
        </BasicModalColumns>
        <Pivot className={styles.tabs}>
          <PivotItem headerText={loc.labels.workshopMovements}>
            <WorkshopMovementsTab
              workshopMovementsHistory={workshopMovementsHistory}
            />
          </PivotItem>
          <PivotItem headerText={loc.labels.audit}>
            <AuditTab
              usersList={usersList}
              auditEvents={auditEvents}
            />
          </PivotItem>
          <PivotItem headerButtonProps={{ disabled: isDisabledSearchBySerial }} headerText={loc.labels.serialNumberSearch}>
            <SearchBySerialTab
              openDeviceModal={openDeviceModal}
              warehouses={warehouses}
            />
          </PivotItem>
        </Pivot>
        <CommandWrapper isActive={activeCommand === CommandbarCommands.Display}>
          <DeviceModal
            updateState={search}
            firmId={firmIdForDisplay}
            deviceId={deviceIdForDisplay}
            onDismiss={handleModalDismiss}
          />
        </CommandWrapper>
      </BasicModalContent>
    </BasicModal>
  );
};
