import { Callout, DirectionalHint, FontIcon, TooltipHost } from "@fluentui/react";
import loc from "localization";
import styles from "./styles.module.scss";
import { useContext, useMemo, useState } from "react";
import { CommandbarCommands } from "constants/enums";
import { PrintAccompanyingCardModal } from "./PrintAccompanyingCardModal";
import { getMenuItems } from "./config";
import { CommandWrapper } from "../CommandWrapper";
import { RoleManagementModal } from "./RoleManagementModal";
import { AuthContext } from "context/authContext";
import { Permissions } from "constants/enums";
import { checkPermission } from "utils";
import { ConfigureNotificationsModal } from "./ConfigureNotifications";
import { EmployeeBadgeModal } from "./EmployeeBadgeModal";

export const SettingsMenu= () => {
  const { user } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeCommand, setActiveCommand] = useState<CommandbarCommands>(CommandbarCommands.None);

  const handleModalDismiss = () => setActiveCommand(CommandbarCommands.None);

  const specPermission = useMemo(() => checkPermission(user, Permissions.FullControl), [user]);

  const menuItems = getMenuItems(specPermission || false);

  return (
    <div id="settings" className={styles.settingsBtn} onClick={() => setIsMenuOpen(!isMenuOpen)}>
      <TooltipHost
        content={loc.main.settings}
        directionalHint={DirectionalHint.rightCenter}
      >
        <FontIcon iconName="Settings"/>
      </TooltipHost>
      { isMenuOpen && (
        <Callout
          target="#settings"
          isBeakVisible={false}
          onDismiss={() => setIsMenuOpen(false)}
        >
          <ul className={styles.settingsBtnItems}>
            { menuItems.map(menuItem => (
              menuItem.display ? <li key={menuItem.key} onClick={() => setActiveCommand(menuItem.command)}>
                {menuItem.name}
              </li> : null
            )) }
          </ul>
        </Callout>
      ) }
      <CommandWrapper isActive={activeCommand === CommandbarCommands.AccompanyingCard}>
        <PrintAccompanyingCardModal
          onDismiss={handleModalDismiss}
        />
      </CommandWrapper>
      <CommandWrapper isActive={activeCommand === CommandbarCommands.RoleManagement}>
        <RoleManagementModal
          onDismiss={handleModalDismiss}
        />
      </CommandWrapper>
      <CommandWrapper isActive={activeCommand === CommandbarCommands.EmployeeBadge}>
        <EmployeeBadgeModal
          onDismiss={handleModalDismiss}
        />
      </CommandWrapper>
      <CommandWrapper isActive={activeCommand === CommandbarCommands.ConfigureNotifications}>
        <ConfigureNotificationsModal onDismiss={handleModalDismiss}/>
      </CommandWrapper>
    </div>
  );
};