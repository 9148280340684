import { IComboBoxOption } from "@fluentui/react";
import { BasicCheckbox } from "components/common/BasicCheckbox";
import { BasicDropdown } from "components/common/BasicDropdown";
import { BasicInput } from "components/common/BasicInput";
import { AppLanguage } from "constants/enums";
import { DeviceDefectsDto } from "generated-sources/openapi";
import loc from "localization";

export const getColumns = (
  language: AppLanguage,
  blamesOptions: IComboBoxOption[],
  onChange: (item: DeviceDefectsDto) => void,
  onBlur: (item: DeviceDefectsDto) => void,
  readonly?: boolean,
) => [
  {
    key: "osn",
    name: loc.labels.main,
    fieldName: "osn",
    minWidth: 35,
    maxWidth: 35,
    isResizable: true,
    onRender: (item: DeviceDefectsDto) => (
      <BasicCheckbox
        indeterminate={item.osn === null}
        checked={Boolean(item.osn)}
        disabled={readonly}
        onChange={(checked) => onBlur({ ...item, osn: checked })}
      />
    ),
  },
  {
    key: "defR",
    name: loc.labels.defect,
    fieldName: language === AppLanguage.Russian ? "defR" : "defE",
    minWidth: 200,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "repR",
    name: loc.labels.fix,
    fieldName: language === AppLanguage.Russian ? "repR" : "repE",
    minWidth: 150,
    maxWidth: 150,
    isResizable: true,
  },
  {
    key: "idStr",
    name: loc.columns.reason,
    fieldName: "idStr",
    minWidth: 120,
    maxWidth: 120,
    isResizable: true,
    onRender: (item: DeviceDefectsDto) => (
      <BasicDropdown
        itemKey={Number(item.vinId)}
        options={blamesOptions}
        onChange={(_, option?: IComboBoxOption) => onBlur({ ...item, vinId: Number(option?.key) })}
        disabled={readonly}
        clearButtonOff
      />
    ),
  },
  {
    key: "primec",
    name: loc.columns.note,
    fieldName: "primec",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    onRender: (item: DeviceDefectsDto) => (
      <BasicInput
        value={item.primec || ""}
        onChange={(val) => onChange({ ...item, primec: val })}
        disabled={readonly}
        onBlur={() => onBlur(item)}
      />
    ),
  },
];
