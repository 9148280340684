import { BasicCheckbox } from "components/common/BasicCheckbox";
import { InfoContainer } from "components/common/InfoContainer";
import loc from "localization";
import { useEffect } from "react";

interface IRejectionOfMaintenanceBlockProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
}

export const RejectionOfMaintenanceBlock = ({ formik }: IRejectionOfMaintenanceBlockProps) => {

  useEffect(() => {
    if(!formik.values.isValidWarranty) {
      formik.setFieldValue("prichina1", true);
      formik.handleSubmit();
    };
  }, [formik.values.isValidWarranty]);

  return (
    <>
      <InfoContainer>
        <ins>{loc.technicalConclusionModal.isNotUnderWarranty}</ins>
      </InfoContainer>
      <InfoContainer>
        <BasicCheckbox
          label={loc.formatString(
            loc.technicalConclusionModal.theWarrantyPeriodHasExpired,
            formik.values.warrantyTerm || 0,
            formik.values.warrantyTerm === 1
              ? loc.technicalConclusionModal.year || ""
              : loc.technicalConclusionModal.years || "",
          )}
          checked={Boolean(formik.values.prichina1)}
          onChange={(checked) => {
            formik.setFieldValue("prichina1", checked);
            formik.handleSubmit();
          }}
          disabled={!formik.values.enableReason1}
        />
      </InfoContainer>
      <InfoContainer>
        <BasicCheckbox
          label={loc.technicalConclusionModal.breachOfOperationConditions}
          checked={Boolean(formik.values.prichina2)}
          onChange={(checked) => {
            formik.setFieldValue("prichina2", checked);
            formik.handleSubmit();
          }}
          disabled={!formik.values.enableReason2}
        />
      </InfoContainer>
    </>
  );
};