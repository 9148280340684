import { format } from "date-fns";
import InputMask from "react-input-mask";
import { TextField } from "@fluentui/react";
import { transformStringToDate } from "utils";
import styles from "./styles.module.scss";
import loc from "localization";
import { DatePickerCallout } from "../DatePickerCallout";

interface DatePickerInputProps {
  monthCalendar?: boolean;
  className?: string;
  dateMask?: string;
  dateFormat?: string;
  name: string;
  value: string;
  errorMessage?: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  onChangeValue: (name: string, val: string) => void;
  disabled?: boolean;
  onBlur?: () => void;
  target?: string;
}

export const DatePickerInput = ({
  dateFormat,
  className,
  name,
  value,
  errorMessage,
  minDate,
  maxDate,
  onChangeValue,
  disabled,
  monthCalendar,
  dateMask,
  target,
  onBlur,
}: DatePickerInputProps) => {
  return (
    <InputMask
      mask={dateFormat || "99/99/9999"}
      disabled={disabled}
      className={styles.datePickerInput}
      name={name}
      value={value}
      onChange={(e) => onChangeValue(name, e.target.value)}
      onBlur={onBlur}
    >
      <TextField
        id={target || name}
        className={className}
        disabled={disabled}
        placeholder={monthCalendar ? loc.main.monthPlaceholder : loc.main.datePlaceholder}
        errorMessage={errorMessage}
        onRenderSuffix={() => (
          <DatePickerCallout
            value={transformStringToDate(value) || undefined}
            monthCalendar={monthCalendar}
            minDate={minDate}
            maxDate={maxDate}
            target={`#${target || name}`}
            onSelectDate={(date: Date) =>
              onChangeValue(name, format(date, dateMask || "dd/MM/yyyy"))
            }
            disabled={disabled}
            onBlur={onBlur}
          />
        )}
      />
    </InputMask>
  );
};
