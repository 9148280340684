import { Toggle } from "@fluentui/react";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface IBasicToggleProps {
  value: boolean;
  label?: string;
  setValue: (value: boolean) => void;
  offText?: string;
  onText?: string;
  inContainer?: boolean;
  rowReverse?: boolean;
}

export const BasicToggle = (props: IBasicToggleProps) => {
  return (
    <Toggle
      className={classNames(styles.basicToggle, {
        [styles.basicToggleinContainer]: props.inContainer,
        [styles.basicToggleRowReverse]: props.rowReverse,
      })}
      label={props.label}
      checked={props.value}
      offText={props.offText}
      onText={props.onText}
      onChange={(_, checked?: boolean) => props.setValue(!!checked)}
    />
  );
};
