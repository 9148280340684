import { BasicInput } from "components/common/BasicInput";
import { serialNumbersRegex } from "constants/regex";
import { LanguageContext } from "context/languageContext";
import { useContext, useState } from "react";
import { fillZeroes } from "utils";

interface ISerialNumInputProps {
  name?: string;
  value: string;
  onChange: (val: string) => void;
  onBlur?: () => void;
  errorMessage?: string;
  disabled?: boolean;
}

export const SerialNumInput = (props: ISerialNumInputProps) => {
  const [value, setValue] = useState(props.value);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const { loc } = useContext(LanguageContext);

  const handleBlur = () => {
    if (!isInvalid && value?.length > 0) {
      setValue(fillZeroes(value));
    }
    if (props.onBlur) {
      props.onBlur();
    };
  };

  const handleChange = (val: string) => {
    setValue(val);
    if (!serialNumbersRegex.test(val)) {
      setIsInvalid(true);
      props.onChange("");
    } else {
      setIsInvalid(false);
      props.onChange(val && fillZeroes(val));
    }
  };

  return (
    <BasicInput
      name={props.name}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      errorMessage={isInvalid ? loc.warnings.incorectValueErrorMessage : props.errorMessage}
      maxLength={10}
      disabled={props.disabled}
    />
  );
};
