import { useMemo, useState, useEffect, useContext } from "react";
import { DefaultButton, IComboBoxOption, SelectionMode } from "@fluentui/react";
import { initial, map } from "lodash-es";
import { AxiosResponse } from "axios";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { LanguageContext } from "context/languageContext";
import { AppLanguage, CommandbarCommands } from "constants/enums";
import { useApi, useHttp } from "hooks";
import { transformDateToString } from "utils";
import { DeviceRepairCostCalculationBrowseDto, DeviceReplacedSeDto, EmployeeBrowseDto } from "generated-sources/openapi";
import { ScrollableTableWrapper, Table } from "components/common/Table";
import { BasicDropdown } from "components/common/BasicDropdown";
import { BasicInput } from "components/common/BasicInput";
import { CommandWrapper } from "components/common/CommandWrapper";
import { GetDocumentModal } from "../GetDocumentModal";
import { getColumns } from "./config";
import styles from "./styles.module.scss";
interface IRepairCostCalculationModalProps {
  serialNumbers: { oldSerial: string, serial: string }
  basicPayload: { firmId: number; deviceId: number };
  deviceName: { en: string, ru: string };
  replacedSes: DeviceReplacedSeDto[];
  onDismiss: () => void;
}

export const RepairCostCalculationModal = (props: IRepairCostCalculationModalProps) => {
  const { loc, language } = useContext(LanguageContext);
  const { devicesApi, employeesApi } = useApi();
  const devicesHttp = useHttp();
  const employeeHttp = useHttp();
  const [repairCostCalculationInfo, setRepairCostCalculationInfo] = useState<DeviceRepairCostCalculationBrowseDto>();
  const [employees, setEmployees] = useState<EmployeeBrowseDto[]>([]);
  const [activeCommand, setActiveCommand] = useState<CommandbarCommands>(CommandbarCommands.None);
  const [dataIsLoaded, setDataIsLoaded] = useState<boolean>(false);
  const handleModalDismiss = () => setActiveCommand(CommandbarCommands.None);

  const repairCostCalculationRequest = (sotrId1?: number) => {
    return devicesHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationPut({
      ...props.basicPayload,
      repairCostCalculationUpdatePayload: {
        primec: String(repairCostCalculationInfo?.primec),
        sotrId1: sotrId1 || repairCostCalculationInfo?.sotrId1,
      }
    })).then(() => sotrId1 && setRepairCostCalculationInfo({ ...repairCostCalculationInfo, sotrId1 }));
  };

  const submitHandler = () => {
    repairCostCalculationRequest()
      .then(() => {
        props.onDismiss();
      });
  };

  const employeeOptions = useMemo(() => {
    return map(employees, employee => ({
      key: Number(employee.sotrId),
      text: `${language === AppLanguage.English ? employee.nameEn : employee.nameRu}`,
    }));
  }, [language, employees]);


  const getRepairCostCalculation = () => {
    devicesHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationGet(
      props.basicPayload
    )).then((res: AxiosResponse) => {
      setRepairCostCalculationInfo(res.data);
    });
  };

  const getEmployees = () => {
    employeeHttp.request(() => employeesApi.apiDictEmployeesBrowseGet({
      filterFirmId: props.basicPayload.firmId,
      filterPravoPod: 1
    })).then((res: AxiosResponse) => {
      setEmployees(res.data);
    });
  };

  const loadData = () => {
    employeeHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdRepairCostCalculationLoadDataPost(
      props.basicPayload
    )).then(() => {
      setDataIsLoaded(true);
      getRepairCostCalculation();
    });
  };

  const columns = useMemo(() => getColumns(language), [language]);

  useEffect(() => {
    getRepairCostCalculation();
    getEmployees();
  }, []);

  return (
    <BasicModal
      additionalClassName={styles.repairConstCalculationModal}
      isLoading={devicesHttp.isLoading || employeeHttp.isLoading}
      title={loc.formatString(
        loc.deviceModal.rccSerial,
        props.serialNumbers.serial || "",
        props.serialNumbers.oldSerial || ""
      )}
      errors={devicesHttp.errorMessages || employeeHttp.errorMessages}
      submitMessage={loc.warnings.changesAreSavedAutomatically}
      dismissButtonText={loc.buttons.close}
      onSubmit={submitHandler}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <div className="actions">
          <DefaultButton
            text={loc.buttons.loadData}
            onClick={loadData}
            disabled={dataIsLoaded}
          />
          <DefaultButton
            text={loc.buttons.print}
            onClick={() => setActiveCommand(CommandbarCommands.Print)}
            disabled={!repairCostCalculationInfo?.primec?.length}
          />
          <DefaultButton
            text={loc.buttons.excel}
            onClick={() => setActiveCommand(CommandbarCommands.Excel)}
            disabled={!repairCostCalculationInfo?.primec?.length}
          />
        </div>
        <InfoContainer
          label={loc.columns.date}
        >
          <>
            {transformDateToString(repairCostCalculationInfo?.rccData) || ""}
          </>
        </InfoContainer>
        <InfoContainer
          label={loc.columns.name}
        >
          <>
            {language === AppLanguage.Russian
              ? props.deviceName.ru
              : props.deviceName.en
            }
          </>
        </InfoContainer>
        <div className="scrollable-warapper">
          <ScrollableTableWrapper noData={!initial(props.replacedSes)?.length}>
            <Table
              items={initial(props.replacedSes)}
              columns={columns}
              selectionMode={SelectionMode.none}
            />
          </ScrollableTableWrapper>
        </div>
        <div className={styles.notificationBlock}>
          <InfoContainer label={
            language === AppLanguage.Russian
              ? loc.columns.fullName
              : loc.columns.name
          }>
            <BasicDropdown
              options={employeeOptions}
              itemKey={Number(repairCostCalculationInfo?.sotrId1)}
              onChange={(_, option?: IComboBoxOption) => {
                Number(option?.key) !== repairCostCalculationInfo?.sotrId1 && repairCostCalculationRequest(Number(option?.key));
              }}
            />
          </InfoContainer>
          <InfoContainer label={loc.labels.notes}>
            <BasicInput
              multiline
              value={repairCostCalculationInfo?.primec || ""}
              onChange={(value: string) => {
                setRepairCostCalculationInfo({ ...repairCostCalculationInfo, primec: value });
              }}
              onBlur={() => repairCostCalculationRequest()}
              maxLength={254}
            />
          </InfoContainer>
        </div>
        <CommandWrapper isActive={activeCommand === CommandbarCommands.Excel}>
          <GetDocumentModal
            basicPayload={props.basicPayload}
            onDismiss={handleModalDismiss}
            typeOfDocument="excel"
          />
        </CommandWrapper>
        <CommandWrapper isActive={activeCommand === CommandbarCommands.Print}>
          <GetDocumentModal
            basicPayload={props.basicPayload}
            onDismiss={handleModalDismiss}
            typeOfDocument="pdf"
          />
        </CommandWrapper>
      </BasicModalContent>
    </BasicModal>
  );
};