import { BasicModalColumn, BasicModalColumns } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import styles from "../styles.module.scss";
import { useContext } from "react";
import { LanguageContext } from "context/languageContext";
import { AppLanguage } from "constants/enums";
import { transformDateToString } from "utils";

interface IDeviceInfoBlockProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
}

export const DeviceInfoBlock = ({ formik }: IDeviceInfoBlockProps) => {
  const { loc, language } = useContext(LanguageContext);

  return (
    <>
      <InfoContainer
        infoContainerLabelClassName={styles.smallSizeLabel}
        label={loc.columns.sku}
      >
        {formik.values.sku}
      </InfoContainer>
      <InfoContainer
        infoContainerLabelClassName={styles.smallSizeLabel}
        label={loc.columns.name}
      >
        {language === AppLanguage.Russian
          ? formik.values.nameRu
          : formik.values.nameEn
        }
      </InfoContainer>
      <BasicModalColumns>
        <BasicModalColumn>
          <InfoContainer
            label={loc.columns.serialNumber}
          >
            {formik.values.oldNomer || "-"}
          </InfoContainer>
          <InfoContainer
            label={loc.labels.saleDate}
          >
            <>{transformDateToString(formik.values.dataProd) || "-"}</>
          </InfoContainer>
        </BasicModalColumn>
        <BasicModalColumn>
          <InfoContainer
            label={loc.labels.newSerialNumber}
          >
            {formik.values.nomer || "-"}
          </InfoContainer>
          <InfoContainer
            label={loc.columns.productionDate}
          >
            <>{transformDateToString(formik.values.dataProiz) || "-"}</>
          </InfoContainer>
        </BasicModalColumn>
      </BasicModalColumns>
    </>
  );
};