import { NumberField } from "components/common/NumberField";
import { AppLanguage } from "constants/enums";
import loc from "localization";
import { RepairAreaSparePartsDto } from "generated-sources/openapi";

export const getColumns = (language: AppLanguage, onChange: (item: RepairAreaSparePartsDto) => void) => [
  {
    key: "seK",
    name: loc.columns.se,
    fieldName: "seK",
    minWidth: 60,
    maxWidth: 60,
    isResizable: true,
  },
  {
    key: "nameRu",
    name: loc.columns.name,
    fieldName: language === AppLanguage.Russian ? "nameRu" : "nameEn",
    minWidth: 300,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "kol",
    name: loc.columns.qty,
    fieldName: "kol",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    onRender: (item: RepairAreaSparePartsDto) => (
      <NumberField
        item={item}
        name="quantity"
        integer={10}
        fract={0}
        value={`${item.quantity}`}
        onChange={(item: RepairAreaSparePartsDto, field: string, value?: string) => onChange({ ...item, [field]: value })}
      />
    ),
  },
];
