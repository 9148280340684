import { useNavigate } from "react-router-dom";
import { BasicDropdown } from "../BasicDropdown";
import styles from "./styles.module.scss";

interface IViewDropdownProps {
  currentView: string;
  viewOptions: { key: string, text: string }[];
  fullPath: string;
}

export const ViewDropdown = (props: IViewDropdownProps) => {
  const navTo = useNavigate();
  return (
    <BasicDropdown
      className={styles.viewDropdown}
      itemKey={props.currentView}
      onChange={
        (_e, val) => typeof val?.key === "string" && navTo(`${props.fullPath}${val.key}`)
      }
      options={props.viewOptions}
      clearButtonOff
    />
  );
};