import { AxiosResponse } from "axios";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { BasicRadioGroup } from "components/common/BasicRadioGroup";
import { InfoContainer } from "components/common/InfoContainer";
import { getChoiseGroupLangOptions } from "constants/options";
import { LanguageContext } from "context/languageContext";
import { useFormik } from "formik";
import { PaperSizeType } from "generated-sources/openapi";
import { useApi, useHttp } from "hooks";
import { useContext } from "react";
import { getLcid, getPdf } from "utils";
import { getAccompayingCardSetting } from "utils/settings";

interface IPrintModalProps {
  basicPayload: { firmId: number, deviceId: number };
  onDismiss: () => void;
}

export const PrintModal = (props: IPrintModalProps) => {
  const { devicesApi } = useApi();
  const { isLoading, request, errorMessages } = useHttp();
  const { loc, language } = useContext(LanguageContext);

  const submitHandler = () => {
    request(() => devicesApi.apiOpDevicesExportPdfPost({
      ...props.basicPayload,
      paperSize: formik.values.paperSize,
      lcid: getLcid(formik.values.lang),
    }, { responseType: "blob" })).then((res: AxiosResponse) => {
      getPdf(res);
      props.onDismiss();
    });
  };

  const formik = useFormik({
    initialValues: {
      lang: language,
      paperSize: getAccompayingCardSetting()
    },
    validateOnChange: true,
    onSubmit: submitHandler,
  });

  return (
    <BasicModal
      isLoading={isLoading}
      title={loc.main.selectLanguage}
      errors={errorMessages}
      submitButtonText={loc.buttons.print}
      dismissButtonText={loc.buttons.cancel}
      onSubmit={formik.handleSubmit}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <InfoContainer label={loc.labels.documentLang}>
          <>
            <BasicRadioGroup
              name="lang"
              selectedKey={formik.values.lang}
              options={getChoiseGroupLangOptions()}
              onChange={formik.setFieldValue}
            />
            <BasicRadioGroup
              name="paperSize"
              selectedKey={formik.values.paperSize}
              options={[
                { key: PaperSizeType.A4, text: PaperSizeType.A4 },
                { key: PaperSizeType.A5, text: PaperSizeType.A5 },
              ]}
              onChange={formik.setFieldValue}
            />
          </>
        </InfoContainer>
      </BasicModalContent>
    </BasicModal>
  );
};