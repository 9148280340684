import { createContext, useMemo } from "react";
import { lastDayOfMonth, startOfMonth } from "date-fns";
import { IDateFilter } from "components/common/DatePeriodInput";
import { transformDateToString, transformStringToDate, validPeriod } from "utils";
import { useStorageState } from "hooks/useStorageState";

export interface IProps {
  children: JSX.Element;
}

interface IGlobalFilterContext {
  period: IDateFilter;
  setPeriod: (period: IDateFilter) => void;
  periodIsValid: boolean;
  counterpartyId: number;
  setCounterpartyId: (id: number) => void;
  // emptyPeriod: IDateFilter,
}

export const GlobalFilterContext = createContext<IGlobalFilterContext>({
  period: {
    dateFrom: "",
    dateTo: "",
  },
  setPeriod: () => undefined,
  periodIsValid: false,
  counterpartyId: 0,
  setCounterpartyId: () => undefined,
  // emptyPeriod: {
  //   dateFrom: "",
  //   dateTo: "",
  // },
});

export const GlobalFilterContextProvider = (props: IProps) => {
  const initialFilters = {
    counterpartyId: 0,
    period: {
      dateFrom: transformDateToString(startOfMonth(new Date())),
      dateTo: transformDateToString(lastDayOfMonth(new Date())),
    }
  };
  const [{ period, counterpartyId }, setFilters] = useStorageState("globalFilter", initialFilters);
  const setCounterpartyId = (counterpartyId: number) => setFilters((filters) => ({ ...filters, counterpartyId }));
  const setPeriod = (period: IDateFilter) => setFilters((filters) => ({ ...filters, period }));

  // const emptyPeriod = useMemo(() => ({
  //   dateFrom: "01/01/1900",
  //   dateTo: transformDateToString(new Date(
  //     new Date().getFullYear(),
  //     new Date().getMonth() + 6,
  //     new Date().getDate()
  //   )),
  // }), []);
  const periodIsValid = useMemo(() => {
    if (!period.dateFrom.length && transformStringToDate(period.dateTo)) return true;
    if (transformStringToDate(period.dateFrom) && !period.dateTo) return true;
    if (!period.dateFrom.length && !period.dateTo) return true;
    return validPeriod(period);
  }, [period.dateFrom, period.dateTo]);

  return (
    <GlobalFilterContext.Provider
      value={{
        period,
        setPeriod,
        counterpartyId,
        setCounterpartyId,
        periodIsValid,
        // emptyPeriod,
      }}
    >
      {props.children}
    </GlobalFilterContext.Provider>
  );
};
