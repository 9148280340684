import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { initializeIcons, loadTheme } from "@fluentui/react";
import { ReactNotifications } from "react-notifications-component";
import { ApplicationRouter } from "router";
import { AppInsightsContextProvider } from "context/appInsightsContext";
import { AuthContextProvider } from "context/authContext";
import { CheckUpdatesContextProvider } from "context/checkUpdatesContext";
import { LanguageContextProvider } from "context/languageContext";
import { GlobalFilterContextProvider } from "context/globalFilterContext";
import { TodayEventsContextProvider } from "context/todayEventsContext";
import { SignInPage } from "components/pages/SignInPage";
import { ErrorBoundary } from "context/errorBoundaryContext";
import { msalConfig } from "authConfig";
import "./styles/main.scss";

const msalInstance = new PublicClientApplication(msalConfig);

const App = () => {
  useEffect(() => {
    initializeIcons(undefined, { disableWarnings: true });
    loadTheme({ palette: { themePrimary: "#009688", themeDark: "#324c4d" } });
    document.title = `${document.title} ${process.env.REACT_APP_ENV}`;
  }, []);
  return (
    <div className="app">
      <AuthenticatedTemplate>
        <AppInsightsContextProvider>
          <ErrorBoundary>
            <AuthContextProvider>
              <LanguageContextProvider>
                <CheckUpdatesContextProvider>
                  <TodayEventsContextProvider>
                    <ReactNotifications />
                    <ErrorBoundary forceReload={true}>
                      <GlobalFilterContextProvider>
                        <ApplicationRouter />
                      </GlobalFilterContextProvider>
                    </ErrorBoundary>
                  </TodayEventsContextProvider>
                </CheckUpdatesContextProvider>
              </LanguageContextProvider>
            </AuthContextProvider>
          </ErrorBoundary>
        </AppInsightsContextProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignInPage />
      </UnauthenticatedTemplate>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>,
);
