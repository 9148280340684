import { BasicDropdown } from "components/common/BasicDropdown";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { PaperSizeType } from "generated-sources/openapi";
import { useContext, useState } from "react";
import styles from "./styles.module.scss";
import { LanguageContext } from "context/languageContext";
import { getAccompayingCardSetting, setAccompayingCardSetting } from "utils/settings";

interface IPrintAccompanyingCardModalProps {
  onDismiss: () => void;
}

export const PrintAccompanyingCardModal = (props: IPrintAccompanyingCardModalProps) => {
  const accompayingCardSetting = getAccompayingCardSetting();
  const { loc } = useContext(LanguageContext);
  const [paperSize, setPaperSize] = useState<PaperSizeType>(accompayingCardSetting);

  const downloadDocument = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setAccompayingCardSetting(paperSize);
    props.onDismiss();
  };

  return (
    <BasicModal
      isLoading={false}
      title={loc.settings.printAccompanyingCard}
      submitButtonText={loc.buttons.save}
      dismissButtonText={loc.buttons.cancel}
      onDismiss={props.onDismiss}
      onSubmit={downloadDocument}
    >
      <BasicModalContent>
        <div className={styles.messageWrapper}>
          <span>{loc.settings.printAccompanyingCard}</span>
          <BasicDropdown
            className={styles.dropdown}
            options={[{
              key: PaperSizeType.A4,
              text: "A4"
            }, {
              key: PaperSizeType.A5,
              text: "A5"
            }]}
            itemKey={paperSize}
            onChange={(_e, value) => {
              if (value) {
                setPaperSize(value.key as PaperSizeType);
              }
            }}
            clearButtonOff
          />
        </div>
      </BasicModalContent>
    </BasicModal>
  );
};