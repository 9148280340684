import { filter, find, indexOf, isUndefined, last, map, reduce, sortBy } from "lodash-es";
import { DefectBrowseDto } from "generated-sources/openapi/api";
import { IDefectGroup } from "./types";

const defineLevel = (defect: DefectBrowseDto) => {
  return filter(defect.defectNumber?.split("."), (item) => item !== "0").length - 1;
};

export const modifyDefects = (data: DefectBrowseDto[]): IDefectGroup[] => {
  return sortByDefectNumber(
    map(data, (item: DefectBrowseDto) => ({
      ...item,
      isCollapsed: true,
      level: defineLevel(item),
      isParent: filter(data, (defect) => defect.parentId === item.defectId).length > 0,
    })),
    0,
  );
};

export const modifyDefect = (item: DefectBrowseDto, data: IDefectGroup[]) => ({
  ...item,
  isCollapsed: true,
  level: defineLevel(item),
  isParent: filter(data, (defect) => defect.parentId === item.defectId).length > 0,
});

const defineChildren = (defects: IDefectGroup[], items: IDefectGroup[]): IDefectGroup[] => {
  return reduce(
    items,
    (prev: IDefectGroup[], next: IDefectGroup) => {
      const children = next.isCollapsed
        ? []
        : sortByDefectNumber(
          filter(defects, (defect) => defect.parentId === next.defectId),
          next.level + 1,
        );
      return [...prev, next, ...defineChildren(defects, children)];
    },
    [],
  );
};

export const getGroupedDefects = (defects: IDefectGroup[]): IDefectGroup[] => {
  const parents = filter(defects, (defect) => defect.parentId === 0);
  const items = defineChildren(defects, parents);
  return items;
};

export const getFilteredDefectNumbers = (defectNumbers: string[]) => {
  return reduce(defectNumbers, (prev: string[], next: string) => {
    const newArray: string[] = [];
    const numbers = next?.split(".");
    for (let i = 0; i < numbers.length; i++) {
      newArray.push(numbers.slice(0, i + 1).join("."));
    };
    return [...prev, ...newArray];
  }, []);
};

export const sortByDefectNumber = (defects: IDefectGroup[], level: number) => {
  return sortBy(defects, (item) => Number(item.defectNumber?.split(".")[level]));
};

export const getAheadDefectNumber = (defects: IDefectGroup[], selectedDefect?: IDefectGroup) => {
  const defectChildren = sortByDefectNumber(filter(
    defects,
    (defect) => defect.parentId === (!isUndefined(selectedDefect) ? selectedDefect?.defectId : 0),
  ), Number(selectedDefect?.level) + 1);
  const enabledNumberIndex = indexOf(defectChildren, find(defectChildren, (child, index) => {
    return Number(last(child?.defectNumber?.split("."))) !== index + 1;
  })) - 1;

  const defectNumberByIndex = defectChildren[enabledNumberIndex]?.defectNumber;
  const lastDefectNumber = last(defectChildren)?.defectNumber;
  const defectNumberAlradyExists = find(defectChildren, child => child.defectNumber === defectNumberByIndex);
  const enabledNumber = defectNumberByIndex
    ? (defectNumberAlradyExists ? lastDefectNumber : defectNumberByIndex)
    : lastDefectNumber;
  
  return defectChildren.length
    ? map(
      enabledNumber?.split("."),
      (item: string, index: number) =>
        Number(item) +
        (index === (!isUndefined(selectedDefect) ? selectedDefect?.level + 1 : 0) ? 1 : 0),
    ).join(".")
    : `${selectedDefect?.defectNumber}.1`;
};
