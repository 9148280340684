import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { Spinner, SpinnerSize } from "@fluentui/react";

interface IWidgetProps {
	topHeader: string;
	bottomTitle?: string;
  count: number;
  link?: string;
  isLoading: boolean;
  onClick?: () => void;
};

export const Widget = (props: IWidgetProps) => {
  return (
    <Link onClick={props.onClick} className={styles.widget} to={props.link || ""}>
      <div>
        <div className={styles.title}>{props.topHeader}</div>
        <div className={styles.count}>
          {props.isLoading ? <Spinner className={styles.spinner} size={SpinnerSize.large} /> : props.count}
        </div>
        <div className={styles.item}>{props.bottomTitle}</div>
        
      </div>
    </Link>
  );
};