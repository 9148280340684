import { isArray, map } from "lodash-es";
import { MessageBar, MessageBarType } from "@fluentui/react";

interface IMessageBlockProps {
  messages: string | string[];
  isWarning?: boolean;
}

export const MessageBlock = ({ messages, isWarning }: IMessageBlockProps) => {
  return messages.length ? (
    <div className="message-block">
      {isArray(messages) ? (
        map(messages, (message) => (
          <MessageBar
            key={message}
            messageBarType={isWarning ? MessageBarType.warning : MessageBarType.error}
          >
            {message}
          </MessageBar>
        ))
      ) : (
        <MessageBar messageBarType={isWarning ? MessageBarType.warning : MessageBarType.error}>
          {messages}
        </MessageBar>
      )}
    </div>
  ) : null;
};
