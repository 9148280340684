import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { map, last, isNull, filter } from "lodash-es";
import { DirectionalHint, TooltipHost, FontIcon, CommandBarButton, TooltipDelay } from "@fluentui/react";
import { ROOT_PATH } from "constants/paths";
import { AuthContext } from "context/authContext";
import { LanguageContext } from "context/languageContext";
import appLogo from "images/appLogo.png";
import { getMenuItems, IMenuItem } from "./config";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface IRenderLinkListProps {
  item: IMenuItem;
  icon?: JSX.Element;
}

const RenderLinkList = (props: IRenderLinkListProps) => {
  const navigate = useNavigate();
  const { pathname } = window.location;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const toggleList = () => setIsExpanded(!isExpanded);
  const linkIsActive = (url: string) => pathname.includes(url);
  // useEffect(() => {
  //   find(props.item.links, (link) => {
  //     return pathname.includes(link.key) || map(link?.links, (item) => item.url).includes(pathname);
  //   })
  //     ? setIsExpanded(true)
  //     : setIsExpanded(false);
  // }, [pathname]);
  return (
    <div
      className={classNames(styles.linkExpandedBlock, "link-expanded-block", {
        [styles.linkExpandedBlockExpanded]: isExpanded,
      })}
    >
      <div
        className={classNames(
          styles.linkExpandedBlockTitle,
          { [styles.linkExpandedBlockTitleExpanded]: isExpanded },
          "link-expanded-block-title",
          "flex-row",
        )}
        onClick={toggleList}
      >
        <div className={classNames({ [styles.iconWrapper]: props.icon })} style={props.item.style}>
          {props.icon}
          {props.item.name}
        </div>
        <FontIcon
          className={styles.iconExpand}
          iconName={isExpanded ? "ChevronUpMed" : "ChevronDownMed"}
        />
      </div>
      {isExpanded ? (
        <ul>
          {map(props.item.links, (link) => (
            <li
              key={link.key}
              className={classNames(
                styles.item,
                { [styles.itemIsActive]: link.url && linkIsActive(link.url) },
                { [styles.itemIsDisabled]: link.disabled },
              )}
              style={{ borderBottom: link.underline ? "1px solid #8c8c8c" : "" }}
              onClick={() => link.url && navigate(link.url)}
            >
              {!link.links?.length ? (
                link.newTab ? (
                  <a
                    className={styles.itemLink}
                    href={link.url}
                    style={link.style}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link.name}
                  </a>
                ) : link.url && (
                  <Link
                    className={classNames(styles.listPointLink, styles.itemLink)}
                    to={link.url}
                    style={link.style}
                  >
                    {link.name}
                  </Link>
                )
              ) : (
                <RenderLinkList item={link} />
              )}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

interface IRenderCalloutContentProps {
  item: IMenuItem;
}

const RenderCalloutContent = ({ item }: IRenderCalloutContentProps) => (
  <ul>
    {map(
      item.links,
      (link) =>
        !link.disabled && (
          <li key={link.key}>
            {link.url ? (
              link.newTab ? (
                <a href={link.url} target="_blank" rel="noreferrer">
                  {link.name}
                </a>
              ) : (
                <Link to={link.url}>{link.name}</Link>
              )
            ) : !link.links?.length && link.url ? (
              <Link to={link.url}>{link.name}</Link>
            ) : (
              <>
                {filter(link.links, (link) => !link.disabled).length ? link.name : ""}
                <RenderCalloutContent item={link} />
              </>
            )}
          </li>
        ),
    )}
  </ul>
);

interface IRenderCalloutWrapperProps {
  children: JSX.Element;
  item: IMenuItem;
  leftMenuIsVisible: boolean;
}

const RenderCalloutWrapper = ({ children, item, leftMenuIsVisible }: IRenderCalloutWrapperProps) =>
  leftMenuIsVisible ? (
    children
  ) : (
    <TooltipHost
      className={styles.tooltipMenu}
      tooltipProps={{
        onRenderContent: () =>
          item.disabled ? null : (
            <div>
              <div className={styles.tooltipTitle}>{item.name}</div>
              {item?.links ? <RenderCalloutContent item={item} /> : null}
            </div>
          ),
      }}
      calloutProps={{ directionalHint: DirectionalHint.rightTopEdge, isBeakVisible: false }}
      delay={TooltipDelay.zero} // не меняйте значение, т.к. при другой задержке в данном случае ломается тултип
    >
      {children}
    </TooltipHost>
  );

interface IRenderIconWrapperProps {
  item: IMenuItem;
  children: JSX.Element;
}

const RenderIconWrapper = ({ item, children }: IRenderIconWrapperProps) =>
  !item.links?.length && item.url ? <Link to={item.url}>{children}</Link> : children;

export const LeftMenu = () => {
  const { loc } = useContext(LanguageContext);
  const navigate = useNavigate();
  const { permissions } = useContext(AuthContext);
  const menuIsCollapsed = localStorage.getItem("menuIsCollapsed");
  const menuMode = menuIsCollapsed === "false" || isNull(menuIsCollapsed) ? true : false;
  const [leftMenuIsVisible, setLeftMenuIsVisible] = useState<boolean>(menuMode);
  const activeKey = last(window.location.pathname.split("/"));
  const activeKeyForIcon = window.location.pathname.split("/")[1];
  const activeKeyForOperationsIcon = window.location.pathname.split("/")[2];
  const onChangeLeftMenuMode = () => {
    setLeftMenuIsVisible(!leftMenuIsVisible);
    localStorage.setItem("menuIsCollapsed", `${leftMenuIsVisible}`);
  };
  const nav = useNavigate();
  const onOpenLeftMenu = () => setLeftMenuIsVisible(true);
  return (
    <div className={styles.leftMenu} style={{ flex: leftMenuIsVisible ? "1" : "0.1" }}>
      <ul
        className={classNames(styles.list, "left-menu-list")}
        style={{ paddingTop: leftMenuIsVisible ? 2 : 0 }}
      >
        {map(getMenuItems(permissions), (item) => (
          <React.Fragment key={item.key}>
            <RenderCalloutWrapper key={item.key} item={item} leftMenuIsVisible={leftMenuIsVisible}>
              <li
                className={classNames(
                  styles.item,
                  { [styles.itemIsActive]: activeKey === item.key },
                  { [styles.itemIsDisabled]: item.disabled },
                  { [styles.itemActiveIcon]: !leftMenuIsVisible && activeKeyForIcon === item.key },
                )}
                style={{
                  paddingLeft: leftMenuIsVisible ? 0 : 17,
                  minHeight: leftMenuIsVisible ? "auto" : 24,
                }}
              >
                {!leftMenuIsVisible && (
                  <RenderIconWrapper item={item}>
                    <FontIcon
                      iconName={item.iconName}
                      className={classNames(styles.pointIcon, styles.mobileIcon)}
                    />
                  </RenderIconWrapper>
                )}
                <div
                  className={classNames(styles.listPoint, { current: item.links?.length })}
                  style={{
                    display: leftMenuIsVisible ? "block" : "none",
                    paddingLeft: !item.links?.length ? 10 : 0,
                  }}
                >
                  {!item.links?.length && item.url ? (
                    <Link className={styles.listPointLink} to={item.url}>
                      <FontIcon
                        className={styles.listPointLinkIcon}
                        iconName={item.iconName}
                        onClick={onOpenLeftMenu}
                      />
                      {item.name}
                    </Link>
                  ) : (
                    <RenderLinkList
                      item={item}
                      icon={<FontIcon iconName={item.iconName} onClick={onOpenLeftMenu} />}
                    />
                  )}
                </div>
              </li>
            </RenderCalloutWrapper>
            {!leftMenuIsVisible && item.key === "operations" && (
              <div className={styles.operationsIconsWrapper}>
                {map(item.links, link => (
                  <TooltipHost
                    key={link.iconName}
                    content={link.name}
                    hostClassName={
                      classNames(
                        styles.operationsIcon,
                        { [styles.operationsIconActive]: !leftMenuIsVisible && activeKeyForOperationsIcon === link.key }
                      )
                    }
                  >
                    <FontIcon key={link.iconName} iconName={link.iconName} onClick={() => link.url && navigate(link.url)} />
                  </TooltipHost>
                ))}
              </div>
            )}
          </React.Fragment>
        ))}
      </ul>
      <div
        className={styles.settings}
        style={{ flexDirection: leftMenuIsVisible ? "row" : "column" }}
      >
        <CommandBarButton
          text={leftMenuIsVisible ? loc.menu.startPage : ""}
          onClick={() => nav(ROOT_PATH)}
          className={styles.homeIcon}
          iconProps={{ imageProps: { src: `${appLogo}`, maximizeFrame: true } }}
        />
        <span
          className={styles.modeBtn}
          onClick={onChangeLeftMenuMode}
          style={{ marginTop: leftMenuIsVisible ? 0 : 10 }}
        >
          <FontIcon
            className={styles.modeBtnIcon}
            iconName={leftMenuIsVisible ? "DoubleChevronLeft12" : "DoubleChevronRight12"}
            style={{
              marginRight: leftMenuIsVisible ? 16 : 7,
              marginBottom: leftMenuIsVisible ? 0 : 5,
            }}
          />
        </span>
      </div>
    </div>
  );
};
