import { Icon, SelectionMode, Stack, Text } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { find } from "lodash-es";
import invoiceStyles from "components/pages/InvoicesPage/styles.module.scss";
import { Loading } from "components/common/LoadingWrapper";
import { LanguageContext } from "context/languageContext";
import { ILegend, Legend } from "components/common/Legend";
import { GREEN_COLOR, RED_COLOR, SKY_BLUE_COLOR, YELLOW_COLOR } from "constants/colors";
import { SearchDeviceBrowseDto, SearchDeviceDocumentBrowseDto } from "generated-sources/openapi";
import { ScrollableTableWrapper, Table } from "components/common/Table";
import { useApi, useHttp, useSelection } from "hooks";
import { onRenderRowWithMarkers } from "utils/onRenderRowWithMarkers";
import { companyOptions } from "constants/options";
import { AuthContext } from "context/authContext";
import classNames from "classnames";
import registerIcon from "images/register-icon.svg";
import { getCompaniesColumns, getMarkerLegend, getWarehousesColumns } from "./config";
import styles from "../../../SearchBySerial/styles.module.scss";

interface ISearchBySerialTabProps {
  warehouses: SearchDeviceBrowseDto[];
  openDeviceModal: (deviceId: number, firmId: number) => void;
};

export const SearchBySerialTab = ({ warehouses, openDeviceModal }: ISearchBySerialTabProps) => {
  const { searchApi } = useApi();
  const companiesHttp = useHttp<SearchDeviceDocumentBrowseDto[]>();

  const { company } = useContext(AuthContext);
  const { loc, language } = useContext(LanguageContext);

  const [companies, setCompanies] = useState<SearchDeviceDocumentBrowseDto[]>([]);
  const { selection, selectedItem } = useSelection<SearchDeviceBrowseDto>();

  const firmId = find(companyOptions, { text: company })?.key || "";

  const warehousesColumns = getWarehousesColumns(language, openDeviceModal);
  const companiesColumns = getCompaniesColumns();

  const warehousesLegend: ILegend[] = [{
    color: YELLOW_COLOR,
    text: loc.searchBySerial.externalSc
  }];

  const companiesLegend: ILegend[] = [{
    color: GREEN_COLOR,
    text: loc.searchBySerial.rma
  }, {
    color: SKY_BLUE_COLOR,
    text: loc.searchBySerial.sentToDistributor
  }, {
    color: RED_COLOR,
    text: loc.searchBySerial.returnFromCustomer
  }];

  const loadCompanies = () => {
    if (selectedItem) {
      companiesHttp.request(() => searchApi.apiOpSearchBrowseDeviceIdDeviceFirmIdGet({
        deviceFirmId: selectedItem?.firmId || 0,
        deviceId: selectedItem?.deviceId || 0
      })).then(res => {
        setCompanies(res.data);
      });
    } else {
      setCompanies([]);
    }
  };

  useEffect(() => {
    loadCompanies();
  }, [selectedItem]);

  return (
    <>
      <div className={styles.tableSection}>
        <Stack horizontal horizontalAlign="space-between">
          <b>{loc.searchBySerial.warehouses}</b>
          <Legend
            legends={[{ type: "point", items: warehousesLegend }]}
          />
        </Stack>
        <div className={classNames(styles.tableWrapper, styles.tableWrapperMedium)}>
          <ScrollableTableWrapper noData={!warehouses.length}>
            <Table
              columns={warehousesColumns}
              items={warehouses}
              selectionMode={SelectionMode.single}
              selection={selection}
              onRenderRow={onRenderRowWithMarkers(getMarkerLegend(firmId))}
            />
          </ScrollableTableWrapper>
        </div>
      </div>
      {selectedItem && (
        <div className={styles.tableSection}>
          <Stack horizontal horizontalAlign="space-between">
            <b>{loc.searchBySerial.docs}</b>
            <Legend
              legends={[{ type: "point", items: companiesLegend }]}
              onLegendRender={(defaultRender) => (
                <div className={invoiceStyles.legend}>
                  <div className={styles.dotsLegendWrapper}>
                    {defaultRender()}
                  </div>
                  <div className={invoiceStyles.legendItem}>
                    <Icon
                      className={classNames(invoiceStyles.docStatusIcon, invoiceStyles.docStatusIconPlus)}
                      iconName="CirclePlus"
                    />
                    <Text>{loc.invoicesPage.incomingInvoices}</Text>
                  </div>
                  <div className={invoiceStyles.legendItem}>
                    <Icon
                      className={classNames(invoiceStyles.docStatusIcon, invoiceStyles.docStatusIconMinus)}
                      iconName="SkypeCircleMinus"
                    />
                    <Text>{loc.invoicesPage.outgoingInvoices}</Text>
                  </div>
                  <div className={invoiceStyles.legendItem}>
                    <img src={registerIcon} width={20} />
                    <Text>{loc.invoicesPage.rma}</Text>
                  </div>
                </div>
              )}
            />
          </Stack>
          <div className={styles.tableWrapper}>
            <ScrollableTableWrapper noData={!companies.length}>
              <Loading isLoading={companiesHttp.isLoading}>
                <Table
                  columns={companiesColumns}
                  items={companiesHttp.errorMessages ? [] : companies}
                  selectionMode={SelectionMode.none}
                />
              </Loading>
            </ScrollableTableWrapper>
          </div>
        </div>
      )}

    </>
  );
};