import { useContext, useState, useEffect, useMemo } from "react";
import { find, map } from "lodash-es";
import classNames from "classnames";
import { SelectionMode } from "@fluentui/react";
import { AxiosResponse } from "axios";
import { useApi, useDebounce, useHttp, useMultiSelection } from "hooks";
import { LanguageContext } from "context/languageContext";
import { AuthContext } from "context/authContext";
import { companyOptions } from "constants/options";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { CodeCeInput } from "components/common/CodeCeInput";
import { ScrollableTableWrapper, Table } from "components/common/Table";
import { CommandWrapper } from "components/common/CommandWrapper";
import { RepairAreaSparePartsDto, ReplacementCEDetailsBrowseDto } from "generated-sources/openapi";
import { DeviceDetailsModal } from "./DeviceDetailsModal";
import { getColumns } from "./config";
import styles from "../AddDefectModal/styles.module.scss";

export interface IAddReplacedSeDeviceModalProps {
  basicPayload: { firmId: number; deviceId: number };
  updateState: () => void;
  onDismiss: () => void;
  workshopNumber?: string | null;
  selectedrepairArea?: string;
}


export const AddReplacedSeDeviceModal = (props: IAddReplacedSeDeviceModalProps) => {
  const { devicesApi, sparePartsApi } = useApi();
  const devicesHttp = useHttp({showErrors: true});
  const sparePartsHttp = useHttp({showErrors: true});
  const { company } = useContext(AuthContext);
  const { loc, language } = useContext(LanguageContext);
  const [devices, setDevices] = useState<RepairAreaSparePartsDto[]>([]);
  const { selection, selectedItems } = useMultiSelection<RepairAreaSparePartsDto>(devices, "seK");
  const [detailsModal, setDetailsModal] = useState<boolean>(false);
  const [searchCodeSeValue, setSearchCodeSeValue] = useState<string>("");
  const debouncedSearchCodeSeValue = useDebounce(searchCodeSeValue, 1000);
  const userFirmId = Number(find(companyOptions, { text: company })?.key);

  const modifyData = (data: RepairAreaSparePartsDto[]): RepairAreaSparePartsDto[] => (
    map(data, item => ({ ...item, quantity: 1 }))
  );

  const onChange = (item: RepairAreaSparePartsDto) => {
    setDevices(devices => map(devices, device => device.seK === item.seK ? item : device));
  };


  const loadData = () => {
    sparePartsHttp.request(() => sparePartsApi.apiOpSparePartsBrowseGet({
      repairArea: props.selectedrepairArea || `${props.basicPayload.firmId}06`
    })).then((res: AxiosResponse) => setDevices(modifyData(res.data)));
  };

  const request = (items: RepairAreaSparePartsDto[]) => devicesApi.apiOpDevicesDeviceDeviceIdFirmDeviceFirmIdReplacedCePost({
    deviceId: props.basicPayload.deviceId,
    deviceFirmId: props.basicPayload.firmId,
    deviceAddReplacedCEPayload: {
      devices: map(items, item => ({
        seK: item?.seK,
        seKParent: null,
        kol: item?.quantity,
      })),
      skl: props.workshopNumber || null,
      firmId: userFirmId,
    }
  });

  const submitHandler = (items: ReplacementCEDetailsBrowseDto[]) => {
    devicesHttp.request(() => request(items))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          props.updateState();
          props.onDismiss();
        };
      });
  };

  useEffect(() => {
    loadData();
  }, [debouncedSearchCodeSeValue]);

  const columns = useMemo(() => getColumns(language, onChange), [language]);

  return (
    <BasicModal
      isDraggable
      additionalClassName={classNames(styles.addDefectModal, styles.noScrollModal)}
      isLoading={devicesHttp.isLoading || sparePartsHttp.isLoading}
      title={loc.labels.selectDevices}
      errors={devicesHttp.errorMessages || sparePartsHttp.errorMessages}
      submitButtonText={loc.buttons.save}
      dismissButtonText={loc.buttons.cancel}
      onSubmit={() => submitHandler(selectedItems)}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <InfoContainer>
          <CodeCeInput
            className="code-field"
            value={searchCodeSeValue}
            onChange={setSearchCodeSeValue}
            placeholder={loc.columns.se}
            reset
          />
        </InfoContainer>
        <div className="scrollable-table-wrapper">
          <ScrollableTableWrapper noData={!devices.length}>
            <Table
              items={devices}
              columns={columns}
              selection={selection}
              selectionMode={SelectionMode.multiple}
            />
          </ScrollableTableWrapper>
        </div>
        <CommandWrapper isActive={detailsModal}>
          <DeviceDetailsModal
            se={selectedItems[0]?.seK || ""}
            request={request}
            updateState={() => {
              props.updateState();
              props.onDismiss();
            }}
            onDismiss={() => setDetailsModal(false)}
          />
        </CommandWrapper>
      </BasicModalContent>
    </BasicModal>
  );
};
