import { filter, find, isNull, isUndefined, map, reduce, uniqBy } from "lodash-es";
import { GREEN_COLOR, RED_COLOR, WHITE_COLOR, YELLOW_COLOR } from "constants/colors";
import { RmaDeviceBrowseDto, RmaBrowseDto } from "generated-sources/openapi";
import loc from "localization";
import { getDifferenceInDays, transformDateToString } from "utils";

export const getPermissionPanelTitle = (item?: RmaBrowseDto) => {
  return `
    ${loc.permissionsRMAPage.rmaTitle} 
    ${item?.rmaNumber ? `№ ${item?.rmaNumber}` : ""}
    ${loc.main.by}
    ${transformDateToString(item?.rmaDate)}
    ${item?.counterpartyName}
  `;
};

export const getDevicesPanelTitle = (selectedItem?: RmaBrowseDto) => {
  return `${selectedItem?.counterpartyName} ${selectedItem?.rmaNumber}`;
};

export const defineMarkerColor = (id?: number | null) => {
  switch (id) {
  case 1:
    return GREEN_COLOR;
  case 2:
    return YELLOW_COLOR;
  case 3:
    return RED_COLOR;
  default:
    return WHITE_COLOR;
  };
};

export const getDevicesWithTotal = (devices: RmaDeviceBrowseDto[]): RmaDeviceBrowseDto[] => {
  return [...devices, {
    nameRu: "Итого",
    nameEn: "Total",
    stoimRem: Number(devices.reduce((sum, el) => (el.stoimRem || 0) + sum, 0).toFixed(2)),
    obozn: devices.find(el => el.obozn && el.stoimRem)?.obozn
  }];
};

export const getModifiedDevices = (data: RmaDeviceBrowseDto[]) => {
  const modifiedDevices = map(uniqBy(data, device => [device.sek, device.cena].join()), item => {
    const count = filter(data, ({ sek, cena }) => item.sek === sek && cena === item.cena).length;
    const matchedItem = find(data, (i:RmaDeviceBrowseDto) => i.sek === item.sek && (isNull(i.idStr) && item.prLic)) as RmaDeviceBrowseDto;
    return ({
      ...item,
      count,
      sum: count * Number(item.cena),
      cena: item.cena?.toFixed(2),
      idStr: !isUndefined(matchedItem) && isNull(matchedItem.idStr) ? matchedItem.idStr : item.idStr,
      prLic: !isUndefined(matchedItem) && !matchedItem.prLic ? matchedItem.prLic : item.prLic,
    });
  });
  return [...modifiedDevices, {
    nameRu: "Итого",
    nameEn: "Total",
    count: reduce(modifiedDevices, (prev, next) => prev + Number(next.count), 0),
    cena: undefined,
    sum: reduce(modifiedDevices, (prev, next) => prev + Number(next.sum), 0),
  }];
};

export const isDisabledCheckLicensesButton = (counterpartyId: number | undefined) => {
  switch(counterpartyId) {
  case 4:
    return false;
  case 7: 
    return false;
  case 8: 
    return false;
  default: 
    return true;
  }; 
};

export const getMessage = (result: string) => {
  switch(result) {
  case "NoLicensedDevices": 
    return loc.notifications.noLicensedDevices;
  case "Success": 
    return loc.notifications.success;
  case "Unknown": 
    return loc.notifications.unknown;
  case "NotEnoughLicensesImport": 
    return loc.notifications.notEnoughLicensesImport;
  case "NotEnoughLicensesTempImport": 
    return loc.notifications.notEnoughLicensesTempImport;
  case "ExchangeRateNotFound": 
    return loc.notifications.exchangeRateNotFound;
  case "NotEnoughLicenses": 
    return loc.notifications.NotEnoughLicenses; 
  default: 
    return "";
  };
};

export const getDateColor = (date: string | null | Date | undefined) => {
  const differenceInDays = getDifferenceInDays(date);
  return { color: differenceInDays < 60 ? "red" : "" };
};