import styles from "./styles.module.scss";

interface IRequiredFieldProps {
  text: string;
}

export const RequiredField = ({ text }: IRequiredFieldProps) => (
  <>
    {text}
    <span className={styles.requiredField}>*</span>
  </>
);
