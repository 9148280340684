import { AxiosResponse } from "axios";
import { map } from "lodash-es";
import { TooltipHost } from "@fluentui/react";
import { useEffect, useMemo, useState } from "react";
import { editSuccessNotification } from "utils";
import { DevicePackagingInformationDto } from "generated-sources/openapi";
import { useApi, useHttp } from "hooks";
import loc from "localization";
import { BasicModal, BasicModalColumn, BasicModalContent } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { BasicCheckbox } from "components/common/BasicCheckbox";
import styles from "./styles.module.scss";

interface IPackingModalProps {
  onDismiss: () => void;
  basicPayload: { firmId: number; deviceId: number };
  textPackagingInfo: {
    equipments: string,
    battaries: {
      serial: string,
      name: string,
    }[];
    comment: string;
  };
  updateState: () => void;
};

export const PackingModal = (props: IPackingModalProps) => {
  const { devicesApi } = useApi();
  const devicesHttp = useHttp({ showErrors: true });
  const [packagingInfo, setPackagingInfo] = useState<DevicePackagingInformationDto | null>(null);

  const onChangeInfo = (field: string, checked: boolean) => {
    setPackagingInfo({ ...packagingInfo, [field]: checked });
    updateInfoRequest(field, checked);
  };

  const loadInfo = () => {
    devicesHttp.request(() =>
      devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingGet(props.basicPayload),
    ).then((res: AxiosResponse) => setPackagingInfo(res.data));
  };

  const updateInfoRequest = (field: string, checked: boolean) => {
    devicesHttp.request(() =>
      devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingPut({
        ...props.basicPayload,
        packagingInformationUpdatePayload: {
          ...packagingInfo,
          [field]: checked
        }
      }),
    ).then((res: AxiosResponse) => editSuccessNotification(res, loc.notifications.editNotification));
  };

  const confirmPacking = () => {
    devicesHttp.request(() =>
      devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdPackagingConfirmPost(props.basicPayload),
    ).then((res: AxiosResponse) => {
      props.updateState();
      editSuccessNotification(res, loc.notifications.packedNotification);
    });
  };

  useEffect(() => loadInfo(), []);

  const isDisabledSubmitButton = useMemo(() => Boolean(
    !packagingInfo?.checkBatteries || !packagingInfo?.checkComments || !packagingInfo?.checkEquipment
  ), [packagingInfo?.checkBatteries, packagingInfo?.checkComments, packagingInfo?.checkEquipment]);

  return (
    <BasicModal
      isLoading={devicesHttp.isLoading}
      title={loc.buttons.package}
      errors={devicesHttp.errorMessages}
      dismissButtonText={loc.buttons.close}
      onDismiss={props.onDismiss}
      additionalClassName={styles.packageModal}
      submitButtonText={loc.buttons.packed}
      disabledSubmitButton={isDisabledSubmitButton}
      onSubmit={confirmPacking}
    >

      <BasicModalContent>
        <BasicModalColumn>
          <div className={styles.infoWrapper}>
            <InfoContainer label={loc.columns.equipments}>
              <BasicCheckbox
                onChange={(checked) => onChangeInfo("checkEquipment", checked)}
                checked={!!packagingInfo?.checkEquipment}
              />
            </InfoContainer>
            <div className={styles.infoWrapperDescr}>
              <TooltipHost content={props.textPackagingInfo.equipments}>
                {props.textPackagingInfo.equipments || "-"}
              </TooltipHost>
            </div>
          </div>
          <div className={styles.infoWrapper}>
            <InfoContainer label={loc.labels.notesForTheClient}>
              <BasicCheckbox
                onChange={(checked) => onChangeInfo("checkComments", checked)}
                checked={!!packagingInfo?.checkComments}
              />
            </InfoContainer>
            <div className={styles.infoWrapperDescr}>
              <TooltipHost content={props.textPackagingInfo.comment}>
                {props.textPackagingInfo.comment || "-"}
              </TooltipHost>

            </div>
          </div>
          <div className={styles.infoWrapper}>
            <InfoContainer label={loc.labels.batteries}>
              <BasicCheckbox
                onChange={(checked) => onChangeInfo("checkBatteries", checked)}
                checked={!!packagingInfo?.checkBatteries}
              />
            </InfoContainer>
            <div className={styles.infoWrapperDescr}>
              <div className={styles.battery}>
                <div className={styles.serialNumber}>{loc.columns.serialN}</div>
                <div className={styles.batteryName}>{loc.columns.name}</div>
              </div>
              {props.textPackagingInfo.battaries.length ? map(props.textPackagingInfo.battaries, battery => (
                <div className={styles.battery}>
                  <div className={styles.serialNumber}>{battery.serial || "-"}</div>
                  <TooltipHost content={battery.name}>
                    <div className={styles.batteryName}>{battery.name || "-"}</div>
                  </TooltipHost>
                </div>
              )) : "-"}
            </div>
          </div>
        </BasicModalColumn>
      </BasicModalContent>
    </BasicModal>
  );
};