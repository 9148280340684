import { useContext, useState, useEffect, useMemo } from "react";
import { AxiosResponse } from "axios";
import { SelectionMode } from "@fluentui/react";
import { filter, includes, map, orderBy, reduce } from "lodash-es";
import { useApi, useHttp, useMultiSelection } from "hooks";
import { filterArrayByFields } from "utils";
import { LanguageContext } from "context/languageContext";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { AppLanguage } from "constants/enums";
import { Table } from "components/common/Table";
import { BasicInput } from "components/common/BasicInput";
import { DeviceWorkBrowseDto } from "generated-sources/openapi";
import { getColumns } from "./config";
import { getGroupedItems, modifyWorks } from "./helpers";
import styles from "../AddDefectModal/styles.module.scss";

export interface IAddWorklistModalProps {
  basicPayload: { firmId: number; deviceId: number };
  updateState: () => void;
  onDismiss: () => void;
}

export interface IDeviceWork extends DeviceWorkBrowseDto {
  code: string;
  level: number;
  isParent: boolean;
  isCollapsed: boolean;
};

export const AddWorklistModal = (props: IAddWorklistModalProps) => {
  const { devicesApi } = useApi();
  const devicesHttp = useHttp();
  const worksHttp = useHttp<DeviceWorkBrowseDto[]>();
  const { loc, language } = useContext(LanguageContext);
  const [works, setWorks] = useState<IDeviceWork[]>([]);
  const { selection, selectedItems } = useMultiSelection<IDeviceWork>(works, undefined, true);
  const [codeValue, setCodeValue] = useState<string>("");
  const [nameValue, setNameValue] = useState<string>("");

  const submitHandler = () => {
    const selectedItemsIds = map(selectedItems, item => item.workId);
    const workIds = map(
      filter(works, work => includes(selectedItemsIds, work.workId) && !work.isParent),
      item => Number(item.workId)
    );
    devicesHttp.request(() =>
      devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdWorksPost({
        ...props.basicPayload,
        deviceAddWorksPayload: { workIds },
      }),
    )
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          props.updateState();
          props.onDismiss();
        };
      });
  };

  const collapseWork = (workId?: number) => {
    setWorks((works) =>
      map(works, (work) =>
        work.workId === workId ? { ...work, isCollapsed: !work.isCollapsed } : work,
      ),
    );
  };

  const loadWorks = () => {
    worksHttp
      .request(() => devicesApi.apiOpDevicesWorksListGet())
      .then((res) => setWorks(modifyWorks(res.data)));
  };

  useEffect(() => {
    loadWorks();
  }, []);

  const columns = useMemo(() => getColumns(language, collapseWork), [language]);
  const items = useMemo(() => {
    const groupedItems = getGroupedItems(works);
    const lang = language === AppLanguage.Russian ? "Ru" : "En";
    if (nameValue || codeValue) {
      const worklistCodes = map(
        filterArrayByFields(works, [
          { value: codeValue, fieldValues: ["code"] },
          { value: nameValue, fieldValues: [`name${lang}`, "notes"] },
        ]),
        (item: IDeviceWork) => `${item.code}`,
      );
      const allWorklistCodes = reduce(worklistCodes, (prev: string[], next: string) => {
        const newArray: string[] = [];
        const numbers = next?.split(".");
        for (let i = 0; i < numbers.length; i++) {
          newArray.push(numbers.slice(0, i + 1).join("."));
        };
        return [...prev, ...newArray];
      }, []);
      return orderBy(filter(works, defect => includes(allWorklistCodes, defect.code)), "u1");
    }
    return groupedItems;
  }, [works, codeValue, nameValue, language]);

  return (
    <BasicModal
      isDraggable
      additionalClassName={styles.addDefectModal}
      isLoading={devicesHttp.isLoading || worksHttp.isLoading}
      title={loc.deviceModal.selectTypicalWorksTitle}
      errors={devicesHttp.errorMessages}
      submitButtonText={loc.buttons.save}
      dismissButtonText={loc.buttons.cancel}
      onSubmit={submitHandler}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <InfoContainer>
          <div className="flex-row">
            <BasicInput
              className="code-field"
              value={codeValue}
              onChange={setCodeValue}
              placeholder={loc.columns.code}
              reset
              styles={{ marginRight: 20 }}
            />
            <BasicInput
              className="name-field"
              value={nameValue}
              onChange={setNameValue}
              placeholder={loc.main.searchPlaceholder}
              reset
            />
          </div>
        </InfoContainer>
        <div className="scrollable-table-wrapper">
          <Table
            items={items}
            columns={columns}
            selection={selection}
            selectionMode={SelectionMode.multiple}
          />
        </div>
      </BasicModalContent>
    </BasicModal>
  );
};
