import { IDetailsRowProps, IRenderFunction, PrimaryButton } from "@fluentui/react";
import { isNull } from "lodash-es";
import { AppLanguage, CommandbarCommands, RmaStatus } from "constants/enums";
import { BLUE_COLOR, DARK_GREEN_COLOR, GREEN_COLOR, LIGHT_GREY_COLOR, PINK_COLOR, PURPLE_COLOR, RED_COLOR } from "constants/colors";
import { Marker } from "components/common/Marker";
import loc from "localization";
import { getModifiedNumber, transformDateToString } from "utils";
import { IRmaDevice } from ".";
import { defineMarker } from "../../RequestsRMAPage/RMARequestPanel/helpers";
import { ICommandBarAction } from "components/common/CommandBar";
import { BasicCheckbox } from "components/common/BasicCheckbox";
import { NumberField } from "components/common/NumberField";
import { BarcodeInput } from "components/common/BarcodeInput";
import basicPanelStyles from "components/common/BasicPanel/styles.module.scss";
import classNames from "classnames";
import { RmaBrowseDto, RmaDeviceBrowseDto } from "generated-sources/openapi";
import { getDateColor } from "./helpers";
import { getLockColumnItem } from "components/common/LockColumnItem";
import styles from "./styles.module.scss";

export const getPermissionActions = (
  updatePrices: () => void,
  haveInvalidPrices: boolean,
  haveExpiringLicenses: boolean,
  specPermission: boolean,
  selectedItem?: RmaBrowseDto,
): ICommandBarAction[] => [
  // {
  //   key: "confirm",
  //   text: loc.buttons.confirm,
  //   command: CommandbarCommands.Confirm,
  //   disabled: selectedItems.length < 1,
  //   display: true,
  // },
  // {
  //   key: "reject",
  //   text: loc.buttons.reject,
  //   command: CommandbarCommands.Cancel,
  //   disabled: selectedItems.length < 1,
  //   display: true,
  // },
  {
    key: "add",
    text: loc.buttons.add,
    command: CommandbarCommands.Add,
    disabled: !(selectedItem?.rmaStatus === RmaStatus.Prepairing && isNull(selectedItem.rmaRequestNumber)),
    display: true,
  },
  {
    key: "pricesUpload",
    text: loc.buttons.pricesUpload,
    command: CommandbarCommands.Upload,
    disabled: selectedItem?.rmaStatus !== RmaStatus.Prepairing,
    display: true,
    render: (item) => {
      return (
        <PrimaryButton className={classNames(basicPanelStyles.basicPanelActionsBtn, {
          [basicPanelStyles.basicPanelActionsBtnDisabled]: item.disabled
        })} onClick={updatePrices}>
          {item.text}
        </PrimaryButton>
      );
    }
  },
  {
    key: "checkLicenses",
    text: loc.buttons.checkLicenses,
    command: CommandbarCommands.Check,
    /*disabled: selectedItem?.rmaStatus === RmaStatus.Prepairing && items?.length
      ? isDisabledCheckLicensesButton(userCompanyId) 
      : true,*/
    disabled: false,
    display: true,
  },
  {
    key: "approve",
    text: loc.buttons.approve,
    command: haveExpiringLicenses ? CommandbarCommands.ConfirmCheck : CommandbarCommands.Approve,
    disabled: selectedItem?.rmaStatus !== RmaStatus.Prepairing || haveInvalidPrices,
    display: true,
  },
  {
    key: "cancelApprove",
    text: loc.buttons.cancelApproval,
    command:  CommandbarCommands.CancelApproval,
    disabled: !specPermission,
    display: selectedItem?.rmaStatus === RmaStatus.Open,
  },
];

export const getDevicesActions = (
  selectedItems: RmaDeviceBrowseDto[], 
  barcode: string,
  setBarcode: (val: string) => void,
  setSerialsFromBarcodes: (val: string[]) => void,
  serialsFromBarcodes: string[],
  addDeviceByBarcode: () => void,
  selectedItem?: RmaBrowseDto,
) : ICommandBarAction[] => [
  {
    key: "confirm",
    text: loc.buttons.confirm,
    command: CommandbarCommands.ConfirmRma,
    disabled: !selectedItems.length || selectedItem?.rmaStatus !== RmaStatus.Open,
    display: true,
    threeDots: true,
  },
  {
    key: "reject",
    text: loc.buttons.reject,
    command: CommandbarCommands.Cancel,
    disabled: !selectedItems.length,
    display: true,
    threeDots: true,
  },
  {
    key: "delete",
    text: loc.buttons.delete,
    command: CommandbarCommands.Delete,
    disabled: !(selectedItems.length === 1 && selectedItem?.rmaStatus === RmaStatus.Prepairing),
    display: true,
    threeDots: true,
  },
  {
    key: "add",
    text: "",
    command: CommandbarCommands.Add,
    disabled: !(selectedItem?.rmaStatus === RmaStatus.Prepairing && isNull(selectedItem.rmaRequestNumber)),
    display: true,
    render: () => {
      return (
        <span className={styles.barcodeInputWrapper}>
          <BarcodeInput
            disabled={!(selectedItem?.rmaStatus === RmaStatus.Prepairing && isNull(selectedItem.rmaRequestNumber))}
            value={barcode}
            onChange={setBarcode}
            onEnterPressed={() => {
              addDeviceByBarcode();
            }}
            onReset={() => {
              setSerialsFromBarcodes([]);
            }}
            active={!!serialsFromBarcodes?.length}
          />
        </span>
      );
    }
  },
];

export const getPermissionColumns = (
  language: AppLanguage,
  onChange: (item: IRmaDevice) => void,
  onBlur: (item: IRmaDevice) => void,
) => [
  {
    key: "nLic",
    name: loc.columns.licenseN,
    fieldName: "nLic",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    onRender: (item: IRmaDevice) => {
      const marker = defineMarker(item.prRem);
      if (item.nameEn === "Total") {
        return null;
      }
      return (
        <>
          <Marker text={marker?.text} color={marker?.color} isStripes />
          <Marker
            text={loc.tooltips.licenseIsRequiredAndMatched}
            display={Boolean(item.prLic && !isNull(item.idStr))}
            color={DARK_GREEN_COLOR}
            position={1}
          />
          <Marker
            text={loc.tooltips.licenseIsRequiredAndNotMatched}
            display={Boolean(item.prLic && isNull(item.idStr))}
            color={BLUE_COLOR}
            position={1}
          />
          <Marker
            text={loc.tooltips.emptyCodeSe}
            display={Boolean(isNull(item.sek))}
            color={PINK_COLOR}
            position={1}
          />
          <Marker
            text={loc.tooltips.licenseIsNotRequired}
            display={Boolean(!item.prLic)}
            color={LIGHT_GREY_COLOR}
            position={1}
          />
          {item.nLic}
        </>
      );
    },
  },
  {
    key: "dataOkon",
    name: loc.columns.validTo,
    fieldName: "dataOkon",
    minWidth: 90,
    maxWidth: 90,
    isResizable: true,
    onRender: (item: IRmaDevice) => (
      <span style={getDateColor(item.dataOkon)}>
        {transformDateToString(item.dataOkon)}
      </span> 
    ),
  },
  {
    key: "idStr",
    name: loc.columns.pageN,
    fieldName: "idStr",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
  {
    key: "sku",
    name: loc.columns.sku,
    fieldName: "sku",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
  {
    key: "sek",
    name: loc.columns.se,
    fieldName: "sek",
    minWidth: 60,
    maxWidth: 60,
    isResizable: true,
  },
  {
    key: "name",
    name: language === AppLanguage.Russian ? loc.columns.nameRu : loc.columns.nameEn,
    fieldName: language === AppLanguage.Russian ? "nameRu" : "nameEn",
    minWidth: 250,
    maxWidth: 250,
    isResizable: true,
  },
  {
    key: "count",
    name: loc.columns.qty,
    fieldName: "count",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "cena",
    name: loc.columns.price,
    fieldName: "cena",
    minWidth: 90,
    maxWidth: 90,
    isResizable: true,
    onRender: (item: IRmaDevice) => item.nameEn === "Total" ? null : (
      <NumberField
        item={item}
        className={classNames({ [styles.redInput]: item.isValidLicensePrice === false })}
        name="cena"
        value={`${item.cena}`}
        onChange={(item: IRmaDevice, field: string, value?: string) => onChange({ ...item, [field]: value })}
        onBlur={onBlur}
        fract={2}
      />
    ),
  },
  {
    key: "sum",
    name: loc.columns.amount,
    fieldName: "sum",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
    headerClassName: styles.amount,
    onRender: (item: IRmaDevice) => (
      <div className={styles.amount}>{getModifiedNumber(item.sum, 2)}</div>
    ),
  },
];

export const getDevicesColumns = (language: AppLanguage) => [
  {
    key: "three-dots",
    name: "",
    minWidth: 15,
    maxWidth: 15,
    isResizable: true,
    onRender: (item: RmaDeviceBrowseDto) => {
      if (item.nameEn === "Total") {
        return null;
      }
      if(item.isDistributorNotBuyer) {
        return (
          <Marker text={loc.tooltips.distributorNotBuyer} color={PURPLE_COLOR} position={1}/>
        );
      };
      if(!isNull(item.chk)) {
        if(item.chk) {
          return (
            <Marker text={loc.tooltips.confirmed} color={GREEN_COLOR} />
          );
        } else {
          return (
            <Marker text={loc.tooltips.rejected} color={RED_COLOR} />
          );
        }
      }
    }
  },
  getLockColumnItem(),
  {
    key: "sku",
    name: loc.columns.sku,
    fieldName: "sku",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
  {
    key: "name",
    name: language === AppLanguage.Russian ? loc.columns.nameRu : loc.columns.nameEn,
    fieldName: language === AppLanguage.Russian ? "nameRu" : "nameEn",
    minWidth: 250,
    maxWidth: 250,
    isResizable: true,
  },
  {
    key: "nomer",
    name: loc.columns.serialN,
    fieldName: "nomer",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
  },
  {
    key: "stoimRem",
    name: loc.columns.costOfRepair,
    fieldName: "stoimRem",
    minWidth: 120,
    maxWidth: 120,
    isResizable: true,
    onRender: (item: RmaDeviceBrowseDto) => item.stoimRem ? <b>{getModifiedNumber(item.stoimRem, 2)}</b> : null,
  },
  {
    key: "obozn",
    name: loc.columns.currency,
    fieldName: "obozn",
    minWidth: 60,
    maxWidth: 60,
    isResizable: true,
    onRender: (item: RmaDeviceBrowseDto) => item.stoimRem ? item.obozn : null,
  },
  {
    key: "dataProiz",
    name: loc.columns.productionDate,
    fieldName: "dataProiz",
    minWidth: 80,
    maxWidth: 80,
    isResizable: true,
    onRender: (item: RmaDeviceBrowseDto) => transformDateToString(item.dataProiz),
  },
  {
    key: "dataProd",
    name: loc.columns.saleDate,
    fieldName: "dataProd",
    minWidth: 80,
    maxWidth: 80,
    isResizable: true,
    onRender: (item: RmaDeviceBrowseDto) => transformDateToString(item.dataProd),
  },
  {
    key: "dataReceiptForRepair",
    name: loc.columns.handOverDate,
    fieldName: "dataReceiptForRepair",
    minWidth: 80,
    maxWidth: 80,
    isResizable: true,
    onRender: (item: RmaDeviceBrowseDto) => transformDateToString(item.dataReceiptForRepair),
  },
  {
    key: "warranty",
    name: loc.columns.warranty,
    fieldName: "warranty",
    minWidth: 70,
    maxWidth: 70,
    onRender: (item: RmaDeviceBrowseDto) => {
      if (item.nameEn === "Total") {
        return null;
      }
      return <BasicCheckbox disabled checked={item.warranty} />;
    },
  },
  {
    key: "wait",
    name: loc.columns.r2o,
    fieldName: "wait",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
    onRender: (item: RmaDeviceBrowseDto) => {
      if (item.nameEn === "Total") {
        return null;
      }
      return <BasicCheckbox disabled checked={item.wait} />;
    },
  },
  {
    key: "complaints",
    name: loc.columns.complaints,
    fieldName: "complaints",
    minWidth: 120,
    maxWidth: 120,
    isResizable: true,
  },
];

export const onRenderRow = (props?: IDetailsRowProps, render?: IRenderFunction<IDetailsRowProps>) => (
  <>
    {props && render && render({
      ...props,
      styles: { root: { fontWeight: props.item?.nameEn === "Total" ? "bold" : "normal" } }
    })}
  </>
);
