import { AxiosResponse } from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { errorNotification } from "utils";
import { useEventListener } from "./useEventListener";

export interface IPagingResult<T> {
  data: T[];
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  hasNextPage: boolean;
}

export const useInfinityScroll = <T>(
  fetchData: (pageIndex: number, pageSize: number) => Promise<AxiosResponse>,
  setData: Dispatch<SetStateAction<T[]>>,
  modifyData?: (array: T[]) => void,
) => {
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleScroll = (e: any) => {
    const isBottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 15 && e.target.scrollTop > 0;
    if (isBottom && hasNextPage) {
      setPageIndex(pageIndex + 1);
    };
  };

  const loadData = (loadMore?: boolean) => {
    if (!loadMore) {
      setData([]);
      setPageIndex(0);
      setHasNextPage(false);
      setIsLoading(true);
    };
    fetchData(loadMore ? pageIndex : 0, 50)
      .then((res) => {
        const data = modifyData ? modifyData(res.data.data) : res.data.data;
        if (loadMore) {
          setData(prevState => [...prevState, ...data]);
        } else {
          setData(data);
          setPageIndex(0);
          setIsLoading(false);
        }
        setTotalCount(res.data.totalCount);
        setHasNextPage(res.data.hasNextPage);
      })
      .catch(errorNotification)
      .finally(() => setLoadingMore(false));
  };

  useEffect(() => {
    if (pageIndex !== 0) {
      setLoadingMore(true);
      setHasNextPage(false);
      loadData(true);
    };
  }, [pageIndex]);

  useEventListener("scroll", handleScroll);

  return {
    totalCount,
    loadingMore,
    isLoading,
    loadData,
  };
};
