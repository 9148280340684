import { useState, useEffect } from "react";

export function useDebounce(value: string, delay?: number) {
  const del = delay || 1000;
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, del);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);
  return value.length ? debouncedValue : value;
}
