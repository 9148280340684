import { orderBy, isNumber, filter, map, find, includes, isBoolean } from "lodash-es";
import { ISortingState } from "hooks/useSorting";
import { Sorting } from "constants/enums";
import { companyOptions } from "constants/options";
import { OrderByDirection } from "generated-sources/openapi";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const orderArrayBySorting = <T>(items: T[], sortingState: ISortingState, itemTransform?: (item: any) => any): T[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return orderBy(items, [(item: any) => {
    const field = itemTransform ? itemTransform(item)[sortingState.field] : item[sortingState.field];
    return (isNumber(field) || isBoolean(field)) ? field : field?.toLowerCase();
  }], [sortingState.type]);
};

export const getOrderByDirection = (fieldName: string, sortingState: ISortingState) => {
  if (sortingState.field === fieldName) {
    return sortingState.type === Sorting.ASC
      ? OrderByDirection.Ascending
      : OrderByDirection.Descending;
  } else {
    return OrderByDirection.Ignore;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOrderCompaniesByDefaultKeys = (items: any) => {
  const defaultCompanies = filter(map(companyOptions, option => find(items, { counterpartyId: Number(option.key) })), item => item);
  const restCompanies = filter(items, item => !includes(map(companyOptions, option => Number(option.key)), item.counterpartyId));
  return [...defaultCompanies, ...restCompanies];
};
