import { SelectionMode } from "@fluentui/react";
import { useMemo } from "react";
import { ScrollableTableWrapper, Table } from "components/common/Table";
import { useSorting } from "hooks";
import { Sorting } from "constants/enums";
import { orderArrayBySorting } from "utils";
import { DeviceAuditBrowseDto, UserShortProfileDto } from "generated-sources/openapi";
import { getColumns } from "./config";

interface IAuditTabProps {
  auditEvents: DeviceAuditBrowseDto[];
  usersList: UserShortProfileDto[];
};


export const AuditTab = ({ auditEvents, usersList }: IAuditTabProps) => {
  const sorting = useSorting({
    field: "createdAt",
    type: Sorting.DESC,
  });

  const sortedItems = useMemo(
    () => orderArrayBySorting(auditEvents, sorting.state),
    [auditEvents.length, sorting.state.field, sorting.state.type]
  );

  const columns = useMemo(() => getColumns(usersList, sorting), [auditEvents.length]);

  return (
    <div className="scrollable-defects-wrapper">
      <ScrollableTableWrapper noData={!auditEvents.length}>
        <Table
          items={sortedItems}
          columns={columns}
          selectionMode={SelectionMode.none}
        />
      </ScrollableTableWrapper>
    </div>
  );
};