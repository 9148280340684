import { IDetailsRowProps, IRenderFunction, Stack } from "@fluentui/react";
import { ILegend } from "components/common/Legend";
import { map } from "lodash-es";
import { getMarker } from "./getMarker";

export const onRenderRowWithMarkers = function<T = unknown>(getMarkerLegend: (item: T) => ILegend[]) {
  const onRenderRow = (props?: IDetailsRowProps, render?: IRenderFunction<IDetailsRowProps>) => {
    const item = props?.item;
    return <Stack horizontal>
      <div className="sticky-markers">
        {map(getMarkerLegend(item), (legend) =>
          legend.display
            ? getMarker(
              legend.display,
              legend.text,
              legend.color,
              legend.isStripes,
              legend.position,
            )
            : null,
        )}
      </div>
      {props && render && render(props)}
    </Stack>;
  };
  return onRenderRow;
};
