import { useContext, useMemo } from "react";
import { find } from "lodash-es";
import { FontIcon, IColumn, TooltipHost } from "@fluentui/react";
import { AuthContext } from "context/authContext";
import { TodayEventsContext } from "context/todayEventsContext";
import { GREY_COLOR, PRIMARY_COLOR } from "constants/colors";
import styles from "./styles.module.scss";

interface ILockColumnItemProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
}

export const LockColumnItem = (props: ILockColumnItemProps) => {
  const { user } = useContext(AuthContext);
  const { lockedDevices } = useContext(TodayEventsContext);
  const lockedItem = useMemo(() => find(lockedDevices, {
    deviceId: props.item?.deviceId,
    firmId: props.item?.firmId,
  }), [props.item, lockedDevices]);
  return lockedItem ? (
    <TooltipHost content={lockedItem?.fio}>
      <FontIcon
        iconName="LockSolid"
        style={{ color: lockedItem?.tn === user?.workNumber ? PRIMARY_COLOR : GREY_COLOR }}
      />
    </TooltipHost>
  ) : null;
};

export const getLockColumnItem = <T,>(): IColumn => {
  return {
    key: "lock",
    name: "",
    fieldName: "lock",
    minWidth: 20,
    maxWidth: 20,
    onRenderHeader: () => <FontIcon className={styles.lockItem} iconName="LockSolid" />,
    onRender: (item: T) => <LockColumnItem item={item} />,
  };
};
