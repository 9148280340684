import loc from "localization";
import { getDaysInMonth, lastDayOfMonth, startOfYear, endOfYear } from "date-fns";
import { getDateMonthsAgo, transformDateToString } from "utils";
import { AppLanguage, Result } from "./enums";

export enum Currencies {
  EUR = 3,
  BYN = 7
};

export const allCompaniesOptions = [
  { key: "8", text: "Y", title: "Yukon", val: Currencies.EUR },
  { key: "4", text: "L", title: "Pulsar", val: Currencies.EUR },
  { key: "7", text: "P", title: "Polaris", val: Currencies.EUR },
  { key: "1", text: "B", title: "Beltex", val: Currencies.BYN },
  { key: "5", text: "M", title: "Mezon", val: Currencies.EUR },
  { key: "9", text: "O", title: "OPYU", val: Currencies.EUR },
];

export const companyOptions = [
  { key: "8", text: "Y", title: "Yukon", val: Currencies.EUR },
  { key: "4", text: "L", title: "Pulsar", val: Currencies.EUR },
  { key: "7", text: "P", title: "Polaris", val: Currencies.EUR },
  { key: "9", text: "O", title: "OPYU", val: Currencies.EUR },
  { key: "6", text: "SC", title: "External SC", val: Currencies.EUR },
];

export const getChoiseGroupLangOptions = () => [
  { key: AppLanguage.Russian, text: loc.labels.russian, disabled: false },
  { key: AppLanguage.English, text: loc.labels.english, disabled: false },
];

export const getPeriodOptions = () => [
  {
    key: "1",
    text: loc.labels.previousMonth,
    data: {
      dateFrom: transformDateToString(getDateMonthsAgo(1, 1)),
      dateTo: transformDateToString(
        getDateMonthsAgo(1, getDaysInMonth(new Date(new Date().getFullYear(), new Date().getMonth() - 1)))
      ),
    },
  },
  {
    key: "3",
    text: loc.labels.threeMonths,
    data: {
      dateFrom: transformDateToString(getDateMonthsAgo(2, 1)),
      dateTo: transformDateToString(lastDayOfMonth(new Date())),
    },
  },
  {
    key: "6",
    text: loc.labels.sixMonths,
    data: {
      dateFrom: transformDateToString(getDateMonthsAgo(5, 1)),
      dateTo: transformDateToString(lastDayOfMonth(new Date())),
    },
  },
  {
    key: "12",
    text: loc.labels.year,
    data: {
      dateFrom: transformDateToString(startOfYear(new Date())),
      dateTo: transformDateToString(endOfYear(new Date())),
    },
  },
];

export const getQuestionOptions = () => [
  { key: Result.Yes, text: loc.buttons.yes },
  { key: Result.No, text: loc.buttons.no },
];
export const getQualityOptions = () => [
  { key: Result.Yes, text: loc.labels.good },
  { key: Result.No, text: loc.labels.bad },
];

export const getMonthsOptions = () => [
  { key: 1, text: loc.months.jan },
  { key: 2, text: loc.months.feb },
  { key: 3, text: loc.months.mar },
  { key: 4, text: loc.months.apr },
  { key: 5, text: loc.months.may },
  { key: 6, text: loc.months.jun },
  { key: 7, text: loc.months.jul },
  { key: 8, text: loc.months.aug },
  { key: 9, text: loc.months.sep },
  { key: 10, text: loc.months.oct },
  { key: 11, text: loc.months.nov },
  { key: 12, text: loc.months.dec },
];