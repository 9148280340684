import { GREEN_COLOR, RED_COLOR, WHITE_COLOR, YELLOW_COLOR } from "constants/colors";
import { Companies } from "constants/enums";
import { companyOptions } from "constants/options";
import { RmaRequestBrowseDto, RmaRequestDeviceBrowseDto } from "generated-sources/openapi";
import loc from "localization";
import { filter, reduce } from "lodash-es";
import { transformDateToString } from "utils";

export const getPanelTitle = (item?: RmaRequestBrowseDto) => {
  return `
    ${loc.requestsRMAPage.rmaRequestTitle} №
    ${item?.requestNumber}
    ${loc.main.by}
    ${transformDateToString(item?.requestDate)}
    ${item?.counterpartyName}
  `;
};

export const defineMarker = (id?: number | null) => {
  switch (id) {
  case 1:
    return { color: GREEN_COLOR, text: loc.tooltips.repair };
  case 2:
    return { color: YELLOW_COLOR, text: loc.tooltips.limitedRepair };
  case 3:
    return { color: RED_COLOR, text: loc.tooltips.repairIsImpossible };
  default:
    return { color: WHITE_COLOR, text: "" };
  };
};

export const isEmptyDeviceCompany = (device: RmaRequestDeviceBrowseDto) => {
  return !device.pulsar && !device.polaris && !device.yukon;
};

export const getRemainingCompanies = (firmSelectionTitle: string | undefined) => {
  const filteredCompanies = filter(companyOptions, option => (
    option.title !== firmSelectionTitle
    && option.title !== Companies.OPYU
  ));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return reduce(filteredCompanies, (prev: any, curr: any) => ({
    ...prev, 
    [curr.title.toLocaleLowerCase()]: false
  }),{});
};