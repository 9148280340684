import React, { useEffect, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { useFormik } from "formik";
import { useApi, useHttp } from "hooks";
import { BasicModal, BasicModalColumn, BasicModalColumns, BasicModalContent } from "../BasicModal";
import loc from "localization";
import { DeviceInfoBlock } from "./DeviceInfoBlock";
import styles from "./styles.module.scss";
import { DefectInfoBlock } from "./DefectInfoBlock";
import { RejectionOfMaintenanceBlock } from "./RejectionOfMaintenanceBlock";
import { InfoContainer } from "../InfoContainer";
import { BasicInput } from "../BasicInput";
import { BasicCheckbox } from "../BasicCheckbox";
import { transformDateToString } from "utils";
import { CommandbarCommands } from "constants/enums";
import { CommandWrapper } from "../CommandWrapper";
import { TechnicalConclusionActionModal } from "./TechnicalConclusionActionModal";
import { ConfirmModal } from "../ConfirmModal";

interface ITechnicalConclusionModalProps {
  deviceId?: number;
  firmId?: number;
  onDismiss: () => void;
};

export const TechnicalConclusionModal = ({ deviceId, firmId, onDismiss }: ITechnicalConclusionModalProps) => {
  const [activeCommand, setActiveCommand] = useState<CommandbarCommands>(CommandbarCommands.None);
  const { devicesApi } = useApi();
  const devicesHttp = useHttp();

  const handleModalDismiss = () => setActiveCommand(CommandbarCommands.None);

  const basicPayload = {
    deviceId: Number(deviceId),
    firmId: Number(firmId),
  };

  const submitHandlerRequest = () => {
    return devicesHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionPut({
      deviceId: Number(deviceId),
      firmId: Number(firmId),
      technicalConclusionUpdatePayload: {
        vOsmotr: formik.values.vOsmotr,
        test: formik.values.test,
        zakl: formik.values.zakl,
        prichina1: formik.values.prichina1,
        prichina2: formik.values.prichina2,
        defectNotConfirmed: formik.values.defectNotConfirmed,
        meetsQuality: formik.values.meetsQuality,
        prevention: formik.values.prevention,
        showAdvice: formik.values.showAdvice
      }
    }));
  };

  const submitHandler = () => formik.values.devId && submitHandlerRequest();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formik: any = useFormik({
    initialValues: {},
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  const getTechnicalConclusion = () => {
    devicesHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionGet({
      deviceId: Number(deviceId),
      firmId: Number(firmId)
    })).then((res: AxiosResponse) => {
      formik.setValues({
        ...res.data.technicalConclusion,
        enableReason1: res.data.enableReason1,
        enableReason2: res.data.enableReason2,
        isValidWarranty: res.data.isValidWarranty
      });
    });
  };

  const notificationMessage = useMemo(() => {
    return !formik.values.warranty && !(formik.values.prichina1 || formik.values.prichina2)
      ? loc.technicalConclusionModal.notificationMessage
      : "";
  }, [formik.values.prichina1, formik.values.prichina2, formik.values.isValidWarranty]);

  useEffect(() => {
    getTechnicalConclusion();
  }, []);

  return (
    <BasicModal
      additionalClassName={styles.technicalConclusionModal}
      isLoading={devicesHttp.isLoading}
      title={
        loc.formatString(
          loc.technicalConclusionModal.title,
          formik.values.tzNomer || "",
          transformDateToString(formik.values.tzData) || ""
        )
      }
      errors={devicesHttp.errorMessages || notificationMessage}
      submitMessage={loc.warnings.changesAreSavedAutomatically}
      dismissButtonText={loc.buttons.close}
      onSubmit={formik.handleSubmit}
      onDismiss={onDismiss}
    >
      <>
        <div className="actions">
          <DefaultButton
            text={loc.buttons.print}
            onClick={() => setActiveCommand(CommandbarCommands.Print)}
          />
          <DefaultButton
            text={loc.buttons.excel}
            onClick={() => setActiveCommand(CommandbarCommands.Excel)}
          />
          <DefaultButton
            text={loc.buttons.loadDefects}
            onClick={() => {
              formik.values.zakl?.length
                ? setActiveCommand(CommandbarCommands.ReloadDefects)
                : setActiveCommand(CommandbarCommands.LoadDefects);
            }}
          />
        </div>
        <BasicModalContent>
          <DeviceInfoBlock formik={formik} />
          <DefectInfoBlock formik={formik} setActiveCommand={setActiveCommand} />
          {!formik.values.warranty ? <RejectionOfMaintenanceBlock formik={formik} /> : <div />}
          <InfoContainer
            infoContainerClassName={styles.flexColumn}
            label={loc.technicalConclusionModal.executedWork}
          >
            <BasicInput
              multiline
              name="zakl"
              value={formik.values.zakl || ""}
              onChange={(value: string) => formik.setFieldValue("zakl", value)}
              onBlur={formik.handleSubmit}
              maxLength={254}
            />
          </InfoContainer>

          <BasicModalColumns>
            <BasicModalColumn>
              <InfoContainer>
                <BasicCheckbox
                  label={loc.technicalConclusionModal.deviceMeetsTheQualityControlRequirements}
                  checked={Boolean(formik.values.meetsQuality)}
                  onChange={(checked) => {
                    formik.setFieldValue("meetsQuality", checked);
                    formik.handleSubmit();
                  }}
                />
              </InfoContainer>
            </BasicModalColumn>

            <BasicModalColumn>
              {!formik.values.warranty ? (
                <InfoContainer
                  infoContainerClassName={styles.flexEnd}
                  infoContainerLabelClassName={styles.flexInherit}
                  infoContainerValueClassName={styles.flexInherit}
                  label={loc.technicalConclusionModal.repairCost}
                >
                  <>{formik.values.stoimRem?.toFixed(2)} {formik.values.obozn}</>
                </InfoContainer>
              ) : <div />}
            </BasicModalColumn>
          </BasicModalColumns>
          <CommandWrapper isActive={activeCommand === CommandbarCommands.Excel}>
            <TechnicalConclusionActionModal
              basicPayload={basicPayload}
              onDismiss={handleModalDismiss}
              action={formik.values.isUpgradable ? "upgradbleExcel" : "excel"}
            />
          </CommandWrapper>
          <CommandWrapper isActive={activeCommand === CommandbarCommands.Print}>
            <TechnicalConclusionActionModal
              basicPayload={basicPayload}
              onDismiss={handleModalDismiss}
              action={formik.values.isUpgradable ? "upgradblePdf" : "print"}
            />
          </CommandWrapper>
          <CommandWrapper isActive={activeCommand === CommandbarCommands.LoadDefects}>
            <TechnicalConclusionActionModal
              basicPayload={basicPayload}
              onDismiss={handleModalDismiss}
              action="loadDefects"
              updateDocument={getTechnicalConclusion}
              formik={formik}
              submitHandlerRequest={submitHandlerRequest}
            />
          </CommandWrapper>
          <CommandWrapper isActive={activeCommand === CommandbarCommands.ReloadDefects}>
            <ConfirmModal
              text={loc.technicalConclusionModal.reloadDefects}
              leftButton={<PrimaryButton text={loc.buttons.yes} onClick={() => setActiveCommand(CommandbarCommands.LoadDefects)} />}
              rightButton={<DefaultButton text={loc.buttons.no} onClick={handleModalDismiss} />}
              onDismiss={handleModalDismiss}
            />
          </CommandWrapper>
          <CommandWrapper isActive={activeCommand === CommandbarCommands.ReloadNotification}>
            <ConfirmModal
              text={loc.technicalConclusionModal.reloadNotification}
              leftButton={<PrimaryButton text={loc.buttons.reLoad} onClick={() => setActiveCommand(CommandbarCommands.LoadDefects)} />}
              rightButton={<DefaultButton text={loc.buttons.cancel} onClick={handleModalDismiss} />}
              onDismiss={handleModalDismiss}
            />
          </CommandWrapper>
        </BasicModalContent>
      </>
    </BasicModal>
  );
};