import { useContext, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import { find } from "lodash-es";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { useFormik } from "formik";
import { useApi, useHttp } from "hooks";
import { LanguageContext } from "context/languageContext";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { SerialNumInput } from "components/common/SerialNumInput";
import { CommandWrapper } from "components/common/CommandWrapper";
import { ConfirmModal } from "components/common/ConfirmModal";
import { DeviceBatteryDto, DeviceBatteryTypeBrowseDto, DeviceBySerialDto } from "generated-sources/openapi";
import { transformDateFormat, transformDateToString } from "utils";
import { getLcid } from "utils";
import * as Yup from "yup";

export interface ICorrectSerialNumberBatteryModalProps {
  basicPayload: { firmId: number, deviceId: number, batteryId: number };
  battery: DeviceBatteryDto;
  updateState: () => void;
  types: DeviceBatteryTypeBrowseDto[];
  onDismiss: () => void;
  readonly?: boolean;
}

export const CorrectSerialNumberBatteryModal = (props: ICorrectSerialNumberBatteryModalProps) => {
  const { devicesApi } = useApi();
  const { isLoading, request, errorMessages } = useHttp();
  const { loc, language } = useContext(LanguageContext);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [deviceBySerialFound, setDeviceBySerialFound] = useState<DeviceBySerialDto | null>(null);

  const correctSerial = (deviceBySerialFound?: DeviceBySerialDto, confirm?: boolean) => {
    const correctBatterySerialPayload = deviceBySerialFound ? {
      ...formik.values,
      sek: confirm ? deviceBySerialFound?.se : props.battery.seK,
      dataProiz: transformDateFormat(transformDateToString(deviceBySerialFound?.productionDate)),
      proiz: deviceBySerialFound?.proiz,
    } : {
      ...formik.values,
      sek: "",
      dataProiz: "",
      proiz: null,
    };
    request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdBatteriesBatteryIdCorrectSerialPost({
      ...props.basicPayload,
      correctBatterySerialPayload,
    })
    ).then((res: AxiosResponse) => {
      if (res.status === 200) {
        props.updateState();
        props.onDismiss();
      };
    });
  };

  const searchCorrectSerial = () => {
    request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSearchCorrectSerialGet({
      ...props.basicPayload,
      serial: formik.values.serial,
      lcid: getLcid(language),
    }))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          if (res.data.deviceBySerial) {
            setDeviceBySerialFound(res.data.deviceBySerial);
            setConfirmModal(true);
          } else {
            correctSerial();
          };
        };
      });
  };

  const checkSerialExist = () => {
    request(() => devicesApi.apiOpDevicesGeneralCheckSerialExistGet({ serial: formik.values.serial }))
      .then((res: AxiosResponse) => {
        if (res.data.isUnique) {
          searchCorrectSerial();
        } else {
          setConfirmModal(true);
        };
      });
  };

  const confirmSubmit = () => !deviceBySerialFound ? searchCorrectSerial() : correctSerial(deviceBySerialFound, true);
  const confirmDecline = () => !deviceBySerialFound ? setConfirmModal(false) : correctSerial(deviceBySerialFound, false);

  const formik = useFormik({
    initialValues: {
      serial: props.battery.nomer || "",
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      serial: Yup.string().required(loc.warnings.requiredField)
    }),
    onSubmit: () => props.battery?.oldNomer?.length ? correctSerial() : checkSerialExist(),
  });

  const haveMatchFromTypes = useMemo(() => {
    return !deviceBySerialFound ? true : Boolean(find(props.types, { se: deviceBySerialFound?.se }));
  }, [deviceBySerialFound]);

  const confirmText = useMemo(() => {
    return !deviceBySerialFound
      ? loc.formatString(loc.deviceModal.checkSerialExist, formik.values.serial)
      : `${loc.columns.serialN} ${formik.values.serial}
        ${loc.columns.sku}: ${deviceBySerialFound?.sku}
        ${deviceBySerialFound?.name}
        ${haveMatchFromTypes ? `${loc.buttons.replace}?` : ""}`;
  }, [deviceBySerialFound, formik.values.serial]);

  const matchErrorMessage = useMemo(() => {
    return !haveMatchFromTypes ? loc.deviceModal.deviceIsNotBattery : "";
  }, [haveMatchFromTypes]);

  return (
    <BasicModal
      isLoading={isLoading}
      title={loc.deviceModal.correctSerialTitle}
      errors={errorMessages}
      submitButtonText={loc.buttons.save}
      dismissButtonText={loc.buttons.cancel}
      onSubmit={formik.handleSubmit}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <InfoContainer label={loc.columns.serialN}>
          <SerialNumInput
            name="serial"
            value={formik.values.serial}
            onChange={(value: string) => formik.setFieldValue("serial", value)}
            errorMessage={formik.errors.serial}
            disabled={props.readonly}
          />
        </InfoContainer>
        
        <CommandWrapper isActive={confirmModal}>
          <ConfirmModal
            preLine
            isLoading={isLoading}
            text={confirmText}
            leftButton={haveMatchFromTypes ? <PrimaryButton text={loc.buttons.yes} onClick={confirmSubmit} /> : <div />}
            rightButton={<DefaultButton text={haveMatchFromTypes ? loc.buttons.no : loc.buttons.close} onClick={confirmDecline} />}
            errors={errorMessages || matchErrorMessage}
            onDismiss={props.onDismiss}
          />
        </CommandWrapper>
      </BasicModalContent>
    </BasicModal>
  );
};
