import { ScrollablePane, SelectionMode } from "@fluentui/react";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { Table } from "components/common/Table";
import { LanguageContext } from "context/languageContext";
import { ExpiringLicenseDto } from "generated-sources/openapi";
import { useApi, useHttp, useSorting } from "hooks";
import { useContext, useEffect, useState } from "react";
import { getColumns } from "./config";
import styles from "./styles.module.scss";
import { Sorting } from "constants/enums";
import { orderArrayBySorting } from "utils";

interface IExpiringLicensesProps {
  firm: number;
  onDismiss: () => void;
}

export const ExpiringLicenses = (props: IExpiringLicensesProps) => {
  const expiringLicensesHttp = useHttp<ExpiringLicenseDto[]>();
  const { loc } = useContext(LanguageContext);
  const { dashboardApi } = useApi();
  const [expiringLicenses, setExpiringLicenses] = useState<ExpiringLicenseDto[]>([]);

  const sorting = useSorting({
    field: "licenseNumber",
    type: Sorting.ASC,
  });

  const columns = getColumns(sorting);
  const sortedExpiringLicenses = orderArrayBySorting(expiringLicenses, sorting.state);

  const loadExpiringLicenses = () => {
    expiringLicensesHttp.request(() => 
      dashboardApi.apiDashboardFirmIdLicensesPost({
        firmId: props.firm,
      })
    ).then(res => {
      setExpiringLicenses(res.data);
    });
  };

  useEffect(() => {
    loadExpiringLicenses();
  }, []);

  return (
    <BasicModal
      additionalClassName={styles.expiringLicensesModal}
      isLoading={expiringLicensesHttp.isLoading}
      title={loc.labels.expiringLicenses}
      errors={expiringLicensesHttp.errorMessages}
      onDismiss={props.onDismiss}
      dismissButtonText={loc.buttons.close}
    >
      <BasicModalContent>
        <div className="scrollable-table-wrapper">
          <ScrollablePane>
            <Table
              columns={columns}
              items={sortedExpiringLicenses}
              selectionMode={SelectionMode.none}
            />
          </ScrollablePane>
        </div>
      </BasicModalContent>
    </BasicModal>
  );
};
