import { useContext, useState, useMemo, useEffect } from "react";
import { IComboBoxOption } from "@fluentui/react";
import { AxiosResponse } from "axios";
import { find, isUndefined, map } from "lodash-es";
import ZebraBrowserPrintWrapper from "zebra-browser-print-wrapper";
import { Device } from "zebra-browser-print-wrapper/lib/types";
import { useApi, useHttp } from "hooks";
import { LanguageContext } from "context/languageContext";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { BasicDropdown } from "components/common/BasicDropdown";
import { InfoContainer } from "components/common/InfoContainer";
import { acceptSuccessNotification } from "utils";

export interface IPrintBarcodeModalProps {
  serials?: { serial: string, company: string }[];
  firmId?: number;
  deviceId?: number;
  company?: string;
  getZpl: (serial: string, company?: string) => string;
  onDismiss: () => void;
}

enum PrinterStatus {
  SUCCESS,
  ZEBRA_NOT_FOUND,
  DEVICES_NOT_FOUND,
}

export const PrintBarcodeModal = (props: IPrintBarcodeModalProps) => {
  const defaultPrinterKey = localStorage.getItem("defaultPrinterKey");
  const http = useHttp();
  const { devicesApi } = useApi();
  const { loc } = useContext(LanguageContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [browserPrint, setBrowserPrint] = useState<ZebraBrowserPrintWrapper>();
  const [printerStatus, setPrinterStatus] = useState<PrinterStatus>();
  const [printers, setPrinters] = useState<Device[]>([]);
  const [selectedPrinterKey, setSelectedPrinterKey] = useState<string>();

  const printerOptions = useMemo(
    () =>
      map(printers, (printer) => ({
        key: printer.uid,
        text: `${printer.connection}: ${printer.uid}`,
      })),
    [printers],
  );

  const printServiceSerials = (e: Event) => {
    e.preventDefault();
    const printer = find(printers, { uid: selectedPrinterKey });
    if (printer) {
      browserPrint?.setPrinter(printer);
    };
    Promise.all(map(props.serials, ({ serial, company }) => browserPrint?.print(props.getZpl(serial, company))))
      .then(() => {
        acceptSuccessNotification({ status: 200 }, loc.warnings.barcodePrintedSuccessfully);
        props.onDismiss();
      });
  };

  const handleSubmit = () => {
    http.request(() =>
      devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSerialEan13Get({
        firmId: Number(props.firmId),
        deviceId: Number(props.deviceId),
      }),
    )
      .then(async (res: AxiosResponse) => {
        const printer = find(printers, { uid: selectedPrinterKey });
        if (printer) {
          browserPrint?.setPrinter(printer);
        };
        const zpl = props.getZpl(res.data.ean13, props.company);
        browserPrint?.print(zpl)
          .then(() => {
            acceptSuccessNotification(res, loc.warnings.barcodePrintedSuccessfully);
            props.onDismiss();
          });
      });
  };

  const getPrinters = () => {
    setIsLoading(true);
    browserPrint
      ?.getAvailablePrinters()
      .then((res) => {
        console.log("Available printers: ", res);
        if (res.length) {
          setPrinters(res);
          setPrinterStatus(PrinterStatus.SUCCESS);
          setSelectedPrinterKey(defaultPrinterKey || res[0]?.uid);
          browserPrint?.setPrinter(find(res, { uid: defaultPrinterKey }) || res[0]);
        } else {
          setPrinterStatus(PrinterStatus.DEVICES_NOT_FOUND);
        };
      })
      .catch((err) => {
        console.log("Available printers error: ", err);
        setPrinterStatus(PrinterStatus.DEVICES_NOT_FOUND);
      })
      .finally(() => setIsLoading(false));
  };

  const checkPrinterStatus = () => {
    browserPrint
      ?.checkPrinterStatus()
      .then(() => getPrinters())
      .catch(() => {
        setPrinterStatus(PrinterStatus.ZEBRA_NOT_FOUND);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (selectedPrinterKey) {
      localStorage.setItem("defaultPrinterKey", selectedPrinterKey);
    };
  }, [selectedPrinterKey]);

  useEffect(() => {
    checkPrinterStatus();
  }, [browserPrint]);

  useEffect(() => {
    const browserPrint = new ZebraBrowserPrintWrapper();
    setBrowserPrint(browserPrint);
  }, []);

  return (
    <BasicModal
      isLoading={http.isLoading || isLoading}
      title={loc.buttons.printBarcode}
      errors={http.errorMessages}
      submitButtonText={loc.buttons.print}
      dismissButtonText={loc.buttons.close}
      disabledSubmitButton={printerStatus !== PrinterStatus.SUCCESS}
      onSubmit={isUndefined(props.serials) ? handleSubmit : printServiceSerials}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <InfoContainer>
          <>
            {printerStatus === PrinterStatus.ZEBRA_NOT_FOUND && loc.warnings.pleaseInstallZebra}
            {printerStatus === PrinterStatus.DEVICES_NOT_FOUND && loc.warnings.thereAreNoConnectedDevices}
            {printerStatus === PrinterStatus.SUCCESS && (
              <BasicDropdown
                options={printerOptions}
                itemKey={`${selectedPrinterKey}`}
                onChange={(_, option?: IComboBoxOption) => setSelectedPrinterKey(`${option?.key}`)}
                clearButtonOff
              />
            )}
          </>
        </InfoContainer>
      </BasicModalContent>
    </BasicModal>
  );
};
