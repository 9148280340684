import { FormEvent } from "react";
import { Checkbox } from "@fluentui/react";

interface IBasicCheckboxProps {
  checked?: boolean;
  label?: string;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  indeterminate?: boolean;
}

export const BasicCheckbox = (props: IBasicCheckboxProps) => {
  return (
    <Checkbox
      label={props.label}
      checked={props.checked}
      indeterminate={props.indeterminate}
      onChange={(_?: FormEvent, checked?: boolean) =>
        props.onChange && props.onChange(Boolean(checked))
      }
      disabled={props.disabled}
    />
  );
};
