import { useEffect, useState } from "react";

type SetValue<T> = T | ((prev: T) => T);

export const useStorageState = <T>(key: string, initialValue: T): [T, (setState: SetValue<T>) => void] => {
  const [state, setState] = useState<T>(() => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [state]);

  return [state, setState];
};
