import { useContext } from "react";
import { DirectionalHint, FontIcon, IconButton, TooltipDelay, TooltipHost } from "@fluentui/react";
import { LanguageContext } from "context/languageContext";
import styles from "./style.module.scss";
import { ILegend, Legend } from "components/common/Legend";
import { isUndefined, map } from "lodash-es";

interface IPageWrapperTitle {
  title: string;
  hasTotal?: boolean;
  total?: number;
  onLegendRender?: () => JSX.Element;
  reload?: () => void;
  selectedCount?: number;
  afterCountText?: string | JSX.Element;
  isColorFilling?: boolean;
  setColorFilling?: (val: boolean) => void;
  legends?: {
    type: "line" | "point",
    items: ILegend[],
    header?: string,
  }[],
  children?: JSX.Element,
  selectedToReportCount?: number,
  selectedToReportText?: string,
  errors?: {
    rowNumbers: number[],
    errorMessage: string,
  }[],
  legendRow?: JSX.Element;
  servicePageLegend?: boolean;
};

export const PageWrapperTitle = (props: IPageWrapperTitle) => {
  const { loc } = useContext(LanguageContext);

  return (
    <div className={styles.titleWrapper}>
      <div>
        <h2 className={styles.title}>{props.title}</h2>
        {props.children}
        {props.reload && (
          <div className={styles.itemsRefresh}>
            <TooltipHost content={loc.buttons.reload}>
              <IconButton iconProps={{
                iconName: "Refresh"
              }} onClick={props.reload} />
            </TooltipHost>
          </div>
        )}
        {props.hasTotal ? (
          <div className={styles.itemsCount}>
            {loc.main.found}: {props.total}
          </div>
        ) : null}
        {props.selectedCount ? (
          <div className={styles.itemsCount}>
            {loc.main.selectedCount}: {props.selectedCount}
          </div>
        ) : null}
        {props.afterCountText ? (
          <div className={styles.itemsCount}>
            {props.afterCountText}
          </div>
        ) : null}
        {!isUndefined(props.selectedToReportCount) ? (
          <div className={styles.itemsCount}>
            {loc.main.selectedForReport} {props.selectedToReportCount}
          </div>
        ) : null}
      </div>
      <div>
        {(props.legends?.length || props.onLegendRender) ? (
          <Legend
            servicePageLegend={props.servicePageLegend}
            legends={props.legends}
            onLegendRender={props.onLegendRender}
            isColorFilling={props.isColorFilling}
            setColorFilling={props.setColorFilling}
          />
        ) : null}
        {props.errors?.length ? (
          <TooltipHost
            hostClassName={styles.errorsWrapper}
            delay={TooltipDelay.long}
            content={
              <div style={{ display: "flex", flexDirection: "column" }}>
                {map(props.errors, error => (
                  <span>
                    {`${error.errorMessage}, ${loc.warnings.rows}: ${error.rowNumbers.toString()}`}
                  </span>
                ))}
              </div>
            }
            directionalHint={DirectionalHint.rightCenter}
          >
            <FontIcon iconName="Error" />
          </TooltipHost>
        ) : null}
        {props.legendRow}
      </div>
      {/* <div className="wrappered-page-content__title-actions">
        <div className="search-icon" onClick={onSearchIconClick}>
          <FontIcon iconName={filterIsOn ? "FilterSolid" : "Filter"} />
        </div>
      </div> */}
    </div>
  );
};
