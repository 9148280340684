import { PropsWithChildren, useContext, useEffect, useState } from "react";
import { PrimaryButton, MessageBar, MessageBarType } from "@fluentui/react";
import { last } from "lodash-es";
import { useLocation } from "react-router-dom";
import { AuthContext } from "context/authContext";
import { ErrorBoundary } from "context/errorBoundaryContext";
import { AppInsightsContext } from "context/appInsightsContext";
import { CheckUpdatesContext } from "context/checkUpdatesContext";
import { Navbar } from "components/common/Navbar";
import { LeftMenu } from "components/common/LeftMenu";
import { ConfirmModal } from "components/common/ConfirmModal";
import loc from "localization";
import classNames from "classnames";
import styles from "./style.module.scss";
import { TokenExpiredModal } from "../TokenExpiredModal";

export const PageWrapper = (props: PropsWithChildren) => {
  const location = useLocation();
  const { trackView, updateTraceId } = useContext(AppInsightsContext);
  const { haveUpdates } = useContext(CheckUpdatesContext);
  const {
    user,
    tokenExpiredModal,
    networkErrorModal,
    setTokenExpiredModal,
    setNetworkErrorModal,
    onRelogin,
    handleLogout,
  } = useContext(AuthContext);
  const [connectionIsChecking, setConnectionIsChecking] = useState<boolean>(false);
  const reloadPage = () => {
    window.location.reload();
  };
  const retryConnection = () => {
    setConnectionIsChecking(true);
    if (navigator.onLine) {
      setNetworkErrorModal(false);
    } else {
      setTimeout(() => setConnectionIsChecking(false), 1000);
    }
  };
  useEffect(() => {
    updateTraceId();
    const pageName = last(location.pathname.split("/"))?.toUpperCase();
    if (pageName) {
      trackView(user, `${pageName} page has been visited`);
    };
  }, [location]);

  return (
    <div className={styles.pageWrapper}>
      <Navbar />
      <div className={styles.pageWrapperContent}>
        <LeftMenu />
        <div className={classNames("wrappered-page", styles.container)}>
          <ErrorBoundary>{props.children}</ErrorBoundary>
          {haveUpdates && (
            <MessageBar className={styles.haveUpdatesMessage} messageBarType={MessageBarType.warning}>
              {loc.warnings.appHaveUpdates}&nbsp;
              <u onClick={reloadPage}>{loc.buttons.update}</u>
            </MessageBar>
          )}
        </div>
      </div>
      {tokenExpiredModal && (
        <TokenExpiredModal
          onRelogin={onRelogin}
          handleLogout={handleLogout}
          onDismiss={() => setTokenExpiredModal(false)}
        />
      )}
      {networkErrorModal && (
        <ConfirmModal
          isLoading={connectionIsChecking}
          text={loc.networkError}
          leftButton={<PrimaryButton text={loc.buttons.retry} onClick={retryConnection} />}
          rightButton={<div />}
          onDismiss={() => setNetworkErrorModal(false)}
        />
      )}
    </div>
  );
};
