import { every, filter, map, some } from "lodash-es";

interface IField {
  value: string;
  fieldValues: string[];
}

export const filterArrayByFields = <T>(items: T[], fields: IField[]): T[] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return filter(items, (item: any) => {
    return every(map(fields, ({ value, fieldValues }: IField) => {
      return some(map(fieldValues, (fieldValue: string) => {
        return (item[fieldValue] || "")?.toLowerCase().includes(value.toLowerCase());
      }));
    }));
  });
};
