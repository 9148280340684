import { AuthContext } from "context/authContext";
import { useContext, useState } from "react";
import { useApi } from "./useApi";
import { useHttp } from "./useHttp";
import { StockAreaDto, StockAreaType } from "generated-sources/openapi";
import { find } from "lodash-es";
import { companyOptions } from "constants/options";
import { AxiosResponse } from "axios";

export const useFormStockAreas = () => {
  const { company } = useContext(AuthContext);
  const { stockAreasApi } = useApi();
  const { request, isLoading, errorMessages } = useHttp();
  const [stockAreas, setStockAreas] = useState<StockAreaDto[]>([]);
  const firmId = Number(find(companyOptions, { text: company })?.key);

  const getStockAreas = (areaType?: StockAreaType) => {
    request(() =>
      stockAreasApi.apiDictStockAreasGet({
        firmId,
        areaType: areaType || StockAreaType.All,
      }),
    ).then((res: AxiosResponse) => setStockAreas(res.data));
  };

  return { stockAreas, getStockAreas, isLoading, errorMessages };
};