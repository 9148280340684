import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { BasicRadioGroup } from "components/common/BasicRadioGroup";
import { InfoContainer } from "components/common/InfoContainer";
import { AppLanguage } from "constants/enums";
import { getChoiseGroupLangOptions } from "constants/options";
import { LanguageContext } from "context/languageContext";
import { useFormik } from "formik";
import loc from "localization";
import { useContext } from "react";

interface ISelectLanguageModalProps {
  isLoading: boolean;
  errorMessages: string;
  submitButtonText?: string;
  onlyOneLanguageKey?: AppLanguage;
  defaultLanguage?: AppLanguage;
  onSubmit: (lang: AppLanguage) => void;
  onDismiss: () => void;
}

export const SelectLanguageModal = (props: ISelectLanguageModalProps) => {
  const { language } = useContext(LanguageContext);

  const submitHandler = () => {
    props.onSubmit(formik.values.lang);
  };

  const formik = useFormik({
    initialValues: {
      lang: props.onlyOneLanguageKey || props.defaultLanguage || language,
    },
    validateOnChange: true,
    onSubmit: submitHandler,
  });

  const groupLangOptions = props.onlyOneLanguageKey ? getChoiseGroupLangOptions().map(el => {
    return el.key === props.onlyOneLanguageKey ? el : { ...el, disabled: true };
  }) : getChoiseGroupLangOptions();

  return (
    <BasicModal
      isLoading={props.isLoading}
      title={loc.main.selectLanguage}
      errors={props.errorMessages}
      submitButtonText={props.submitButtonText || loc.buttons.select}
      dismissButtonText={loc.buttons.cancel}
      onSubmit={formik.handleSubmit}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <InfoContainer label={loc.labels.documentLang}>
          <BasicRadioGroup
            name="lang"
            selectedKey={formik.values.lang}
            options={groupLangOptions}
            onChange={formik.setFieldValue}
          />
        </InfoContainer>
      </BasicModalContent>
    </BasicModal>
  );
};
