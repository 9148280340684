import { useContext, useState } from "react";
import { map } from "lodash-es";
import { DefaultButton, SelectionMode } from "@fluentui/react";
import { ScrollableTableWrapper, Table } from "components/common/Table";
import { LanguageContext } from "context/languageContext";
import { DeviceWorklistDto } from "generated-sources/openapi";
import loc from "localization";
import { getColumns } from "./config";
import { CommandWrapper } from "components/common/CommandWrapper";
import { BasicModalColumn, BasicModalColumns } from "components/common/BasicModal";
import { BasicInput } from "components/common/BasicInput";
import { CommandbarCommands } from "constants/enums";
import { useMultiSelection } from "hooks";
import { DeleteModal } from "../../DeleteModal";

export interface IWorkExecutedProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  basicPayload: { firmId: number, deviceId: number };
  worklists: DeviceWorklistDto[];
  setWorklists: (callback: (worklists: DeviceWorklistDto[]) => DeviceWorklistDto[]) => void;
  setActiveCommand: (command: CommandbarCommands) => void;
  updateDeviceInfo: (payload?: unknown) => void;
  updateState: () => void;
  readonly?: boolean;
};

export const WorkExecuted = (props: IWorkExecutedProps) => {
  const { language } = useContext(LanguageContext);
  const { formik } = props;
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [tableIsOpen, setTableIsOpen] = useState<boolean>(false);
  const { selection, selectedItems } = useMultiSelection<DeviceWorklistDto>(props.worklists);
  return (
    <div>
      <div className="table-title">
        <div>
          <DefaultButton
            className="show-table"
            iconProps={{ iconName: "Send" }}
            onClick={() => setTableIsOpen(!tableIsOpen)}
          />
          <b onClick={() => setTableIsOpen(!tableIsOpen)}>{loc.labels.workExecuted}</b>
        </div>
        {tableIsOpen ? (
          <>
            <DefaultButton
              iconProps={{ iconName: "add" }}
              onClick={() => props.setActiveCommand(CommandbarCommands.Worklist)}
              disabled={props.readonly}
            />
            <DefaultButton
              iconProps={{ iconName: "delete" }}
              disabled={!selectedItems.length || props.readonly}
              onClick={() => setDeleteModal(true)}
            />
          </>
        ) : null}
      </div>
      {tableIsOpen ? (
        <BasicModalColumns>
          <BasicModalColumn>
            <div className="scrollable-defects-wrapper">
              <ScrollableTableWrapper noData={!props.worklists?.length}>
                <Table
                  items={props.worklists}
                  columns={getColumns(language)}
                  selection={selection}
                  selectionMode={SelectionMode.multiple}
                />
              </ScrollableTableWrapper>
            </div>
          </BasicModalColumn>
          <BasicModalColumn>
            <div style={{ marginTop: 28 }}>
              <b>{loc.labels.additionalWork}</b>
              <BasicInput
                multiline
                name={"dopWorks"}
                value={formik.values.dopWorks}
                onChange={(value: string) => formik.setFieldValue("dopWorks", value)}
                onBlur={props.updateDeviceInfo}
                errorMessage={formik.errors.dopWorks}
                maxLength={254}
                disabled={props.readonly}
              />
            </div>
          </BasicModalColumn>
        </BasicModalColumns>
      ) : null}

      <CommandWrapper isActive={deleteModal}>
        <DeleteModal
          text={loc.deviceModal.deleteWorkTitle}
          type="work"
          payload={{ workIds: map(selectedItems, item => item.workId) }}
          basicPayload={props.basicPayload}
          updateState={props.updateState}
          onDismiss={() => setDeleteModal(false)}
        />
      </CommandWrapper>
    </div>
  );
};
