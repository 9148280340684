import { IColumn } from "@fluentui/react";
import * as Yup from "yup";

export const getStorageKeyTableKey = (tableKey: string) => `${tableKey}TableInfo`;

const infoSchema = Yup.array(Yup.object({
  key: Yup.string().required(),
  width: Yup.number().required()
})).required();

export const getColumnsInfo = (tableKey: string) => {
  const res = localStorage.getItem(getStorageKeyTableKey(tableKey));
  try {
    const data = JSON.parse(res || "[]");
    return infoSchema.cast(data);
  } catch(err) {
    console.error("Invalid saved data", err);
    localStorage.removeItem(getStorageKeyTableKey(tableKey));
    return [];
  }
};

export const getColumnWidth = (tableKey: string, columnName: string) => {
  const res = getColumnsInfo(tableKey);
  return res.find(el => el.key === columnName)?.width;
};

export const updateColumnsInfo = (tableKey: string, newColumns: IColumn[]) => {
  localStorage.setItem(getStorageKeyTableKey(tableKey), JSON.stringify(newColumns.map(column =>
    ({
      key: column.key,
      width: column.maxWidth
    })
  )));
};
