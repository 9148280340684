import { AxiosResponse } from "axios";
import { useContext, useEffect } from "react";
import { LanguageContext } from "context/languageContext";
import { useApi, useHttp } from "hooks";
import { useFormik } from "formik";
import { downloadFile, getLcid, getPdf } from "utils";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { BasicRadioGroup } from "components/common/BasicRadioGroup";
import { getChoiseGroupLangOptions } from "constants/options";
import { AppLanguage } from "constants/enums";

export interface ITechnicalConclusionActionModalProps {
  basicPayload: { deviceId: number, firmId: number }
  onDismiss: () => void;
  action: "print" | "excel" | "loadDefects" | "upgradbleExcel" | "upgradblePdf";
  updateDocument?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submitHandlerRequest?: () => Promise<AxiosResponse<unknown, any>>
};

enum ActionUrl {
  Excel = "apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportExcelPost",
  Print = "apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionExportPdfPost",
  ExcellUpgradbleDevice = "apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportExcelPost",
  PrintlUpgradbleDevice = "apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionUpgradableExportPdfPost",
  LoadDefects = "apiOpDevicesDeviceDeviceIdFirmFirmIdTechnicalConclusionLoadDefectsPost",
};

export const TechnicalConclusionActionModal = (props: ITechnicalConclusionActionModalProps) => {
  const { devicesApi } = useApi();
  const { isLoading, request, errorMessages } = useHttp();
  const { loc } = useContext(LanguageContext);

  const getActionUrl = () => {
    if (props.action === "upgradbleExcel") return ActionUrl.ExcellUpgradbleDevice;
    if (props.action === "upgradblePdf") return ActionUrl.PrintlUpgradbleDevice;
    if (props.action === "loadDefects") return ActionUrl.LoadDefects;
    if (props.action === "excel") {
      return ActionUrl.Excel;
    } else {
      return ActionUrl.Print;
    };
  };

  const loadDefects = (actionUrl: ActionUrl) => {
    request(() => devicesApi[actionUrl]({
      ...props.basicPayload,
      technicalConclusionLoadDefectsPayload: {
        lcid: getLcid(formik.values.lang),
      }
    }),
    ).then(() => {
      props.updateDocument && props.updateDocument();
      props.formik.status?.field && props.formik.setStatus({});
      props.onDismiss();
    });
  };

  const getDocument = (actionUrl: ActionUrl) => {
    request(() => devicesApi[actionUrl]({
      ...props.basicPayload,
      lcid: getLcid(formik.values.lang),
    }, { responseType: "blob" }),
    ).then((res: AxiosResponse) => {
      actionUrl === ActionUrl.Excel || actionUrl === ActionUrl.ExcellUpgradbleDevice
        ? downloadFile(res)
        : getPdf(res);
      props.onDismiss();
    });
  };

  const submitHandler = () => {
    const actionUrl = getActionUrl();
    if (actionUrl === ActionUrl.LoadDefects) {
      if (props.formik.status?.field) {
        props.formik.setFieldValue(props.formik.status.field, !props.formik.values[props.formik.status.field]);
      } else {
        loadDefects(actionUrl);
      };
    } else {
      getDocument(actionUrl);
    };
  };

  useEffect(() => {
    if (props.formik?.status?.field) {
      if (props.formik.values[props.formik.status?.field] === props.formik.status?.value) {
        props.submitHandlerRequest && props.submitHandlerRequest().then(() => loadDefects(ActionUrl.LoadDefects));
      };
    };
    // eslint-disable-next-line
  }, [props.formik?.values[props.formik?.status?.field]]);

  const formik = useFormik({
    initialValues: {
      lang: AppLanguage.English,
    },
    validateOnChange: true,
    onSubmit: submitHandler,
  });

  return (
    <BasicModal
      isLoading={isLoading}
      title={loc.main.selectLanguage}
      errors={errorMessages}
      submitButtonText={loc.buttons.upload}
      dismissButtonText={loc.buttons.cancel}
      onSubmit={formik.handleSubmit}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <InfoContainer
          label={props.action === "loadDefects"
            ? loc.labels.language
            : loc.labels.documentLang
          }
        >
          <BasicRadioGroup
            name="lang"
            selectedKey={formik.values.lang}
            options={getChoiseGroupLangOptions()}
            onChange={formik.setFieldValue}
          />
        </InfoContainer>
      </BasicModalContent>
    </BasicModal>
  );
};