import { Modal, FontIcon, PrimaryButton, DefaultButton, ContextualMenu } from "@fluentui/react";
import classNames from "classnames";
import { MessageBlock } from "components/common/MessageBlock";
import { Loading } from "components/common/LoadingWrapper";
import { BasicModalContent, BasicModalColumns, BasicModalColumn } from "./components";
import styles from "./styles.module.scss";

interface IBasicModalProps {
  title: string;
  children: JSX.Element;
  additionalClassName?: string;
  submitButtonText?: string;
  dismissButtonText: string;
  additionalButton?: JSX.Element;
  isLoading: boolean;
  errors?: string | string[];
  warnings?: string | string[];
  submitMessage?: string;
  disabledSubmitButton?: boolean;
  disabledDismissButton?: boolean;
  isDraggable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit?: (e?: any) => void;
  onDismiss: () => void | undefined;
}

const BasicModal = (props: IBasicModalProps) => {
  return (
    <Modal
      isOpen
      isBlocking
      className={classNames(styles.basicModal, props.additionalClassName)}
      onDismiss={props.onDismiss}
      dragOptions={props.isDraggable ? { 
        moveMenuItemText: "Move",
        closeMenuItemText: "Close",
        menu: ContextualMenu,
        dragHandleSelector: ".drag-handle"
      } : undefined}
    >
      <form onSubmit={props.onSubmit}>
        <div className={styles.basicModalHeader}>
          <div className={classNames(styles.basicModalTitle, props.isDraggable ? "drag-handle" : "")}>
            {props.title}
            <FontIcon onClick={props.onDismiss} iconName="ChromeClose" />
          </div>
        </div>
        <Loading isLoading={props.isLoading}>{props.children}</Loading>
        <div className={styles.bottomBlock}>
          {props.warnings?.length ? (
            <MessageBlock messages={props.warnings} isWarning />
          ) : null}
          {props.errors?.length ? (
            <MessageBlock messages={props.errors} />
          ) : null}
          <div className={styles.basicModalFooter}>
            <div className="flex-row">
              {props.submitButtonText && (
                <PrimaryButton
                  type="submit"
                  text={props.submitButtonText}
                  onClick={props.onSubmit}
                  disabled={props.isLoading || props.disabledSubmitButton}
                  className={styles.submitButton}
                />
              )}
              {props.additionalButton}
              {props.submitMessage}
            </div>
            <DefaultButton
              text={props.dismissButtonText}
              onClick={props.onDismiss}
              disabled={props.isLoading || props.disabledDismissButton}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export { BasicModal, BasicModalContent, BasicModalColumns, BasicModalColumn };
