import { useContext, useEffect, useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import { isNull, map, reduce } from "lodash-es";
import classNames from "classnames";
import { useFormik } from "formik";
import { DefaultButton, Pivot, PivotItem } from "@fluentui/react";
import { useApi, useHttp } from "hooks";
import {
  CurrencyBrowseDto,
  DeviceAddedSeDto,
  DeviceBatteryDto,
  DeviceDefectsDto,
  DeviceReplacedSeDto,
  DeviceWorklistDto,
  ReplacementDevicesBrowseDto,
} from "generated-sources/openapi";
import { CommandbarCommands, Permissions } from "constants/enums";
import { AuthContext } from "context/authContext";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { CommandWrapper } from "components/common/CommandWrapper";
import { PrintBarcodeModal } from "components/common/PrintBarcodeModal";
import loc from "localization";
import { checkPermission, transformDateFormat, transformDateToString, getDeviceBeltexZpl, getDeviceZpl } from "utils";
import { MainBlock } from "./MainBlock";
import { FeaturesTab } from "./FeaturesTab";
import { DefectsTab } from "./DefectsTab";
import { BatteriesTab } from "./BatteriesTab";
import { NotesTab } from "./NotesTab";
import { SerialNumberModal } from "./SerialNumberModal";
import { AddDefectModal } from "./AddDefectModal";
import { DeviceHistoryModal } from "./DeviceHistoryModal";
import { CorrectSerialNumberModal } from "./CorrectSerialNumberModal";
import { ReplacementDeviceModal } from "./ReplacementDeviceModal";
import { AddReplacedSeDeviceModal } from "./AddReplacedSeDeviceModal";
import { AddWorklistModal } from "./AddWorklistModal";
import { AddBatteryModal } from "./AddBatteryModal";
import { TechnicalConclusionModal } from "../TechnicalConclusionModal";
import { RepairCostCalculationModal } from "./RepairCostCalculationModal";
import { AddEquipmentModal } from "./AddEquipmentModal";
import { PrintModal } from "./PrintModal";
import { AddAddedSeModal } from "./AddAddedSeModal";
import { PackingModal } from "./PackingModal";
import styles from "./styles.module.scss";

interface IDeviceModalProps {
  firmId?: number;
  deviceId?: number;
  printIsDisabled?: boolean;
  batteriesIsDisabled?: boolean;
  deviceHistoryIsDisabled?: boolean;
  rccIsDisabled?: boolean;
  tcIsDisabled?: boolean;
  onDismiss: () => void;
  updateState: () => void;
  readonly?: boolean;
  workshopNumber?: string | null;
  selectedRepariArea?: string;
}

export const DeviceModal = (props: IDeviceModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useContext(AuthContext);
  const { devicesApi, currencyApi } = useApi();
  const devicesHttp = useHttp();
  const updateHttp = useHttp();
  const currenciesHttp = useHttp();

  const [deviceIsLocked, setDeviceIsLocked] = useState<boolean>(false);
  const [activeCommand, setActiveCommand] = useState<CommandbarCommands>(CommandbarCommands.None);
  const [currencies, setCurrencies] = useState<CurrencyBrowseDto[]>([]);
  const [defects, setDefects] = useState<DeviceDefectsDto[]>([]);
  const [replacedSes, setReplacedSes] = useState<DeviceReplacedSeDto[]>([]);
  const [addedSes, setAddedSes] = useState<DeviceAddedSeDto[]>([]);
  const [worklists, setWorklists] = useState<DeviceWorklistDto[]>([]);
  const [batteries, setBatteries] = useState<DeviceBatteryDto[]>([]);
  const [canChangeSe, setCanChangeSe] = useState<boolean>(false);
  const [canChangeSerial, setCanChangeSerial] = useState<boolean>(false);
  const [addedSesSum, setAddedSesSum] = useState<number>(0);
  const [replacedSesSum, setReplacedSesSum] = useState<number>(0);
  const [warningMessage, setWarningMessage] = useState<string>("");
  const [updated, setUpdated] = useState(false);
  const [readOnlyMode, setReadOnlyMode] = useState<boolean>(true);
  const readOnly = useMemo(() => readOnlyMode || props.readonly, [readOnlyMode, props.readonly]);

  const specPermission = useMemo(() => checkPermission(user, Permissions.FullControl), [user]);
  const otkPermission = useMemo(() => checkPermission(user, Permissions.ManageOtk), [user]);

  const currencyOptions = useMemo(
    () =>
      map(currencies, (currency) => ({
        key: `${currency.currencyId}`,
        text: `${currency.currencyName}`,
      })),
    [currencies],
  );

  const basicPayload = {
    deviceId: Number(props.deviceId),
    // deviceId: Number(193808),
    // deviceId: Number(174291),
    firmId: Number(props.firmId),
  };

  const handleModalDismiss = () => setActiveCommand(CommandbarCommands.None);

  const lockDevice = () => {
    devicesHttp.request(() =>
      devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdLockPost(basicPayload),
    ).then(() => setReadOnlyMode(false));
  };

  const unlockDevice = () => {
    devicesHttp.request(() =>
      devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdUnlockPost(basicPayload),
    );
  };

  const checkChangeSe = () => {
    devicesHttp
      .request(() =>
        devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSeGet(basicPayload),
      )
      .then((res: AxiosResponse) => setCanChangeSe(res.data.canChangeSe));
  };
  const checkChangeSerial = () => {
    devicesHttp
      .request(() =>
        devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralCheckChangeSerialGet(basicPayload),
      )
      .then((res: AxiosResponse) => setCanChangeSerial(res.data.canChangeSerial));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const submitHandler = (payload?: any) => {
    const additionalPayload = payload || {};
    updateHttp.request(() =>
      devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdPut({
        ...basicPayload,
        updateDevicePayload: {
          speedRepair: formik.values.speedRepair,
          dataProiz: transformDateFormat(formik.values.dataProiz),
          proiz: formik.values.proiz || null,
          dataProd: transformDateFormat(formik.values.dataProd),
          wait: formik.values.wait,
          defectConfirmed: formik.values.defectConfirmed,
          repairToSaleState: formik.values.repairToSaleState,
          completeToSaleState: formik.values.completeToSaleState,
          komplekt: formik.values.komplekt,
          vneshVid: formik.values.vneshVid,
          complaints: formik.values.complaints,
          complRus: formik.values.complRus,
          prostaf: formik.values.prostaf,
          notes: formik.values.notes,
          notesQc: formik.values.notesQc,
          notesKli: formik.values.notesKli,
          pAnaliz: formik.values.pAnaliz,
          dopWorks: formik.values.dopWorks,
          repairWithoutCost: formik.values.repairWithoutCost,
          customerCommunication: formik.values.customerCommunication,
          replacementForNew: formik.values.replacementForNew,
          requestConfirmation: formik.values.requestConfirmation,
          ...additionalPayload,
        },
      }),
    );
  };

  const replaceDevice = (item: ReplacementDevicesBrowseDto) => {
    formik.setValues({
      ...formik.values,
      nameRu: item.nameRu,
      nameEn: item.nameEn,
      seK: item.se,
      sku: item.sku,
    });
  };

  const confirmRepair = (repeatedRepairConfirmed: boolean) => {
    devicesHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetRepeatedRepairConfirmedPost({
      deviceId: Number(props.deviceId),
      firmId: Number(props.firmId),
      setRepeatedRepairConfirmedPayload: {
        repeatedRepairConfirmed
      }
    }))
      .then(getDeviceInfo);
  };

  const setPremilinaryrepairCost = (preliminaryRepairCost: boolean) => {
    devicesHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralSetPreliminaryRepairCostPost({
      deviceId: Number(props.deviceId),
      firmId: Number(props.firmId),
      setPreliminaryCostPayload: {
        preliminaryRepairCost
      }
    }))
      .then(getDeviceInfo);
  };

  const getDeviceInfo = () => {
    setIsLoading(true);
    devicesHttp
      .request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdDetailsGet(basicPayload))
      .then((res: AxiosResponse) => {
        formik.setValues({
          ...res.data.generalInformation,
          previousCostRem: res.data.generalInformation?.stoimRem,
          dataProiz: transformDateToString(res.data.generalInformation.dataProiz),
          dataProd: transformDateToString(res.data.generalInformation.dataProd),
          proiz: !isNull(res.data.generalInformation.proiz)
            ? `${res.data.generalInformation.proiz}`
            : null,
        });
        setDefects(res.data.defects);
        setReplacedSes([
          ...res.data.replacedSes,
          {
            nameEn: "Total",
            nameRu: "Итого",
            kol: reduce(res.data.replacedSes, (prev, next) => prev + next.kol, 0),
            sum: reduce(res.data.replacedSes, (prev, next) => prev + next.price * next.kol, 0),
          },
        ]);
        setReplacedSesSum(reduce(res.data.replacedSes, (prev, next) => prev + next.price * next.kol, 0));
        setAddedSes([
          ...res.data.addedSes,
          {
            nameEn: "Total",
            nameRu: "Итого",
            kol: reduce(res.data.addedSes, (prev, next) => prev + next.kol, 0),
            sum: reduce(res.data.addedSes, (prev, next) => prev + next.price * next.kol, 0),
          },
        ]);
        setAddedSesSum(reduce(res.data.addedSes, (prev, next) => prev + next.price * next.kol, 0));
        setWorklists(res.data.worklists);
        setBatteries(
          map(res.data.batteries, (battery) => ({
            ...battery,
            dataProiz: transformDateToString(battery.dataProiz),
          })),
        );
      })
      .finally(() => setIsLoading(false));
  };

  const getCurrencies = () => {
    currenciesHttp
      .request(() => currencyApi.apiDictCurrenciesListGet())
      .then((res: AxiosResponse) => setCurrencies(res.data));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formik: any = useFormik({
    initialValues: {
      reControl: false,
      reControlC: false,
      beControl: false,
      beControlC: false,
      chk: null,
      complRus: "",
      deviceId: null,
      dopWorks: null,
      fake: false,
      fidZam: null,
      firmId: null,
      firmName: "",
      invoiceNumber: "",
      konId: null,
      konName: "",
      loc: false,
      nameEn: "",
      nameL: "",
      nameN: "",
      nameRu: "",
      nomer: "",
      notes: "",
      notesKli: "",
      notesQc: "",
      oldNomer: "",
      oper: "",
      pAnaliz: "",
      parentDeviceId: null,
      parentFirmId: null,
      seK: "",
      sklRem: "",
      sku: "",
      speedRepair: false,
      komplekt: "",
      vneshVid: "",
      complaints: "",
      repairToSaleState: false,
      completeToSaleState: false,
      dataProd: "",
      dataProiz: "",
      defectConfirmed: false,
      vozvrBezR: false,
      platRem: null as null | boolean,
      schet: false,
      prostaf: false,
      valRem: null,
      idZam: null,
      konVal: null,
      stoimRem: "",
      previousCostRem: "",
      proiz: null,
      wait: false,
      warranty: false,
      warrantyTerm: null,
      secondaryReturn: false,
      repeatedRepairConfirmed: false,
      preliminaryCost: false,
      repairWithoutCost: false,
      customerCommunication: "",
      buyer: "",
      replacementForNew: false,
      requestConfirmation: false
    },
    validateOnChange: true,
    onSubmit: () => submitHandler(),
  });

  useEffect(() => {
    lockDevice();
    getCurrencies();
    getDeviceInfo();
    checkChangeSe();
    checkChangeSerial();
    return () => {
      unlockDevice();
    };
  }, []);

  useEffect(() => {
    if (devicesHttp.errorMessages === loc.badOperations["device-locked-by-other-user"]) {
      console.log("TUT!!!");
      setDeviceIsLocked(true);
    }
  }, [devicesHttp.errorMessages]);

  const nomerIsEmpty = !formik.values.nomer || formik.values.nomer === "0000000000";

  const repairCostCalculationButtonIsEnabled = Boolean(
    formik.values.konId === 4 ||
    formik.values.konId === 8 ||
    formik.values.konId === 5 ||
    formik.values.firmId === 8 ||
    formik.values.firmId === 4,
  );

  const onDismis = () => {
    if (!warningMessage.length) {
      updated && props.updateState();
      props.onDismiss();
    }
  };

  useEffect(() => {
    if (devicesHttp.errorMessages?.length) setIsLoading(false);
  }, [devicesHttp.errorMessages?.length]);


  return (
    <BasicModal
      additionalClassName={classNames(styles.deviceModal, { [styles.deviceIsLockedModal]: deviceIsLocked })}
      isLoading={devicesHttp.isLoading || currenciesHttp.isLoading || isLoading}
      title={loc.deviceModal.title}
      errors={devicesHttp.errorMessages || warningMessage}
      submitButtonText={""}
      submitMessage={deviceIsLocked ? "" : loc.warnings.changesAreSavedAutomatically}
      dismissButtonText={loc.buttons.close}
      onSubmit={formik.handleSubmit}
      disabledDismissButton={Boolean(warningMessage.length)}
      onDismiss={onDismis}
    >
      <>
        {deviceIsLocked ? (
          <></>
        ) : (
          <div className="actions">
            <DefaultButton
              text={loc.buttons.print}
              menuProps={{
                items: [
                  {
                    key: "serial",
                    text: loc.buttons.print,
                    onClick: () => setActiveCommand(CommandbarCommands.Print),
                    disabled: props.printIsDisabled || readOnly,
                  },
                  {
                    key: "device",
                    text: loc.buttons.printBarcode,
                    onClick: () => setActiveCommand(CommandbarCommands.Barcode),
                    disabled: readOnly,
                  },
                ],
              }}
              disabled={readOnly}
            />
            <DefaultButton
              text={loc.buttons.replace}
              menuProps={{
                items: [
                  {
                    key: "serial",
                    text: loc.columns.serialN,
                    onClick: () => setActiveCommand(CommandbarCommands.Serial),
                    disabled: !canChangeSerial || nomerIsEmpty,
                  },
                  {
                    key: "device",
                    text: loc.buttons.device,
                    onClick: () => setActiveCommand(CommandbarCommands.Edit),
                    disabled: !canChangeSe,
                  },
                ],
              }}
              disabled={readOnly}
            />
            <DefaultButton
              text={loc.buttons.deviceHistory}
              onClick={() => setActiveCommand(CommandbarCommands.History)}
              disabled={readOnly || props.deviceHistoryIsDisabled}
            />
            <DefaultButton
              text={loc.buttons.correctSerial}
              onClick={() => setActiveCommand(CommandbarCommands.Correct)}
              disabled={!canChangeSerial || formik.values.oldNomer?.length || readOnly}
            />
            <DefaultButton
              text={loc.buttons.rcc}
              onClick={() => setActiveCommand(CommandbarCommands.Calculation)}
              disabled={!repairCostCalculationButtonIsEnabled || readOnly || props.rccIsDisabled}
            />
            <DefaultButton
              text={loc.buttons.technicalConclusion}
              onClick={() => setActiveCommand(CommandbarCommands.TechnicalConclusion)}
              disabled={readOnly || props.tcIsDisabled}
            />
            <DefaultButton
              text={loc.buttons.package}
              onClick={() => setActiveCommand(CommandbarCommands.Package)}
              disabled={readOnly}
            />
            <a href={`${formik.values?.packingInstructionsUrl || ""}`}>
              <DefaultButton
                text={loc.buttons.packingInstructions}
                disabled={!formik.values.packingInstructionsUrl?.length}
              />
            </a>

          </div>
        )}

        <BasicModalContent>
          {deviceIsLocked ? (
            <></>
          ) : (
            <>
              <MainBlock
                formik={formik}
                basicPayload={basicPayload}
                readonly={readOnly}
                updateState={() => setUpdated(true)}
              />
              <Pivot>
                <PivotItem headerText={loc.labels.features}>
                  <FeaturesTab
                    formik={formik}
                    firmId={props.firmId}
                    deviceId={props.deviceId}
                    currencyOptions={currencyOptions}
                    specPermission={specPermission}
                    otkPermission={otkPermission}
                    onRowDataUpdate={() => setUpdated(true)}
                    updateState={getDeviceInfo}
                    setActiveCommand={setActiveCommand}
                    readonly={readOnly}
                    setPremilinaryrepairCost={setPremilinaryrepairCost}
                  />
                </PivotItem>
                <PivotItem headerText={loc.labels.defects}>
                  <DefectsTab
                    formik={formik}
                    basicPayload={basicPayload}
                    defects={defects}
                    setDefects={setDefects}
                    replacedSes={replacedSes}
                    setReplacedSes={setReplacedSes}
                    worklists={worklists}
                    setWorklists={setWorklists}
                    setActiveCommand={setActiveCommand}
                    onRowDataUpdate={() => setUpdated(true)}
                    updateState={() => {
                      getDeviceInfo();
                    }}
                    setWarningMessage={setWarningMessage}
                    readonly={readOnly}
                    specPermission={specPermission}
                    otkPermission={otkPermission}
                    addedSes={addedSes}
                    setAddedSes={setAddedSes}
                    replacedSesSum={replacedSesSum}
                    addedSesSum={addedSesSum}
                    confirmRepair={confirmRepair}
                  />
                </PivotItem>
                <PivotItem
                  headerButtonProps={{
                    style: {
                      pointerEvents: props.batteriesIsDisabled ? "none" : "auto",
                      opacity: props.batteriesIsDisabled ? 0.4 : 1,
                    },
                  }}
                  headerText={`${loc.labels.batteries} (${batteries.length})`}
                >
                  <BatteriesTab
                    basicPayload={basicPayload}
                    batteries={batteries}
                    setBatteries={setBatteries}
                    activeCommand={activeCommand}
                    setActiveCommand={setActiveCommand}
                    updateState={getDeviceInfo}
                  />
                </PivotItem>
                <PivotItem headerText={loc.labels.notes}>
                  <NotesTab formik={formik} readonly={readOnly} />
                </PivotItem>
              </Pivot>
              <CommandWrapper isActive={activeCommand === CommandbarCommands.Print}>
                <PrintModal
                  basicPayload={basicPayload}
                  onDismiss={handleModalDismiss}
                />
              </CommandWrapper>
              <CommandWrapper isActive={activeCommand === CommandbarCommands.Barcode}>
                <PrintBarcodeModal
                  firmId={basicPayload.firmId}
                  deviceId={basicPayload.deviceId}
                  company={formik.values.konName}
                  getZpl={formik.values.firmId === 1 ? getDeviceBeltexZpl : getDeviceZpl}
                  onDismiss={handleModalDismiss}
                />
              </CommandWrapper>
              <CommandWrapper isActive={activeCommand === CommandbarCommands.Serial}>
                <SerialNumberModal
                  basicPayload={basicPayload}
                  serial={formik.values.nomer}
                  serialOld={formik.values.oldNomer}
                  updateState={() => {
                    setUpdated(true);
                    checkChangeSerial();
                    getDeviceInfo();
                  }}
                  onDismiss={handleModalDismiss}
                />
              </CommandWrapper>
              <CommandWrapper isActive={activeCommand === CommandbarCommands.Correct}>
                <CorrectSerialNumberModal
                  formik={formik}
                  basicPayload={basicPayload}
                  updateState={() => {
                    setUpdated(true);
                    getDeviceInfo();
                  }}
                  onDismiss={handleModalDismiss}
                />
              </CommandWrapper>
              <CommandWrapper isActive={activeCommand === CommandbarCommands.DefectsList}>
                <AddDefectModal
                  basicPayload={basicPayload}
                  updateState={getDeviceInfo}
                  onDismiss={handleModalDismiss}
                />
              </CommandWrapper>
              <CommandWrapper isActive={activeCommand === CommandbarCommands.ReplacedList}>
                <AddReplacedSeDeviceModal
                  basicPayload={basicPayload}
                  updateState={getDeviceInfo}
                  onDismiss={handleModalDismiss}
                  workshopNumber={props.workshopNumber}
                  selectedrepairArea={props.selectedRepariArea}
                />
              </CommandWrapper>
              <CommandWrapper isActive={activeCommand === CommandbarCommands.AddedList}>
                <AddAddedSeModal
                  basicPayload={basicPayload}
                  updateState={getDeviceInfo}
                  onDismiss={handleModalDismiss}
                  selectedrepairArea={props.selectedRepariArea}
                />
              </CommandWrapper>
              <CommandWrapper isActive={activeCommand === CommandbarCommands.AddBattery}>
                <AddBatteryModal
                  basicPayload={basicPayload}
                  updateState={getDeviceInfo}
                  onDismiss={handleModalDismiss}
                />
              </CommandWrapper>
              <CommandWrapper isActive={activeCommand === CommandbarCommands.Worklist}>
                <AddWorklistModal
                  basicPayload={basicPayload}
                  updateState={getDeviceInfo}
                  onDismiss={handleModalDismiss}
                />
              </CommandWrapper>
              <CommandWrapper isActive={activeCommand === CommandbarCommands.History}>
                <DeviceHistoryModal
                  formik={formik}
                  basicPayload={basicPayload}
                  updateState={getDeviceInfo}
                  onDismiss={handleModalDismiss}
                />
              </CommandWrapper>
              <CommandWrapper isActive={activeCommand === CommandbarCommands.Edit}>
                <ReplacementDeviceModal
                  basicPayload={basicPayload}
                  updateState={replaceDevice}
                  onDismiss={handleModalDismiss}
                />
              </CommandWrapper>
              <CommandWrapper isActive={activeCommand === CommandbarCommands.Calculation}>
                <RepairCostCalculationModal
                  serialNumbers={{ serial: formik.values.nomer, oldSerial: formik.values.oldNomer }}
                  deviceName={{ en: formik.values.nameEn, ru: formik.values.nameRu }}
                  basicPayload={basicPayload}
                  replacedSes={replacedSes}
                  onDismiss={handleModalDismiss}
                />
              </CommandWrapper>
              <CommandWrapper isActive={activeCommand === CommandbarCommands.TechnicalConclusion}>
                <TechnicalConclusionModal
                  firmId={props?.firmId}
                  deviceId={props?.deviceId}
                  onDismiss={handleModalDismiss}
                />
              </CommandWrapper>
              <CommandWrapper isActive={activeCommand === CommandbarCommands.Equipment}>
                <AddEquipmentModal
                  formik={formik}
                  updateState={submitHandler}
                  onDismiss={handleModalDismiss}
                />
              </CommandWrapper>
              <CommandWrapper isActive={activeCommand === CommandbarCommands.Package}>
                <PackingModal
                  basicPayload={basicPayload}
                  textPackagingInfo={{
                    battaries: map(batteries, battery => ({ serial: battery.nomer || "", name: battery.nameRu || "" })),
                    equipments: formik.values.komplekt || "",
                    comment: formik.values.notesKli || ""
                  }}
                  onDismiss={handleModalDismiss}
                  updateState={() => {
                    setUpdated(true);
                    getDeviceInfo();
                  }}
                />
              </CommandWrapper>
            </>
          )}
        </BasicModalContent>
      </>

    </BasicModal>
  );
};
