import { useState, useContext } from "react";
import { Callout, DirectionalHint, FontIcon, Calendar, defaultCalendarStrings, DayOfWeek } from "@fluentui/react";
import { LanguageContext } from "context/languageContext";
import { getModifiedCalendarStrings } from "./helpers";

interface IDatePickerCalloutProps {
  monthCalendar?: boolean;
  value?: Date | null;
  target: string;
  minDate?: Date | null;
  maxDate?: Date | null;
  onSelectDate: (date: Date) => void;
  onBlur?: () => void;
  disabled?: boolean;
}

export const DatePickerCallout = (props: IDatePickerCalloutProps) => {
  const { language } = useContext(LanguageContext);
  const [isCalloutVisible, setIsCalloutVisible] = useState<boolean>(false);
  const modifiedCalendarStrings = getModifiedCalendarStrings(defaultCalendarStrings, language);
  return (
    <>
      <FontIcon iconName="calendar" onClick={() => !props.disabled && setIsCalloutVisible(!isCalloutVisible)} />
      {isCalloutVisible && (
        <Callout
          target={props.target}
          isBeakVisible={false}
          directionalHint={DirectionalHint.bottomLeftEdge}
          setInitialFocus
          onDismiss={() => setIsCalloutVisible(false)}
        >
          <Calendar
            value={props.value || undefined}
            isDayPickerVisible={!props.monthCalendar}
            firstDayOfWeek={DayOfWeek.Monday}
            showMonthPickerAsOverlay={!props.monthCalendar}
            highlightSelectedMonth
            showGoToToday={false}
            onSelectDate={(date: Date) => {
              props.onSelectDate(date);
              props.onBlur && props.onBlur();
              setIsCalloutVisible(false);
            }}
            minDate={props.minDate ? props.minDate : undefined}
            maxDate={props.maxDate ? props.maxDate : undefined}
            strings={modifiedCalendarStrings}
            calendarMonthProps={{
              styles: { selected: { backgroundColor: "#009688", color: "white" } },
            }}
          />
        </Callout>
      )}
    </>
  );
};
