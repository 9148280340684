import { ChoiceGroup, IChoiceGroupOption } from "@fluentui/react";
import styles from "./styles.module.scss";

interface IBasicRadioGroupProps {
  name: string;
  selectedKey?: string;
  options: IChoiceGroupOption[];
  onChange?: (field: string, key?: string) => void;
}

export const BasicRadioGroup = (props: IBasicRadioGroupProps) => {
  return (
    <ChoiceGroup
      className={styles.basicRadioGroup}
      selectedKey={props.selectedKey}
      options={props.options}
      onChange={(_, option?: IChoiceGroupOption) => props.onChange && props.onChange(props.name, option?.key)}
    />
  );
};
