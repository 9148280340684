import { useState } from "react";
import { TextField } from "@fluentui/react";
import { isUndefined, filter } from "lodash-es";
import { numberRegex } from "constants/regex";

interface INumberFieldProps<T> {
  item: T;
  value: string;
  name: string;
  onChange: (item: T, field: string, value?: string) => void;
  onBlur?: (item: T) => void;
  // showErrorInput?: boolean;
  style?: React.CSSProperties;
  fract?: number;
  integer?: number;
  negative?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  placeholder?: string;
  emptyValues?: boolean;
  className?: string;
  // cannotBeZero?: boolean;
  selectValue?: boolean;
}

export const NumberField = <T,>(props: INumberFieldProps<T>) => {
  const [initValue, setInitValue] = useState<string>(props.value);
  const fract = !isUndefined(props.fract) ? props.fract : 2;
  const integer = !isUndefined(props.integer) ? props.integer : 255;
  return (
    <TextField
      // className={props.showErrorInput ? "error-input" : ""}
      type="text"
      className={props.className}
      autoComplete="off"
      inputMode="decimal"
      name={props.name}
      value={props.value}
      style={props.style}
      disabled={props.disabled}
      placeholder={props.placeholder}
      errorMessage={props.errorMessage}
      onFocus={e => {
        if (initValue !== props.value) setInitValue(props.value);
        if (Number(props.value).toFixed(fract) === (0).toFixed(fract)) {
          e?.currentTarget?.select();
        };
        if(props.selectValue) e?.currentTarget?.select();
      }}
      onKeyPress={(e) => !props.negative && e.key === "-" && e.preventDefault()}
      onChange={(_, value) => {
        const val = value?.replace(",", ".");
        const point = filter(val?.split(""), (v) => v === ".").length < 2;
        if (
          !isUndefined(val) &&
          point &&
          (numberRegex.test(val) || val === "" || (props.negative && val.includes("-")))
        ) {
          const array = val?.split(".");
          if (
            (String((array && array[1]) || 0)?.length < fract + 1 ||
              (array.length === 1 && fract === 0)) &&
            array &&
            array[0].length < integer + 1
          ) {
            // props.onChange(props.name, val);
            props.onChange(props.item, props.name, val);
          }
        }
      }}
      onBlur={() => {
        if (initValue !== props.value) {
          if (props.onBlur) {
            const item: T = props.item;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            props.onBlur({ ...item, [props.name]: Number((item as any)[props.name]) });
          } else if (props.value === "" && !props.emptyValues) {
            props.onChange(props.item, props.name, "0");
          }
          setInitValue(props.value);
        }
        // if (props.cannotBeZero && !Number(props.value) && props.onBlur) {
        //   props.onBlur({ ...props.item, [props.field]: "1" });
        // }
      }}
    />
  );
};
