import { AppLanguage } from "constants/enums";
import loc from "localization";

export const getColumns = (language: AppLanguage) => [
  {
    key: "nameRu",
    name: language === AppLanguage.Russian ? loc.columns.nameRu : loc.columns.nameEn,
    fieldName: language === AppLanguage.Russian ? "nameRu" : "nameEn",
    minWidth: 180,
    maxWidth: 180,
    isResizable: true,
  },
];
