import ltFlag from "images/flags/lt.svg";
import sctFlag from "images/flags/gb-sct.svg";
import cnFlag from "images/flags/cn.svg";
import lvFlag from "images/flags/lv.svg";
import scFlag from "images/flags/ch-be-fr-au.svg";

interface ICompanyFlagProps {
  companyName: string;
}

export const CompanyFlag = ({ companyName }: ICompanyFlagProps) => {
  switch(companyName) {
  case "Y": {
    return <img width={40} src={ltFlag} />;
  }
  case "P": {
    return <img width={40} src={sctFlag} />;
  }
  case "L": {
    return <img width={40} src={lvFlag} />;
  }
  case "O": {
    return <img width={40} src={cnFlag} />;
  }
  case "SC": {
    return <img width={40} src={scFlag} />;
  }
  }
  return null;
};