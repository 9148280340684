import { IComboBoxOption } from "@fluentui/react";
import { BasicDropdown } from "components/common/BasicDropdown";
import { NumberField } from "components/common/NumberField";
import { AppLanguage } from "constants/enums";
import { DeviceReplacedSeDto } from "generated-sources/openapi";
import loc from "localization";
import styles from "./styles.module.scss";

export const getColumns = (
  language: AppLanguage,
  stockAreasOptions: IComboBoxOption[],
  stockAreasIsLoading: boolean,
  onChange: (item: DeviceReplacedSeDto) => void,
  onBlur: (item: DeviceReplacedSeDto) => void,
  readonly?: boolean,
) => [
  {
    key: "seK",
    name: loc.columns.se,
    fieldName: "seK",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
  {
    key: "nameRu",
    name: loc.columns.name,
    fieldName: language === AppLanguage.Russian ? "nameRu" : "nameEn",
    minWidth: 200,
    maxWidth: 200,
    isResizable: true,
  },
  {
    key: "kol",
    name: loc.columns.qty,
    fieldName: "kol",
    minWidth: 90,
    maxWidth: 90,
    isResizable: true,
    onRender: (item: DeviceReplacedSeDto) => item.nameEn !== "Total" ? (
      <NumberField
        item={item}
        name="kol"
        integer={10}
        fract={0}
        value={`${item.kol}`}
        onChange={(item: DeviceReplacedSeDto, field: string, value?: string) => onChange({ ...item, [field]: value })}
        onBlur={onBlur}
        disabled={readonly}
      />
    ) : <div className={styles.totalCount}>{item.kol}</div>,
  },
  {
    key: "skl",
    name: loc.labels.repairArea,
    fieldName: "skl",
    minWidth: 140,
    maxWidth: 140,
    isResizable: true,
    onRender: (item: DeviceReplacedSeDto) => item.nameEn !== "Total" ? (
      <BasicDropdown
        itemKey={`${item.skl}`}
        options={stockAreasOptions}
        onChange={(_, option?: IComboBoxOption) => onBlur({ ...item, skl: `${option?.key}` })}
        disabled={readonly || stockAreasIsLoading}
        clearButtonOff
      />
    ) : null,
  },
  {
    key: "price",
    name: loc.columns.price,
    fieldName: "price",
    minWidth: 80,
    maxWidth: 80,
    isResizable: true,
    onRender: (item: DeviceReplacedSeDto) => item.nameEn !== "Total" ? (
      <NumberField
        item={item}
        name="price"
        fract={2}
        value={`${item.price}`}
        onChange={(item: DeviceReplacedSeDto, field: string, value?: string) => onChange({ ...item, [field]: value })}
        disabled={readonly}
        onBlur={onBlur}
      />
    ) : item.price?.toFixed(2),
  },
  {
    key: "sum",
    name: loc.columns.amount,
    fieldName: "sum",
    minWidth: 80,
    maxWidth: 80,
    isResizable: true,
    onRender: (item: DeviceReplacedSeDto & { sum: number }) => (item.nameEn !== "Total" ? (Number(item.kol) * Number(item.price)) : item.sum).toFixed(2)
  },
  {
    key: "obozn",
    name: loc.columns.currency,
    fieldName: "obozn",
    minWidth: 80,
    maxWidth: 80,
    isResizable: true,
  },
];
