import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { ConfirmModal } from "components/common/ConfirmModal";
import { useApi, useHttp } from "hooks";
import loc from "localization";
import { deleteSuccessNotification } from "utils";

interface IDeleteModalProps {
  tn: string;
  onSuccess: () => void;
  onDismiss: () => void;
}

export const DeleteModal = (props: IDeleteModalProps) => {
  const { isLoading, request, errorMessages } = useHttp();
  const { settingsUsersApi } = useApi();
  const submitHandler = () => {
    request(() => settingsUsersApi.apiSettingsUsersDeleteDelete({
      deleteUserPayload: {
        tn: props.tn
      }
    })).then(res => {
      deleteSuccessNotification(res);
      props.onSuccess();
      props.onDismiss();
    });
  };
  return (
    <ConfirmModal
      isLoading={isLoading}
      text={loc.settings.deleteUser}
      onDismiss={props.onDismiss}
      errors={errorMessages}
      leftButton={<PrimaryButton text={loc.buttons.delete} onClick={submitHandler} />}
      rightButton={<DefaultButton text={loc.buttons.cancel} onClick={props.onDismiss} />}
    />
  );
};