import { AppLanguage } from "constants/enums";
import { DeviceHistoryBrowseDto } from "generated-sources/openapi";
import loc from "localization";
import { transformDateToString } from "utils";
import { ISorting } from "hooks/useSorting";
import { Marker } from "components/common/Marker";
import { GREEN_COLOR, RED_COLOR } from "constants/colors";
import { getSortingColumnHeader } from "components/common/SortingColumnHeader";

export const getColumns = (language: AppLanguage, sorting: ISorting) => [
  {
    key: "transactionId",
    name: "Id",
    fieldName: "transactionId",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
    onRenderHeader: getSortingColumnHeader(sorting),
  },
  {
    key: "lastName",
    name: loc.columns.fullName,
    fieldName: "lastName",
    minWidth: 120,
    maxWidth: 120,
    isResizable: true,
    onRender: (item: DeviceHistoryBrowseDto) => {
      return (
        <>
          <Marker text={loc.tooltips.repaired} color={GREEN_COLOR} display={item.soPr === "0"} />
          <Marker text={loc.tooltips.damaged} color={RED_COLOR} display={item.soPr === "1"} />
          <div style={{ paddingLeft: "10px" }}>
            {item.lastName} {item.firstName}
          </div>
        </>
      );
    },
  },
  {
    key: "soDate",
    name: loc.columns.date,
    fieldName: "soDate",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
    onRender: (item: DeviceHistoryBrowseDto) => transformDateToString(item.soDate),
  },
  {
    key: "sklIn",
    name: loc.columns.number,
    fieldName: "sklIn",
    minWidth: 40,
    maxWidth: 40,
    isResizable: true,
  },
  {
    key: "nameRu",
    name: `${loc.columns.warehouse} / ${loc.columns.area}`,
    fieldName: language === AppLanguage.Russian ? "nameRu" : "nameEn",
    minWidth: 150,
    maxWidth: 150,
    isResizable: true,
  },
  {
    key: "docNumber",
    name: loc.columns.documentN,
    fieldName: "docNumber",
    minWidth: 120,
    maxWidth: 120,
    isResizable: true,
  },
  {
    key: "docDate",
    name: loc.columns.dateDocument,
    fieldName: "docDate",
    minWidth: 120,
    maxWidth: 120,
    isResizable: true,
    onRender: (item: DeviceHistoryBrowseDto) => transformDateToString(item.docDate),
  },
];
