import classNames from "classnames";
import styles from "./styles.module.scss";

interface IPageWrapperContentProps {
  className?: string;
  children: JSX.Element | JSX.Element[];
}

export const PageWrapperContent = (props: IPageWrapperContentProps) => {
  return <div className={classNames(styles.wrapperedPageContent, props.className)}>{props.children}</div>;
};
