import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { AppLanguage } from "constants/enums";
import { useFormik } from "formik";
import { useApi, useHttp } from "hooks";
import * as Yup from "yup";
import loc from "localization";
import { InfoContainer } from "components/common/InfoContainer";
import styles from "./styles.module.scss";
import { NumberField } from "components/common/NumberField";
import { BasicInput } from "components/common/BasicInput";
import { BasicComboBox } from "components/common/BasicComboBox";
import { BasicRadioGroup } from "components/common/BasicRadioGroup";
import { companyOptions, getChoiseGroupLangOptions } from "constants/options";
import { namedSuccessNotification } from "utils";
import { useContext } from "react";
import { RepairAreasContext } from "context/repairAreasContext";
import { BasicDropdown } from "components/common/BasicDropdown";

interface IAddBadgeModalProps {
  onSuccess: () => void;
  onDismiss: () => void;
}

export const AddBadgeModal = (props: IAddBadgeModalProps) => {
  const { isLoading, request, errorMessages } = useHttp();
  const { settingsUsersApi } = useApi();  

  const {
    reapairAreasOptions,
    isRepairAreasLoading
  } = useContext(RepairAreasContext);

  const submitHandler = () => {
    request(() => settingsUsersApi.apiSettingsUsersCreateForBadgePost({
      createUserForBadgePayload: formik.values
    })).then(res => {
      namedSuccessNotification(res, loc.notifications.theUserAddedSuccessfully);
      props.onSuccess();
      props.onDismiss();
    });
  };

  const formik = useFormik({
    initialValues: {
      tn: "",
      lastName: "",
      firstName: "",
      area: "",
      company: "",
      language: AppLanguage.Russian,
    },
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      tn: Yup.string().min(4, loc.warnings.tabNumberMustBe4Characters).required(loc.warnings.requiredField),
      lastName: Yup.string().required(loc.warnings.requiredField),
      firstName: Yup.string().required(loc.warnings.requiredField),
      area: Yup.string().required(loc.warnings.requiredField),
      company: Yup.string().required(loc.warnings.requiredField),
    }),
    onSubmit: submitHandler,
  });

  return (
    <BasicModal
      isLoading={isLoading || isRepairAreasLoading}
      title={loc.settings.addUser}
      errors={errorMessages}
      submitButtonText={loc.buttons.add}
      dismissButtonText={loc.buttons.cancel}
      disabledSubmitButton={!formik.isValid || !formik.dirty}
      additionalClassName={styles.modal}
      onSubmit={formik.handleSubmit}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <InfoContainer infoContainerClassName={styles.tnInfoContainer} label={loc.columns.tabNumber}>
          <NumberField
            name="tn"
            item={formik.values}
            onChange={(_item, field, value) => formik.setFieldValue(field, value)}
            integer={4}
            errorMessage={formik.errors.tn}
            value={formik.values.tn}
          />
        </InfoContainer>
        <InfoContainer label={loc.columns.lastName}>
          <BasicInput
            value={formik.values.lastName}
            errorMessage={formik.errors.lastName}
            onChange={(value) => formik.setFieldValue("lastName", value)}
          />
        </InfoContainer>
        <InfoContainer label={loc.columns.firstName}>
          <BasicInput
            value={formik.values.firstName}
            errorMessage={formik.errors.firstName}
            onChange={(value) => formik.setFieldValue("firstName", value)}
          />
        </InfoContainer>
        <InfoContainer label={loc.labels.area}>
          <BasicDropdown
            itemKey={formik.values.area}
            options={reapairAreasOptions}
            errorMessage={formik.errors.area}
            onChange={(_e, value) => formik.setFieldValue("area", value?.key)}
          />
        </InfoContainer>
        <InfoContainer label={loc.columns.company}>
          <BasicComboBox
            itemKey={formik.values.company}
            errorMessage={formik.errors.company}
            options={companyOptions.map(el => ({ key: el.text, text: el.text }))}
            onChange={(_, option) => formik.setFieldValue("company", option?.text)}
            globalFilter
          />
        </InfoContainer>
        <InfoContainer label={loc.columns.language}>
          <BasicRadioGroup
            name="language"
            selectedKey={formik.values.language}
            options={getChoiseGroupLangOptions()}
            onChange={formik.setFieldValue}
          />
        </InfoContainer>
      </BasicModalContent>
    </BasicModal>
  );
};