import loc from "localization";
import { includes, last, map } from "lodash-es";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const defineError = (error: any) => {
  const data = error?.response?.data;
  if (data?.status === 400) {
    if (
      data?.type === "bad-operation-error"
      || data?.type === "business-rule-error"
      || data?.type === "resource-not-found-error"
    ) {
      return loc.badOperations[data?.detail] || loc.badOperations.unknown;
    }
    if (data?.type === "bad-request-error") {
      if (Object.entries(data?.Errors).length) {
        if (includes(Object.keys(data?.Errors), "payload.DefectNumber")) {
          return loc.warnings.defectNumberIsNotValid;
        };
        if (includes(Object.keys(data?.Errors), "payload.DocVal")) {
          return loc.warnings.docValIsNotValid;
        };
        if (data?.Errors && data.Errors["payload.Devices"] && data.Errors["payload.Devices"][0] === "ContainsDuplicatedItems") {
          return loc.badOperations.containsDuplicatedItems;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errors = map(Object.entries(data?.Errors), ([field, messages]: any) => {
          return messages[0]?.replace("''", last(field.split(".")));
        });
        return errors;
      };
      return data?.title;
    };
    return data?.title;
  };
  if (data?.status === 404) {
    if (loc.badOperations[data?.detail]) {
      return loc.badOperations[data?.detail];
    }
    if (data?.type === "resource-not-found-error") {
      return `404: ${data?.detail}`;
    };
  };
  if (error) {
    return loc.badOperations[error?.detail] || loc.badOperations.unknown;
  }
};
