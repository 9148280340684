import { CommandbarCommands } from "constants/enums";
import loc from "localization";
interface IMenuItem {
  key: string;
  name: string;
  command: CommandbarCommands;
  display: boolean;
}

export const getMenuItems = (specPermission: boolean): IMenuItem[] => [{
  key: "roleManagement",
  name: loc.settings.roleManagement,
  command: CommandbarCommands.RoleManagement,
  display: specPermission
}, {
  key: "configureNotifications",
  name: loc.settings.configureNotifications,
  command: CommandbarCommands.ConfigureNotifications,
  display: true
}, {
  key: "employeeBadge",
  name: loc.settings.employeeBadge,
  command: CommandbarCommands.EmployeeBadge,
  display: true
}, {
  key: "printAccompanyingCard",
  name: loc.settings.printAccompanyingCard,
  command: CommandbarCommands.AccompanyingCard,
  display: true
}];