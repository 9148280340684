import React, { createContext, useEffect, useState, useContext, PropsWithChildren } from "react";
import loc, { ILocFile } from "localization";
import { AppLanguage } from "constants/enums";
import { AuthContext } from "./authContext";

interface ILanguageContext {
  language: AppLanguage;
  loc: ILocFile;
  changeAppLanguage: (val: AppLanguage) => void;
}

export const LanguageContext = createContext<ILanguageContext>({
  language: AppLanguage.Russian,
  loc,
  changeAppLanguage: () => {
    throw new Error("Not implemented");
  },
});

export const LanguageContextProvider = (props: PropsWithChildren) => {
  const { user } = useContext(AuthContext);
  const [language, setLanguage] = useState<AppLanguage>(localStorage.appLanguage || user?.language);

  const initAppLanguage = () => {
    const lang = localStorage.appLanguage;
    lang !== language && changeAppLanguage(language);
  };

  const changeAppLanguage = (lang: AppLanguage) => {
    setLanguage(lang);
    localStorage.setItem("appLanguage", lang);
  };

  useEffect(() => {
    initAppLanguage();
  }, []);
  loc.setLanguage(language);

  return (
    <LanguageContext.Provider value={{ loc, language, changeAppLanguage }}>
      {props.children}
    </LanguageContext.Provider>
  );
};
