import { Modal } from "@fluentui/react";
import { Loading } from "components/common/LoadingWrapper";
import { MessageBlock } from "../MessageBlock";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface IConfirmModalProps {
  isLoading?: boolean;
  text: string;
  onDismiss: () => void;
  leftButton?: JSX.Element;
  rightButton?: JSX.Element;
  additionalButton?: JSX.Element;
  errors?: string | string[];
  preLine?: boolean;
}

export const ConfirmModal = (props: IConfirmModalProps) => {
  return (
    <Modal
      isBlocking
      isOpen
      className={classNames(styles.confirmModal, { "additional-btn": props.additionalButton })}
      onDismiss={props.onDismiss}
    >
      <Loading isLoading={Boolean(props.isLoading)}>
        <>
          <div
            className={styles.confirmModalContent}
            style={{ whiteSpace: props.preLine ? "pre-line" : "initial" }}
          >
            {props.text}
          </div>
          <div className="bottom-block">
            {props.errors?.length ? <MessageBlock messages={props.errors} /> : null}
            <div className={styles.confirmModalFooter}>
              {props.leftButton}
              {props.additionalButton && props.additionalButton}
              {props.rightButton && props.rightButton}
            </div>
          </div>
        </>
      </Loading>
    </Modal>
  );
};
