import { useContext, useMemo } from "react";
import { SelectionMode } from "@fluentui/react";
import { ScrollableTableWrapper, Table } from "components/common/Table";
import { DeviceHistoryBrowseDto } from "generated-sources/openapi";
import { LanguageContext } from "context/languageContext";
import { useSorting } from "hooks";
import { Sorting } from "constants/enums";
import { orderArrayBySorting } from "utils";
import { getColumns } from "./config";

export interface IWorkShopMovementsTabProps {
  workshopMovementsHistory: DeviceHistoryBrowseDto[]
};

export const WorkshopMovementsTab = (props: IWorkShopMovementsTabProps) => {
  const { language } = useContext(LanguageContext);
  const sorting = useSorting({
    field: "transactionId",
    type: Sorting.ASC,
  });
  const columns = useMemo(() => getColumns(language, sorting), [language]);

  const sortedItems = useMemo(
    () => orderArrayBySorting(props.workshopMovementsHistory, sorting.state),
    [props.workshopMovementsHistory, sorting.state.field, sorting.state.type]
  );

  return (
    <div className="scrollable-defects-wrapper">
      <ScrollableTableWrapper noData={!props.workshopMovementsHistory.length}>
        <Table
          items={sortedItems}
          columns={columns}
          selectionMode={SelectionMode.none}
        />
      </ScrollableTableWrapper>
    </div>
  );
};