import { BasicInput } from "components/common/BasicInput";
import { BasicModalColumn, BasicModalColumns } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import loc from "localization";
import styles from "../styles.module.scss";
import { BasicCheckbox } from "components/common/BasicCheckbox";
import { CommandbarCommands } from "constants/enums";

interface IDefectInfoBlockProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  setActiveCommand: (command: CommandbarCommands) => void;
}

export const DefectInfoBlock = ({ formik, setActiveCommand }: IDefectInfoBlockProps) => {
  return (
    <>
      <InfoContainer
        infoContainerValueClassName={styles.flexInherit}
        label={loc.technicalConclusionModal.descriptionOfDefect}
      >
        <BasicCheckbox
          label={loc.columns.defectIsNotConfirmed}
          checked={Boolean(formik.values.defectNotConfirmed)}
          onChange={(checked) => {
            formik.setFieldValue("defectNotConfirmed", checked);
            formik.handleSubmit();
          }}
        />
      </InfoContainer>
      <InfoContainer infoContainerClassName={styles.flexColumn} >
        <BasicInput
          disabled
          multiline
          name="complaints"
          value={formik.values.complaints || ""}
          onChange={(value: string) => formik.setFieldValue("complaints", value)}
        />
      </InfoContainer>
      <BasicModalColumns>
        <BasicModalColumn>
          <InfoContainer
            infoContainerClassName={styles.flexColumn}
            label={loc.technicalConclusionModal.resultOfVisualInspection}
          >
            <BasicInput
              multiline
              name="vOsmotr"
              value={formik.values.vOsmotr || ""}
              onChange={(value: string) => formik.setFieldValue("vOsmotr", value)}
              onBlur={formik.handleSubmit}
              maxLength={254}
            />
          </InfoContainer>
        </BasicModalColumn>
        <BasicModalColumn>
          <InfoContainer
            infoContainerClassName={styles.flexColumn}
            label={loc.technicalConclusionModal.testingResults}
          >
            <BasicInput
              multiline
              name="test"
              value={formik.values.test || ""}
              onChange={(value: string) => formik.setFieldValue("test", value)}
              onBlur={formik.handleSubmit}
              maxLength={254}
            />
          </InfoContainer>
        </BasicModalColumn>
      </BasicModalColumns>
      <BasicModalColumns>
        {/* <BasicModalColumn>
          <InfoContainer>
            <BasicCheckbox
              label={loc.technicalConclusionModal.prevention}
              checked={Boolean(formik.values.prevention)}
              onChange={() => {
                formik.setStatus({ field: "prevention", value: !formik.values.prevention });
                setActiveCommand(CommandbarCommands.ReloadNotification);
              }}
            />
          </InfoContainer>
        </BasicModalColumn> */}
        {formik.values.isTalion ? (
          <BasicModalColumn>
            <InfoContainer>
              <BasicCheckbox
                label={loc.technicalConclusionModal.addrRecommendations}
                checked={Boolean(formik.values.showAdvice)}
                onChange={() => {
                  formik.setStatus({ field: "showAdvice", value: !formik.values.showAdvice });
                  setActiveCommand(CommandbarCommands.ReloadNotification);
                }}
              />
            </InfoContainer>
          </BasicModalColumn>
        ) : <div />}
      </BasicModalColumns>
    </>
  );
};