import { FormEvent } from "react";
import { find, isString } from "lodash-es";
import { ComboBox, DefaultButton, IComboBox, IComboBoxOption } from "@fluentui/react";
import loc from "localization";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface IBasicDropdownProps {
  options: IComboBoxOption[];
  itemKey: number | string;
  defaultPlaceholder?: string;
  onChange: (e?: FormEvent<IComboBox>, value?: IComboBoxOption) => void;
  disabled?: boolean;
  clearButtonOff?: boolean;
  errorMessage?: string;
  className?: string;
  defaultValue?: string;
}

export const BasicDropdown = (props: IBasicDropdownProps) => {
  const onClearClick = () => props.onChange(undefined, {
    key: props.defaultValue || (isString(props.itemKey) ? "" : 0),
    text: "",
  });
  const dropDownTitle = find(props.options, { key: props.itemKey })?.text || "";
  return (
    <ComboBox
      className={classNames(props.className, styles.comboBox)}
      key={props.itemKey}
      text={dropDownTitle}
      placeholder={props.defaultPlaceholder}
      options={props.options}
      onChange={props.onChange}
      disabled={props.disabled}
      errorMessage={props.errorMessage}
      onRenderLowerContent={() =>
        props.clearButtonOff ? null : (
          <div className="lower-content">
            <DefaultButton
              text={loc.buttons.clear}
              iconProps={{ iconName: "ChromeClose" }}
              onClick={onClearClick}
              disabled={!props.itemKey}
            />
          </div>
        )
      }
    />
  );
};
