import { useContext, useState, useEffect, useMemo } from "react";
import { SelectionMode } from "@fluentui/react";
import { AxiosResponse } from "axios";
import { useApi, useDebounce, useHttp, useInfinityScroll, useSelection } from "hooks";
import { LanguageContext } from "context/languageContext";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { BasicInput } from "components/common/BasicInput";
import { CodeCeInput } from "components/common/CodeCeInput";
import { BasicToggle } from "components/common/BasicToggle";
import { ScrollableTableWrapper, Table } from "components/common/Table";
import { AppLanguage } from "constants/enums";
import { ReplacementDevicesBrowseDto } from "generated-sources/openapi";
import { getColumns } from "./config";
import styles from "../AddDefectModal/styles.module.scss";

export interface IReplacementDeviceModalProps {
  basicPayload: { firmId: number; deviceId: number };
  updateState: (item: ReplacementDevicesBrowseDto) => void;
  onDismiss: () => void;
}

export const ReplacementDeviceModal = (props: IReplacementDeviceModalProps) => {
  const { devicesApi } = useApi();
  const devicesHttp = useHttp();
  const { loc, language } = useContext(LanguageContext);
  const [devices, setDevices] = useState<ReplacementDevicesBrowseDto[]>([]);
  const { selection, selectedItem } = useSelection<ReplacementDevicesBrowseDto>();

  const [mainSearch, setMainSearch] = useState<string>("");
  const [searchCodeSeValue, setSearchCodeSeValue] = useState<string>("");
  const [searchCodeSkuValue, setSearchCodeSkuValue] = useState<string>("");
  const [filterOnlyWithSku, setFilterOnlyWithSku] = useState<boolean>(false);
  const debouncedSearchCodeSeValue = useDebounce(searchCodeSeValue, 1000);
  const debouncedSearchCodeSkuValue = useDebounce(searchCodeSkuValue, 1000);
  const debouncedMainSearch = useDebounce(mainSearch, 1000);

  const { loadingMore, isLoading, loadData } = useInfinityScroll<ReplacementDevicesBrowseDto>(
    (pageIndex, pageSize) =>
      devicesApi.apiOpDevicesGeneralReplacementDevicesGet({
        pagingPageIndex: pageIndex,
        pagingPageSize: pageSize,
        filterNameRu: language === AppLanguage.Russian ? mainSearch : "",
        filterNameEn: language === AppLanguage.English ? mainSearch : "",
        filterSe: searchCodeSeValue,
        filterSku: searchCodeSkuValue,
        filterOnlyWithSku: filterOnlyWithSku,
      }),
    setDevices,
  );

  const submitHandler = () => {
    devicesHttp.request(() =>
      devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdGeneralChangeSePost({
        ...props.basicPayload,
        changeSePayload: {
          sek: selectedItem?.se,
        },
      }),
    )
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          selectedItem && props.updateState(selectedItem);
          props.onDismiss();
        };
      });
  };

  useEffect(() => {
    loadData();
  }, [
    debouncedSearchCodeSeValue,
    debouncedSearchCodeSkuValue,
    debouncedMainSearch,
    filterOnlyWithSku,
  ]);

  const columns = useMemo(() => getColumns(language), [language]);

  return (
    <BasicModal
      additionalClassName={styles.addDefectModal}
      isLoading={devicesHttp.isLoading || isLoading}
      title={loc.deviceModal.replaceDeviceTitle}
      errors={devicesHttp.errorMessages}
      submitButtonText={loc.buttons.save}
      dismissButtonText={loc.buttons.cancel}
      onSubmit={submitHandler}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <InfoContainer>
          <div className="flex-row">
            <BasicInput
              type="number"
              className="code-field"
              value={searchCodeSkuValue}
              onChange={setSearchCodeSkuValue}
              placeholder={loc.columns.sku}
              styles={{ marginRight: 10 }}
              reset
            />
            <CodeCeInput
              className="code-field"
              value={searchCodeSeValue}
              onChange={setSearchCodeSeValue}
              placeholder={loc.columns.se}
              reset
            />
            <BasicInput
              className="name-field"
              value={mainSearch}
              onChange={setMainSearch}
              placeholder={loc.columns.name}
              styles={{ marginLeft: 10, marginRight: 10 }}
              reset
            />
            <BasicToggle
              value={filterOnlyWithSku}
              label={loc.labels.skuOnly}
              setValue={setFilterOnlyWithSku}
            />
          </div>
        </InfoContainer>
        <div className="scrollable-table-wrapper">
          <ScrollableTableWrapper noData={!devices.length} loadingMore={loadingMore}>
            <Table
              items={devices}
              columns={columns}
              selection={selection}
              selectionMode={SelectionMode.single}
            />
          </ScrollableTableWrapper>
        </div>
      </BasicModalContent>
    </BasicModal>
  );
};
