import { PRINT_ACCOMPANYING_CARD_SETTING } from "constants/storage";
import { PaperSizeType } from "generated-sources/openapi";

export const getAccompayingCardSetting = (): PaperSizeType => {
  const printAccompanyingCardSetting = localStorage.getItem(PRINT_ACCOMPANYING_CARD_SETTING);
  if (printAccompanyingCardSetting && Object.values<string>(PaperSizeType).includes(printAccompanyingCardSetting)) {
    return printAccompanyingCardSetting as PaperSizeType;
  } else {
    console.log(`Incorect value "${printAccompanyingCardSetting}" in "${printAccompanyingCardSetting}"`);
    localStorage.removeItem(PRINT_ACCOMPANYING_CARD_SETTING);
    return PaperSizeType.A4;
  }
};

export const setAccompayingCardSetting = (val: PaperSizeType) => {
  localStorage.setItem(PRINT_ACCOMPANYING_CARD_SETTING, val);
};