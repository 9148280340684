import { FontIcon } from "@fluentui/react";
import { IDefectGroup } from "components/pages/DefectsPage/types";
import { AppLanguage } from "constants/enums";
import loc from "localization";

export const getColumns = (
  language: AppLanguage,
  // collapseDefect: (defectId?: number) => void,
) => [
  {
    key: "collapse",
    name: "",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
    onRender: (item: IDefectGroup) => {
      // const onIconClick = () => collapseDefect(item.defectId);
      return item.isParent ? (
        <div className="cell-action-btn">
          <FontIcon
            iconName={item.isCollapsed ? "ChevronDownSmall" : "ChevronUpSmall"}
            // onClick={onIconClick}
            style={{ marginLeft: item.level * 20 }}
          />
        </div>
      ) : null;
    },
  },
  {
    key: "defectNumber",
    name: loc.columns.code,
    fieldName: "defectNumber",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
  {
    key: `name${language === AppLanguage.Russian ? "R" : "E"}`,
    name: loc.columns.name,
    fieldName: `name${language === AppLanguage.Russian ? "R" : "E"}`,
    minWidth: 300,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "notes",
    name: loc.columns.notes,
    fieldName: "notes",
    minWidth: 120,
    maxWidth: 120,
    isResizable: true,
  },
];
