import classNames from "classnames";
import { CommandbarCommands, PanelTab } from "constants/enums";
import { ScrollableTableWrapper } from "../Table";
import styles from "./styles.module.scss";
import { ICommandBarAction } from "../CommandBar";
import { map } from "lodash-es";
import { PrimaryButton } from "@fluentui/react";

interface IChildrenProps {
  tab?: PanelTab;
  children: JSX.Element | (JSX.Element | null)[];
}

interface IBasicPanelDetailsProps {
  children: JSX.Element;
  noData: boolean;
  loadingMore?: boolean;
};

const BasicPanelContent = (props: IChildrenProps) => {
  return (
    <div
      className={classNames(styles.basicPanelContent, {
        [styles.basicPanelContentTabs]: props.tab,
      }, "basic-panel-content")}
    >
      {props.children}
    </div>
  );
};

interface IBasicPanelActionsProps {
  actions: ICommandBarAction[];
  setActiveCommand?: (command: CommandbarCommands) => void;
}

const BasicPanelActions = (props: IBasicPanelActionsProps) => {
  const onActionClick = (command: CommandbarCommands) => {
    if (props.setActiveCommand) {
      props.setActiveCommand(command);
    }
  };
  return (
    <div className={styles.basicPanelActions}>
      {map(props.actions, (action) => 
        (
          <span key={action.key}>
            {
              action.display ? (
                action.render ? action.render(action) : <PrimaryButton
                  className={classNames(styles.basicPanelActionsBtn, {
                    [styles.basicPanelActionsBtnDisabled]: action.disabled
                  })}
                  onClick={() => onActionClick(action.command)}
                  text={action.text}
                  disabled={action.disabled}
                />
              ) : null
            }
          </span>
        )
      )}
    </div>
  );
};

const BasicPanelFilter = (props: IChildrenProps & { className?: string }) => {
  return <div className={classNames(styles.basicPanelFilter, props.className)}>{props.children}</div>;
};

const BasicPanelDetails = (props: IBasicPanelDetailsProps) => {
  return (
    <div className={styles.basicPanelDetails}>
      <ScrollableTableWrapper
        noData={props.noData}
        loadingMore={props.loadingMore}
        onPanel
      >
        {props.children}
      </ScrollableTableWrapper>
    </div>
  );
};

export { BasicPanelContent, BasicPanelActions, BasicPanelFilter, BasicPanelDetails };