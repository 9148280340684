import { IColumn, TooltipHost } from "@fluentui/react";
import { AppLanguage } from "constants/enums";
import { NotificationDto, NotificationUserDto } from "generated-sources/openapi";
import loc from "localization";

export const listOfEventsColumn = (language: AppLanguage): IColumn[] => [
  {
    key: "listOfEvents",
    name: loc.configureNotifications.listOfEvents,
    fieldName: language === AppLanguage.English ? "nameEn" : "nameRu",
    isResizable: true,
    minWidth: 100,
    maxWidth: 200,
    onRender: (item: NotificationDto) => (
      <TooltipHost content={language === AppLanguage.English ? item.nameEn : item.nameRu}>
        {language === AppLanguage.English ? item.nameEn : item.nameRu}
      </TooltipHost>
    ),
  }
];

export const usersListColumn = (title: string): IColumn[] => [
  {
    key: "usersList",
    name: title,
    fieldName: "",
    isResizable: true,
    minWidth: 100,
    maxWidth: 200,
    onRender: (item: NotificationUserDto) => {
      return (
        <div>{item.firstName} {item.lastName}</div>
      );
    }
  }
];
