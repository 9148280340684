import { format, parse, differenceInCalendarDays } from "date-fns";

export const transformStringToDate = (string: string): Date | null => {
  return parse(string, "dd/MM/yyyy", new Date().setHours(0, 0, 0, 0)).toString() !== "Invalid Date"
    ? parse(string, "dd/MM/yyyy", new Date().setHours(0, 0, 0, 0))
    : null;
};

export const transformMonthStringToDate = (string: string): Date | null => {
  return parse(string, "MM/yyyy", new Date().setHours(0, 0, 0, 0)).toString() !== "Invalid Date"
    ? parse(string, "MM/yyyy", new Date().setHours(0, 0, 0, 0))
    : null;
};

export const transformDateTimeToString = (date: Date | string | null | undefined) => {
  return !date ? "" : format(new Date(date), "dd/MM/yyyy HH:mm");
};

export const transformDateToString = (date: Date | string | null | undefined) => {
  return !date ? "" : format(new Date(date), "dd/MM/yyyy");
};

export const transformDateFormat = (string?: string | null, defaultString?: string): string => {
  const date = string && transformStringToDate(string);
  return date ? format(date, "yyyy-MM-dd") : (defaultString || "");
};

export const transformDateStringFormat = (date: Date | string | null | undefined): string => {
  const formattedDate = transformDateFormat(transformDateToString(date));
  return formattedDate;
};

export const transformStringToDateFormatMonth = (string: string): Date | null => {
  return parse(string, "MM/yyyy", new Date().setHours(0, 0, 0, 0)).toString() !== "Invalid Date"
    ? parse(string, "MM/yyyy", new Date().setHours(0, 0, 0, 0))
    : null;
};

export const getEmptyDateFormat = () => ({
  dateFrom: "1900-01-01",
  dateTo: transformDateFormat(transformDateToString(new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 6,
    new Date().getDate()
  ))),
});

export const getDateMonthsAgo = (monthsCount: number, day?: number) => {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth() - monthsCount,
    day || new Date().getDate(),
  );
};

export const getDifferenceInDays = (deadlineDate: string | Date | null | undefined) => {
  return differenceInCalendarDays(new Date(deadlineDate || ""), new Date());
};