import { createContext, useState, useEffect, PropsWithChildren, useContext } from "react";
import axios from "axios";
import { format } from "date-fns";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Loading } from "components/common/LoadingWrapper";
import { Navbar } from "components/common/Navbar";
import loc from "localization";
import { loginRequest } from "authConfig";
import { AppLanguage, Notification } from "constants/enums";
import { USER_ACCESS_ENDPOINT } from "constants/endpoints";
import { initPermissions } from "constants/objects";
import { IAlert } from "typings";
import { getPermissionScopes, IPermissions, getNotification } from "utils";
import { AuthUserDto } from "generated-sources/openapi/api";
import { TokenExpiredModal } from "components/common/TokenExpiredModal";
import { AppInsightsContext } from "./appInsightsContext";
import { removeLocalStorageItems } from "utils/removeLocalStorageItems";

export interface IAuthContext {
  name?: string;
  isAuthenticated: boolean;
  user: AuthUserDto | null;
  tokenExpiredModal: boolean;
  setTokenExpiredModal: (val: boolean) => void;
  networkErrorModal: boolean;
  setNetworkErrorModal: (val: boolean) => void;
  permissions: IPermissions;
  requestAccessToken: () => void;
  onRelogin: () => void;
  handleLogout: () => void;
  company: string;
  setCompany: (company: string) => void;
}

export const AuthContext = createContext<IAuthContext>({
  name: "",
  isAuthenticated: false,
  user: null,
  tokenExpiredModal: false,
  networkErrorModal: false,
  permissions: initPermissions,
  setTokenExpiredModal: () => {
    throw new Error("Not implemented");
  },
  setNetworkErrorModal: () => {
    throw new Error("Not implemented");
  },
  requestAccessToken: () => {
    throw new Error("Not implemented");
  },
  onRelogin: () => {
    throw new Error("Not implemented");
  },
  handleLogout: () => {
    throw new Error("Not implemented");
  },
  company: "",
  setCompany: () => {
    throw new Error("Not implemented");
  },
});

export const AuthContextProvider = (props: PropsWithChildren) => {
  const isAuthenticated = useIsAuthenticated();
  const { trackTrace } = useContext(AppInsightsContext);
  const { instance, accounts } = useMsal();
  const [accessDenied, setAccessDenied] = useState<boolean>(true);
  const [user, setUser] = useState<AuthUserDto | null>(null);
  const [error, setError] = useState<string>("");
  const [tokenExpiredModal, setTokenExpiredModal] = useState<boolean>(false);
  const [networkErrorModal, setNetworkErrorModal] = useState<boolean>(false);
  const [company, setCompany] = useState<string>(localStorage.getItem("company") || "");
  const permissions = getPermissionScopes(user, company);
  const name = accounts[0] && accounts[0].name;

  const handleLogout = () => {
    setAccessDenied(true);
    instance
      .handleRedirectPromise()
      .then(() => {
        removeLocalStorageItems();
        const account = instance.getActiveAccount();
        if (!account) {
          instance.logoutRedirect();
        }
      })
      .catch((e: Error) => getNotification("Error", e.message, Notification.ERROR));
  };

  const getUserAccessRequest = async () => {
    const token = await requestAccessToken();
    axios
      .get(USER_ACCESS_ENDPOINT, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        if (res.data.isAuthenticated) {
          setUser(res.data.user);
          if (!company) {
            setCompany(res.data.user?.company);
            localStorage.setItem("company", res.data.user?.company);
          }
          setAccessDenied(false);
        }
      })
      .catch((err) => {
        const errorMessage =
          err.response?.status === 403 ? loc.warnings.forbidden : loc.warnings.somethingGoesWrong;
        setError(errorMessage);
      });
  };

  const requestAccessToken = async () => {
    const silentRequest = { ...loginRequest, account: accounts[0], forceRefresh: false };
    let showNetworkError = false;
    let showTokenExpiredError = false;
    try {
      const tokenResponse = await instance.acquireTokenSilent(silentRequest);
      return tokenResponse?.accessToken;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.message === "Network Error" && !networkErrorModal) {
        showNetworkError = true;
      } else if (
        error?.errorCode === "login_required" ||
        error?.errorCode === "multiple_matching_tokens" ||
        error?.errorCode === "interaction_required" ||
        error?.error === "invalid_grant"
      ) {
        if (error?.errorCode === "multiple_matching_tokens") {
          localStorage.clear();
        }
        showTokenExpiredError = true;
      } else {
        console.log("Unknown Error", error.toString());
      }
      if (showNetworkError) {
        setNetworkErrorModal(true);
      }
      if (showTokenExpiredError) {
        setTokenExpiredModal(true);
      }
      return null;
    }
  };

  const onRelogin = () => {
    instance.loginRedirect(loginRequest).catch((e: Error) => console.error(e));
  };

  const defineInterceptors = () => {
    axios.interceptors.request.use(async (config) => {
      const token = await requestAccessToken();
      if (config.headers) config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, status, headers, data } = error.response;
        const dataMessage = data?.detail;
        const isNetworkError: boolean = error?.message === "Network Error";
        if (isNetworkError) setNetworkErrorModal(true);
        if (!isNetworkError && status !== 400 && status !== 429) {
          const correlationId = headers["x-correlation-id"];
          const jsonAlerts = sessionStorage.getItem("alerts");
          const alerts: IAlert[] = jsonAlerts && (JSON.parse(jsonAlerts) || []);
          const alert = {
            errorCode: `${status}`,
            message: error.message,
            correlationId,
            time: format(new Date(), "HH:mm"),
            date: format(new Date(), "dd/MM/yyyy"),
          };
          sessionStorage.setItem("alerts", JSON.stringify([alert, ...(alerts || [])]));
        }
        // if (!isNetworkError && status === 400) {
        //   const unhandledError = !Object.keys(loc.badOperations).includes(dataMessage);
        //   // console.log(unhandledError, data, dataMessage, loc.badOperations);
        //   if (unhandledError) {
        //     console.log("TRACE CALLING HERE", config, trackTrace);
        //     trackTrace(user, `Unhadled error 400: ${dataMessage}`, {
        //       page: window.location.pathname,
        //       request: config.url,
        //       correlationId: data.CorrelationId,
        //     });
        //   } else {
        //     const resultMessage = data?.result?.message;
        //     const unhandledResultError = !Object.keys(badOperations).includes(dataMessage);
        //     if (unhandledResultError) {
        //       trackTrace(user, `Unhadled error 400: ${dataMessage}`, {
        //         page: window.location.pathname,
        //         request: config.url,
        //       });
        //     }
        //   }
        // }
        if (!isNetworkError && status === 500) {
          trackTrace(user, dataMessage, { page: window.location.pathname, request: config.url });
        }
        throw error;
      },
    );
  };

  useEffect(() => {
    defineInterceptors();
    getUserAccessRequest();
    loc.setLanguage(localStorage.getItem("appLanguage") || AppLanguage.Russian);
  }, []);
  return (
    <AuthContext.Provider
      value={{
        name,
        user,
        tokenExpiredModal,
        setTokenExpiredModal,
        networkErrorModal,
        setNetworkErrorModal,
        permissions,
        isAuthenticated,
        requestAccessToken,
        onRelogin,
        handleLogout,
        company,
        setCompany,
      }}
    >
      {isAuthenticated && !accessDenied ? (
        props.children
      ) : (
        <div className="error-page">
          <Navbar />
          {error.length ? (
            <div className="error-page-content">{error}</div>
          ) : (
            <Loading isLoading>
              <div className="error-page-content"></div>
            </Loading>
          )}
          {tokenExpiredModal && (
            <TokenExpiredModal
              onRelogin={onRelogin}
              handleLogout={handleLogout}
              onDismiss={() => setTokenExpiredModal(false)}
            />
          )}
        </div>
      )}
    </AuthContext.Provider>
  );
};
