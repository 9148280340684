import loc from "localization";
import { ViewDropdown } from "../ViewDropdown";
import { SERVICE_PATH_FULL } from "constants/paths";

export enum ServicesViewOption {
  All = "",
  RequiresAgreement = "/approval-required",
  Expired = "/expired",
  DefectsNotEntered = "/defects-not-entered",
  NotAWarranty = "/not-a-warranty",
  CostConfirmed = "/cost-confirmed",
  DevicesApprovedToWorkshop = "/devices-approved",
  RepairRequired = "/repair-required",
  RequestConfirmation = "/request-confirmation",
  ReplacementToNew = "/replacement-to-new"
}

export const getViewOptions = () => [{
  key: ServicesViewOption.All,
  text: loc.main.all
}, {
  key: ServicesViewOption.RequiresAgreement,
  text: loc.labels.requiresAgreement
}, {
  key: ServicesViewOption.Expired,
  text: loc.labels.expired
}, {
  key: ServicesViewOption.DefectsNotEntered,
  text: loc.labels.defectsNotEntered
},{
  key: ServicesViewOption.NotAWarranty,
  text: loc.labels.notAWarranty
},{
  key: ServicesViewOption.CostConfirmed,
  text: loc.labels.costConfirmed
},
{
  key: ServicesViewOption.DevicesApprovedToWorkshop,
  text: loc.labels.devicesApprovedToWorkshop
},{
  key: ServicesViewOption.RepairRequired,
  text: loc.labels.approvedNotPassed
},{
  key: ServicesViewOption.RequestConfirmation,
  text: loc.labels.requestClarifications
},
{
  key: ServicesViewOption.ReplacementToNew,
  text: loc.labels.replacementToNew
}];

interface IServiceViewDropdownProps {
  currentView: ServicesViewOption;
}

export const ServiceViewDropdown = (props: IServiceViewDropdownProps) => {
  return (
    <ViewDropdown
      fullPath={SERVICE_PATH_FULL}
      viewOptions={getViewOptions()}
      currentView={props.currentView}
    />
  );
};