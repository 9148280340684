import { useApi, useHttp, useSelection } from "hooks";
import { getActions, getColumns } from "./config";
import { useContext, useEffect, useState } from "react";
import { AppLanguage, CommandbarCommands } from "constants/enums";
import { BasicPanelActions, BasicPanelContent, BasicPanelDetails, BasicPanelFilter } from "components/common/BasicPanel/components";
import { Table } from "components/common/Table";
import { BasicPanel } from "components/common/BasicPanel";
import { AuthContext } from "context/authContext";
import { companyOptions } from "constants/options";
import { find } from "lodash-es";
import { UserBrowseDto, UserPermissions } from "generated-sources/openapi";
import { BasicCheckbox } from "components/common/BasicCheckbox";
import { SelectionMode, Stack } from "@fluentui/react";
import styles from "./styles.module.scss";
import { downloadFile, getLcid, namedSuccessNotification } from "utils";
import { CommandWrapper } from "components/common/CommandWrapper";
import { DeleteModal } from "./DeleteModal";
import { SelectLanguageModal } from "components/common/SelectLanguageModal";
import loc from "localization";
import { AxiosResponse } from "axios";
import { AddModal } from "./AddModal";

interface IRoleManagementModalProps {
  onDismiss: () => void;
}

export const RoleManagementModal = (props: IRoleManagementModalProps) => {
  const { isLoading, request, errorMessages } = useHttp<UserBrowseDto[]>();
  const permissionHttp = useHttp({ showErrors: true });
  const nameHttp = useHttp({ showErrors: true });
  const excelHttp = useHttp();
  const { selectedItem, selection } = useSelection<UserBrowseDto>();
  const [activeCommand, setActiveCommand] = useState<CommandbarCommands>(CommandbarCommands.None);
  const actions = getActions(selectedItem);
  const [items, setItems] = useState<UserBrowseDto[]>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isDeletedChecked, setIsDeletedChecked] = useState(false);
  const { settingsUsersApi, userDirectoryApi } = useApi();
  const { company } = useContext(AuthContext);
  const userCompanyId = Number(find(companyOptions, { text: company })?.key);
  const handleModalDismiss = () => setActiveCommand(CommandbarCommands.None);

  const changePermission = (tn: string | undefined, field: keyof UserPermissions, value: boolean) => {
    setItems(items.map(el => {
      if (el.tn === tn) {
        return {
          ...el,
          permissions: {
            ...el.permissions,
            [field]: value
          }
        };
      } else {
        return el;
      }
    }));
  };

  const onCheckboxChange = (item: UserBrowseDto, field: keyof UserPermissions, value: boolean) => {
    permissionHttp.request(() => settingsUsersApi.apiSettingsUsersSetPermissionsPut({
      updateUserPermissionsPayload: {
        tn: item.tn,
        ...item.permissions,
        [field]: value
      }
    })).then(res => {
      namedSuccessNotification(res, loc.notifications.changesSaved);
      changePermission(item.tn, field, value);
    });
  };

  const changeLastName = (tn: string | undefined, newLastName: string) => {
    setItems(items.map(el => {
      if (el.tn === tn) {
        return {
          ...el,
          lastName: newLastName
        };
      } else {
        return el;
      }
    }));
  };

  const changeFirstName = (tn: string | undefined, newFirstName: string) => {
    setItems(items.map(el => {
      if (el.tn === tn) {
        return {
          ...el,
          firstName: newFirstName
        };
      } else {
        return el;
      }
    }));
  };

  const onLastNameBlur = (item: UserBrowseDto, value: string) => {
    nameHttp.request(() => userDirectoryApi.apiUserDirectoryUsersTnSetUsernamePost({
      tn: item.tn || "",
      setUserNamePayload: {
        firstName: item.firstName,
        lastName: value
      }
    })).then(res => {
      namedSuccessNotification(res, "");
      changeLastName(item.tn, value);
    });
  };

  const onFirstNameBlur = (item: UserBrowseDto, value: string) => {
    nameHttp.request(() => userDirectoryApi.apiUserDirectoryUsersTnSetUsernamePost({
      tn: item.tn || "",
      setUserNamePayload: {
        firstName: value,
        lastName: item.lastName
      }
    })).then(res => {
      namedSuccessNotification(res, "");
      changeFirstName(item.tn, value);
    });
  };

  const columns = getColumns(
    onCheckboxChange,
    permissionHttp.isLoading,
    onLastNameBlur,
    onFirstNameBlur,
    nameHttp.isLoading
  );

  const loadItems = () => {
    request(() => settingsUsersApi.apiSettingsUsersBrowsePost({
      browseUsersPayload: {
        firmId: userCompanyId,
        allFirms: isAllChecked,
        includeDeleted: isDeletedChecked
      }
    })).then(res => {
      setItems(res.data);
    });
  };

  const exportExcel = (language: AppLanguage) => {
    excelHttp.request(() => userDirectoryApi.apiUserDirectoryExportExcelPost({
      lcid: getLcid(language)
    }, { responseType: "blob" })).then((res: AxiosResponse) => {
      downloadFile(res);
      handleModalDismiss();
    });
  };

  useEffect(() => {
    loadItems();
  }, [isAllChecked, isDeletedChecked]);

  return (
    <BasicPanel
      isLoading={isLoading}
      title={loc.settings.roleManagement}
      dismissButtonText={loc.buttons.close}
      onDismiss={props.onDismiss}
      errors={errorMessages}
    >
      <Stack horizontal>
        <BasicPanelActions actions={actions} setActiveCommand={setActiveCommand} />
        <BasicPanelFilter className={styles.panelFilter}>
          <BasicCheckbox label={loc.labels.allCompanies} checked={isAllChecked} onChange={setIsAllChecked}/>
          <BasicCheckbox label={loc.labels.showDeleted} checked={isDeletedChecked} onChange={setIsDeletedChecked}/>
        </BasicPanelFilter>
      </Stack>
      <BasicPanelContent>
        <BasicPanelDetails noData={!items.length}>
          <Table
            columns={columns}
            items={items}
            selection={selection}
            selectionMode={SelectionMode.single}
          />
        </BasicPanelDetails>
      </BasicPanelContent>
      <CommandWrapper isActive={activeCommand === CommandbarCommands.Add}>
        <AddModal
          onSuccess={loadItems}
          onDismiss={handleModalDismiss}
        />
      </CommandWrapper>
      <CommandWrapper isActive={activeCommand === CommandbarCommands.Delete}>
        <DeleteModal
          tn={selectedItem?.tn || ""}
          onDismiss={handleModalDismiss}
          onSuccess={loadItems}
        />
      </CommandWrapper>
      <CommandWrapper isActive={activeCommand === CommandbarCommands.Excel}>
        <SelectLanguageModal
          isLoading={excelHttp.isLoading}
          errorMessages={excelHttp.errorMessages}
          onSubmit={exportExcel}
          onDismiss={handleModalDismiss}
        />
      </CommandWrapper>
    </BasicPanel>
  );
};