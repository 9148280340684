import { FontIcon, TooltipHost } from "@fluentui/react";
import { AppLanguage } from "constants/enums";
import loc from "localization";
import { IDeviceWork } from ".";

export const getColumns = (language: AppLanguage, collapseDefect: (workId?: number) => void) => [
  {
    key: "collapse",
    name: "",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
    onRender: (item: IDeviceWork) => {
      const onIconClick = () => collapseDefect(item.workId);
      return item.isParent ? (
        <div className="cell-action-btn">
          <FontIcon
            iconName={item.isCollapsed ? "ChevronDownSmall" : "ChevronUpSmall"}
            onClick={onIconClick}
            style={{ marginLeft: item.level * 20 }}
          />
        </div>
      ) : null;
    },
  },
  {
    key: "code",
    name: loc.columns.code,
    fieldName: "code",
    minWidth: 70,
    maxWidth: 70,
    isResizable: true,
  },
  {
    key: `name${language === AppLanguage.Russian ? "Ru" : "En"}`,
    name: loc.columns.name,
    fieldName: `name${language === AppLanguage.Russian ? "Ru" : "En"}`,
    minWidth: 300,
    maxWidth: 300,
    isResizable: true,
    onRender: (item: IDeviceWork) => (
      <TooltipHost content={`${language === AppLanguage.Russian ? item.nameRu : item.nameEn}`}>
        {language === AppLanguage.Russian ? item.nameRu : item.nameEn}
      </TooltipHost>
    )
  },
  {
    key: "notes",
    name: loc.columns.notes,
    fieldName: "notes",
    minWidth: 120,
    maxWidth: 120,
    isResizable: true,
  },
];
