import classNames from "classnames";
import { isUndefined } from "lodash";
import { PanelTab } from "constants/enums";
import styles from "./styles.module.scss";

export interface IPanelTabsProps {
  tab: PanelTab;
  setTab: (tab: PanelTab) => void;
  selectedCount?: number;
  mainTabTitle: string;
  otherTabTitle: string;
  // itemsHasEmptyValues?: boolean;
}

export const PanelTabs = ({
  tab,
  setTab,
  selectedCount,
  mainTabTitle,
  otherTabTitle,
}: IPanelTabsProps) => {
  return (
    <div className={styles.panelTabs}>
      <div
        className={classNames(styles.panelTabsTab, {
          [styles.panelTabsTabActive]: tab === PanelTab.Main,
        })}
        onClick={() => setTab(PanelTab.Main)}
      >
        {mainTabTitle}
      </div>
      <div
        className={classNames(styles.panelTabsTab, {
          [styles.panelTabsTabActive]: tab === PanelTab.Other,
        })}
        onClick={() => setTab(PanelTab.Other)}
      >
        {otherTabTitle} {!isUndefined(selectedCount) && `(${selectedCount})`}
        {/* {itemsHasEmptyValues ? <span className="warning-tab">!</span> : null} */}
      </div>
    </div>
  );
};
