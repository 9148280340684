import { AxiosResponse } from "axios";
import { find } from "lodash-es";
import { decode } from "js-base64";
import { AppLanguage, Result } from "constants/enums";
import { LockedDeviceBrowseDto } from "generated-sources/openapi";

export const getQuestionResult = (value: string) => {
  if (value === Result.Yes) return true;
  if (value === Result.No) return false;
};

export const getLcid = (language: AppLanguage) => {
  return language === AppLanguage.Russian ? 1049 : 1033;
};

export const downloadFile = (response: AxiosResponse, fileName?: string, format?: string) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  const decodedFileName = decode(response.headers["content-disposition"].split("filename=")[1]);
  const name = decodedFileName || `${fileName}.${format || "xlsx"}`;
  link.href = url;
  link.download = name;
  // document.body.appendChild(link);
  link.click();
};

export const getPdf = (response: AxiosResponse) => {
  const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
  const iframe = document.createElement("iframe");
  document.body.appendChild(iframe);
  iframe.style.display = "none";
  iframe.src = url;
  iframe.onload = function () {
    setTimeout(function () {
      iframe.focus();
      iframe.contentWindow?.print();
    }, 0);
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkItemIsLocked = (lockedDevices: LockedDeviceBrowseDto[], item: any) => {
  const itemIsLocked = !!find(lockedDevices, {
    deviceId: item.deviceId,
    firmId: item.firmId,
  });
  return itemIsLocked;
};
