import { useContext, useState, useEffect, useMemo } from "react";
import { AxiosResponse } from "axios";
import { IDetailsRowProps, IRenderFunction, ScrollablePane, SelectionMode } from "@fluentui/react";
import { filter, find, findIndex, forEach, includes, isUndefined, map, reduce, uniq } from "lodash-es";
import { useApi, useHttp, useMultiSelection } from "hooks";
import { filterArrayByFields } from "utils";
import { LanguageContext } from "context/languageContext";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { AppLanguage } from "constants/enums";
import { Table } from "components/common/Table";
import { BasicInput } from "components/common/BasicInput";
import { IDefectGroup } from "components/pages/DefectsPage/types";
import { getFilteredDefectNumbers, getGroupedDefects, modifyDefects } from "components/pages/DefectsPage/helpers";
import { DefectBrowseDto } from "generated-sources/openapi";
import { getColumns } from "./config";
import styles from "./styles.module.scss";

export interface IAddDefectModalProps {
  basicPayload: { firmId: number; deviceId: number };
  updateState: () => void;
  onDismiss: () => void;
}

export const AddDefectModal = (props: IAddDefectModalProps) => {
  const { devicesApi, defectsApi } = useApi();
  const devicesHttp = useHttp();
  const defectsHttp = useHttp<DefectBrowseDto[]>();
  const { loc, language } = useContext(LanguageContext);
  const [defects, setDefects] = useState<IDefectGroup[]>([]);
  const [selectedItemsIds, setSelectedItemsIds] = useState<number[]>([]);
  const [codeValue, setCodeValue] = useState<string>("");
  const [nameValue, setNameValue] = useState<string>("");
  const items = useMemo(() => {
    const groupedDefects = getGroupedDefects(defects);
    const lang = language === AppLanguage.Russian ? "R" : "E";
    if (nameValue || codeValue) {
      const defectNumbers = map(
        filterArrayByFields(defects, [
          { value: codeValue, fieldValues: ["defectNumber"] },
          { value: nameValue, fieldValues: [`name${lang}`, `blameName${lang}`, "notes"] },
        ]),
        (item: IDefectGroup) => `${item.defectNumber}`,
      );
      const allDefectNumbers = getFilteredDefectNumbers(defectNumbers);
      return filter(defects, defect => includes(allDefectNumbers, defect.defectNumber));
    }
    return groupedDefects;
  }, [defects, codeValue, nameValue, language]);
  const { selection, selectedItems } = useMultiSelection<IDefectGroup>(items, "defectId", true);
  const submitHandler = () => {
    const defectIds = map(reduce(selectedItems, (prev: IDefectGroup[], next: IDefectGroup) => [
      ...prev,
      ...filter(defects, defect => Boolean(
        defect?.defectNumber?.startsWith(`${next.defectNumber}`)
        && !defect?.isParent
      ))
    ], []), item => Number(item.defectId));
    devicesHttp.request(() =>
      devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdDefectsPost({
        ...props.basicPayload,
        deviceAddDefectsPayload: { defectIds },
      }),
    )
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          props.updateState();
          props.onDismiss();
        };
      });
  };

  const collapseDefect = (defectId?: number) => {
    setDefects((defects) =>
      map(defects, (defect) =>
        defect.defectId === defectId ? { ...defect, isCollapsed: !defect.isCollapsed } : defect,
      ),
    );
  };

  const loadDefects = () => {
    defectsHttp
      .request(() => defectsApi.apiDictDefectsBrowseGet())
      .then((res) => setDefects(modifyDefects(res.data)));
  };

  useEffect(() => {
    loadDefects();
  }, []);

  const columns = useMemo(() => getColumns(language), [language]);

  useEffect(() => {
    selectedItems.length + 1 !== selectedItemsIds.length && setSelectedItemsIds(
      uniq([
        ...selectedItemsIds,
        ...map(selectedItems, item => !includes(selectedItemsIds, item.defectId) ? Number(item.defectId) : 0)
      ])
    );
  }, [selectedItems]);

  useEffect(() => {
    if (selectedItemsIds.length >= selectedItems.length) {
      forEach(selectedItemsIds, (id) => {
        const item = find(defects, (item: IDefectGroup) => item.defectId === id);
        const index = !isUndefined(item) ? findIndex(items, item) : -1;
        index !== -1 && selection.setIndexSelected(index, true, true);
      });
    };
  }, [items.length]);

  const onRenderRow = (props?: IDetailsRowProps, render?: IRenderFunction<IDetailsRowProps>) => {
    const properties = { ...props };
    return (
      <div
        className={properties?.item?.isParent ? styles.parentRow : ""}
        onClick={() => {
          /* eslint-disable react/prop-types */
          !props?.item.isParent && setSelectedItemsIds(filter(selectedItemsIds, item => item !== props?.item.defectId));
        }}>
        {props && render && render(props)}
      </div>
    );
  };

  useEffect(() => {
    if (!selection.isAllSelected()) setSelectedItemsIds([]);
  }, [selection.isAllSelected()]);


  return (
    <BasicModal
      isDraggable
      additionalClassName={styles.addDefectModal}
      isLoading={devicesHttp.isLoading || defectsHttp.isLoading}
      title={loc.defectsPage.addDefectTitle}
      errors={devicesHttp.errorMessages}
      submitButtonText={loc.buttons.add}
      dismissButtonText={loc.buttons.cancel}
      onSubmit={submitHandler}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <InfoContainer>
          <div className="flex-row">
            <BasicInput
              className="code-field"
              value={codeValue}
              onChange={setCodeValue}
              placeholder={loc.columns.code}
              reset
              styles={{ marginRight: 20 }}
            />
            <BasicInput
              className="name-field"
              value={nameValue}
              onChange={setNameValue}
              placeholder={loc.main.searchPlaceholder}
              reset
            />
            <div className="total-count">
              {loc.main.selected} {filter(selectedItemsIds, item => item).length}
            </div>
          </div>
        </InfoContainer>
        <div className="scrollable-table-wrapper">
          <ScrollablePane>
            <Table
              items={items}
              columns={columns}
              selection={selection}
              selectionMode={SelectionMode.multiple}
              onItemInvoked={((item?: IDefectGroup) => collapseDefect(item?.defectId))}
              onItemInvokedSaveSelection
              isSelectedOnFocus={false}
              selectionIsVisible
              onRenderRow={onRenderRow}
            />
          </ScrollablePane>
        </div>
      </BasicModalContent>
    </BasicModal>
  );
};
