import { useContext, useState, useEffect, useMemo } from "react";
import { map } from "lodash-es";
import { AxiosResponse } from "axios";
import { SelectionMode } from "@fluentui/react";
import { useApi, useDebounce, useHttp, useMultiSelection } from "hooks";
import { LanguageContext } from "context/languageContext";
import { BasicModal, BasicModalContent } from "components/common/BasicModal";
import { InfoContainer } from "components/common/InfoContainer";
import { CodeCeInput } from "components/common/CodeCeInput";
import { ScrollableTableWrapper, Table } from "components/common/Table";
import { ReplacementCEDetailsBrowseDto } from "generated-sources/openapi";
import { getColumns } from "./config";
import styles from "../../AddDefectModal/styles.module.scss";

export interface IDeviceDetailsModalProps {
  se: string;
  request: (items: ReplacementCEDetailsBrowseDto[]) => Promise<AxiosResponse>;
  updateState: () => void;
  onDismiss: () => void;
}

export const DeviceDetailsModal = (props: IDeviceDetailsModalProps) => {
  const { devicesApi } = useApi();
  const devicesHttp = useHttp<ReplacementCEDetailsBrowseDto[]>();
  const deviceHttp = useHttp();
  const { loc, language } = useContext(LanguageContext);
  const [devices, setDevices] = useState<ReplacementCEDetailsBrowseDto[]>([]);
  const { selection, selectedItems } = useMultiSelection<ReplacementCEDetailsBrowseDto>(devices, "se");
  const [searchCodeSeValue, setSearchCodeSeValue] = useState<string>("");
  const debouncedSearchCodeSeValue = useDebounce(searchCodeSeValue, 1000);

  const onChange = (item: ReplacementCEDetailsBrowseDto) => {
    setDevices(devices => map(devices, device => device.se === item.se ? item : device));
  };

  const submitHandler = (items: ReplacementCEDetailsBrowseDto[]) => {
    devicesHttp.request(() => props.request(items))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          props.updateState();
        };
      });
  };

  const loadData = () => {
    devicesHttp.request(() => devicesApi.apiOpDevicesReplacedCeSeDetailsGet({ se: props.se }))
      .then(res => setDevices(res.data));
  };

  useEffect(() => {
    loadData();
  }, [debouncedSearchCodeSeValue]);

  const columns = useMemo(() => getColumns(language, onChange), [language]);

  return (
    <BasicModal
      additionalClassName={styles.addDefectModal}
      isLoading={devicesHttp.isLoading || deviceHttp.isLoading}
      title={loc.labels.selectDevices}
      errors={deviceHttp?.errorMessages?.length ? deviceHttp.errorMessages : devicesHttp.errorMessages}
      submitButtonText={loc.buttons.save}
      dismissButtonText={loc.buttons.cancel}
      onSubmit={() => submitHandler(selectedItems)}
      onDismiss={props.onDismiss}
    >
      <BasicModalContent>
        <InfoContainer>
          <CodeCeInput
            className="code-field"
            value={searchCodeSeValue}
            onChange={setSearchCodeSeValue}
            placeholder={loc.columns.se}
            reset
          />
        </InfoContainer>
        <div className="scrollable-warapper">
          <ScrollableTableWrapper noData={!devices?.length}>
            <Table
              items={devices}
              columns={columns}
              selection={selection}
              selectionMode={SelectionMode.multiple}
            />
          </ScrollableTableWrapper>
        </div>
      </BasicModalContent>
    </BasicModal>
  );
};
