import loc from "localization";
import { ConfirmModal } from "../ConfirmModal";
import { DefaultButton, PrimaryButton } from "@fluentui/react";

interface ITokenExpiredModalProps {
  onRelogin: () => void;
  handleLogout: () => void;
  onDismiss: () => void;
}

export const TokenExpiredModal = (props: ITokenExpiredModalProps) => {
  return (
    <ConfirmModal
      text={loc.tokenExpiredModal.message}
      leftButton={<PrimaryButton text={loc.buttons.login} onClick={props.onRelogin} />}
      rightButton={<DefaultButton text={loc.buttons.logout} onClick={props.handleLogout} />}
      onDismiss={props.onDismiss}
    />
  );
};