import { useEffect, useState } from "react";
import { BasicInput } from "../BasicInput";

interface IUncontrolledValueInputProps {
  value: string;
  type?: string;
  onBlur?: (value: string) => void;
  name?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  reset?: boolean;
  maxLength?: number;
  multiline?: boolean;
}

export const UncontrolledValueInput = (props: IUncontrolledValueInputProps) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <BasicInput
      value={value}
      onChange={setValue}
      className={props.className}
      disabled={props.disabled}
      errorMessage={props.errorMessage}
      maxLength={props.maxLength}
      multiline={props.multiline}
      name={props.name}
      onBlur={() => props.onBlur && props.onBlur(value)}
      placeholder={props.placeholder}
      reset={props.reset}
      type={props.type}
    />
  );
};