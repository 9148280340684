import { getSortingColumnHeader } from "components/common/SortingColumnHeader";
import { ExpiringLicenseDto } from "generated-sources/openapi";
import { ISorting } from "hooks/useSorting";
import loc from "localization";
import { transformDateToString } from "utils";

export const getColumns = (sorting: ISorting) => [
  {
    key: "licenseNumber",
    name: loc.columns.licenseNumber,
    fieldName: "licenseNumber",
    isResizable: true,
    minWidth: 140,
    maxWidth: 150,
    onRenderHeader: getSortingColumnHeader(sorting)
  },
  {
    key: "counterparty",
    name: loc.columns.counterparty,
    fieldName: "counterparty",
    isResizable: true,
    minWidth: 100,
    maxWidth: 120,
    onRenderHeader: getSortingColumnHeader(sorting)
  },
  {
    key: "expiringDate",
    name: loc.columns.validUntil,
    minWidth: 100,
    maxWidth: 120,
    fieldName: "expiringDate",
    isResizable: true,
    onRender: (item: ExpiringLicenseDto) => {
      return transformDateToString(item.expiringDate);
    },
    onRenderHeader: getSortingColumnHeader(sorting)
  }
];
