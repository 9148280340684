import { useContext } from "react";
import { LanguageContext } from "context/languageContext";
import { PageWrapperContent } from "components/common/PageWrapper/Content";

export const NotFoundPage = () => {
  const { loc } = useContext(LanguageContext);
  return (
    <>
      <PageWrapperContent>
        <h5>{loc.warnings.pageNotFound}</h5>
      </PageWrapperContent>
    </>
  );
};
