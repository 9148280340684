import { lastDayOfMonth, startOfMonth } from "date-fns";
import { forEach } from "lodash-es";
import { transformDateToString } from "./dateTransformes";

const localStorageKeysForRemove = [
  "customersInfoFilter", "invoicesPageFilters", "defectsFilters",
  "devicesPageFilters", "maintainabilityFilters", "serialNumPageFilters", "requestsRMAPageFilters",
  "permissionsRMApageFilters", "servicePageFilter", "repairPlanFilters", "workshopFilters"
];

export const removeLocalStorageItems = () => {
  forEach(localStorageKeysForRemove, item => {
    localStorage.removeItem(item);
  });
  const updatedGlobalFilter = {
    period: {
      dateFrom: transformDateToString(startOfMonth(new Date())), 
      dateTo:transformDateToString(lastDayOfMonth(new Date()))
    },
    counterpartyId: 0,
  };
  localStorage.setItem("globalFilter", JSON.stringify(updatedGlobalFilter));
};