import { DefaultButton } from "@fluentui/react";
import { useState } from "react";
import loc from "localization";
import { BasicInput } from "components/common/BasicInput";
import { BasicModalColumn, BasicModalColumns } from "components/common/BasicModal";

interface ICustomerCommunicationProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  updateDeviceInfo: (payload?: unknown) => void;
}

export const CustomerCommunication = ({ formik, updateDeviceInfo }: ICustomerCommunicationProps) => {
  const [tableIsOpen, setTableIsOpen] = useState<boolean>(false);
  
  return (
    <div>
      <div className="table-title">
        <div>
          <DefaultButton
            className="show-table"
            iconProps={{ iconName: "Send" }}
            onClick={() => setTableIsOpen(!tableIsOpen)}
          />
          <b onClick={() => setTableIsOpen(!tableIsOpen)}>{loc.deviceModal.customerCommunication}</b>
        </div>
      </div>
      {tableIsOpen ? (
        <BasicModalColumns>
          <BasicModalColumn>
            <div style={{ marginTop: 28 }}>
              <b>{loc.deviceModal.customerCommunication}</b>
              <BasicInput
                multiline
                name={"customerCommunication"}
                value={formik.values.customerCommunication}
                onChange={(value: string) => formik.setFieldValue("customerCommunication", value)}
                onBlur={updateDeviceInfo}
                errorMessage={formik.errors.customerCommunication}
                maxLength={1000}
              />
            </div>
          </BasicModalColumn>
        </BasicModalColumns>
      ) : null}
    </div>
  );
};