import { TooltipHost } from "@fluentui/react";
import { AppLanguage } from "constants/enums";
import { DeviceWorklistDto } from "generated-sources/openapi";
import loc from "localization";

export const getColumns = (language: AppLanguage) => [
  {
    key: "nameRu",
    name: loc.labels.typicalWorks,
    fieldName: language === AppLanguage.Russian ? "nameRu" : "nameEn",
    minWidth: 300,
    maxWidth: 300,
    isResizable: true,
    onRender: (item: DeviceWorklistDto) => (
      <TooltipHost content={language === AppLanguage.Russian ? item.nameRu : item.nameEn}>
        {language === AppLanguage.Russian ? item.nameRu : item.nameEn}
      </TooltipHost>
    ),
  },
];
