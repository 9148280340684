import { Store } from "react-notifications-component";
import { Notification } from "constants/enums";
import loc from "localization";

export const getNotification = (title: string, message: string, type: Notification) =>
  Store.addNotification({
    title,
    message,
    type,
    insert: "top",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });

export const createSuccessNotification = (res: { status: number }, message?: string) => {
  const text = message || "Item was created successfully";
  if (res.status === 200) {
    getNotification("Success", text, Notification.SUCCESS);
  }
};

export const editSuccessNotification = (res: { status: number }, message?: string) => {
  const text = message || "Item was edited successfully";
  if (res.status === 200) {
    getNotification("Success", text, Notification.SUCCESS);
  }
};

export const deleteSuccessNotification = (res: { status: number }, message?: string) => {
  const text = message || "Item was deleted successfully";
  if (res.status === 200) {
    getNotification("Success", text, Notification.SUCCESS);
  }
};

export const acceptSuccessNotification = (res: { status: number }, message?: string) => {
  const text = message || loc.notifications.acceptSuccessfuly;
  if (res.status === 200) {
    getNotification("Success", text, Notification.SUCCESS);
  }
};

export const errorNotification = (err: Error) => {
  getNotification("Error", err.message, Notification.ERROR);
};

export const uploadSuccessNotification = (res: { status: number }, message?: string) => {
  const text = message || loc.notifications.successUpload;
  if (res.status === 200) {
    getNotification("Success", text, Notification.SUCCESS);
  }
};

export const namedErrorNotification = (res: { status: number }, message: string) => {
  if (res.status === 200) {
    getNotification("Error", message, Notification.ERROR);
  }
};

export const namedSuccessNotification = (res: { status: number }, message: string) => {
  const text = message;
  if (res.status === 200) {
    getNotification("Success", text, Notification.SUCCESS);
  }
};

export const rejectSuccessNotification = (res: { status: number }, message?: string) => {
  const text = message || loc.notifications.reject;
  if (res.status === 200) {
    getNotification("Success", text, Notification.SUCCESS);
  }
};

export const warningNotification = (res: { status: number }, message?: string) => {
  const text = message || "";
  if (res.status === 200) {
    getNotification("Warning", text, Notification.WARNING);
  };
};

export const noDataErrorNotification = (res: { status: number }, message: string) => {
  const text = message;
  if (res.status === 204) {
    getNotification("Success", text, Notification.ERROR);
  }
};

