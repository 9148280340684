import React, { ReactNode } from "react";
import { AppInsightsContext } from "./appInsightsContext";

interface IErrorBoundaryProps {
  forceReload?: boolean;
  children: JSX.Element | ReactNode;
}

interface IErrorBoundaryState {
  hasError: boolean;
  error: string;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  static contextType = AppInsightsContext;

  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error: error?.toString() };
  }

  componentDidCatch() {
    if (this.props.forceReload) window.location.reload();
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { trackTrace } = this.context as any;
    if (this.state.hasError) {
      trackTrace(null, `${this.state.error} on ${window.location.pathname}`, { user: "" });
      return <h5 style={{ marginLeft: 20 }}>{this.state.error}</h5>;
    }
    return this.props.children;
  }
}
