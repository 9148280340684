import React, { FormEvent, useMemo, useState } from "react";
import { isString, isUndefined, filter, find } from "lodash-es";
import classNames from "classnames";
import {
  Callout,
  ComboBox,
  DefaultButton,
  FocusZone,
  FocusZoneDirection,
  FontIcon,
  IComboBox,
  IComboBoxOption,
  IRenderFunction,
  ISelectableDroppableTextProps,
  TextField,
  getId,
} from "@fluentui/react";
import loc from "localization";
import globalStyles from "styles/common.module.scss";

interface IBasicComboBoxProps {
  className?: string;
  options: IComboBoxOption[];
  itemKey: number | string;
  defaultPlaceholder?: string;
  onChange: (e?: FormEvent<IComboBox>, val?: IComboBoxOption) => void;
  disabled?: boolean;
  errorMessage?: string;
  startsWithFilter?: boolean;
  onRenderOption?: (item?: IComboBoxOption, render?: IRenderFunction<IComboBoxOption>) => JSX.Element | null;
  onRenderItem?: (item?: IComboBoxOption, render?: IRenderFunction<IComboBoxOption>) => JSX.Element | null;
  globalFilter?: boolean;
}

export const BasicComboBox = (props: IBasicComboBoxProps) => {
  const id = useMemo(() => getId(), []);
  const [searchValue, setSearchValue] = useState<string>("");
  const getOptions = (options: IComboBoxOption[]) =>
    searchValue.length
      ? props.startsWithFilter
        ? filter(options, (item) => item.text.toLowerCase().startsWith(searchValue?.toLowerCase()))
        : filter(options, (item) => item.text.toLowerCase().includes(searchValue?.toLowerCase()))
      : options;
  const comboBoxTitle = find(props.options, { key: props.itemKey })?.text;
  const onClearClick = () => {
    setSearchValue("");
    props.onChange(undefined, {
      key: isString(props.itemKey) ? "" : 0,
      text: "",
    });
  };

  const onRenderContainer = (containerProps?: ISelectableDroppableTextProps<IComboBox, IComboBox>): JSX.Element => (
    <Callout
      isBeakVisible={false}
      target={`#${id}`}
      onDismiss={containerProps?.onDismiss}
      setInitialFocus
    >
      <FocusZone direction={FocusZoneDirection.vertical}>
        <div className="upper-content">
          <TextField
            value={searchValue}
            onChange={(_: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) =>
              !isUndefined(value) && setSearchValue(value)
            }
            autoFocus
            onRenderPrefix={() => <FontIcon iconName="search" />}
            placeholder={loc.main.searchPlaceholder}
          />
        </div>
        {containerProps?.onRenderList && containerProps?.onRenderList(containerProps)}
        <div className="lower-content">
          <DefaultButton
            iconProps={{ iconName: "ChromeClose" }}
            onClick={onClearClick}
            disabled={!(props.itemKey || searchValue.length)}
          >
            {loc.buttons.clear}
          </DefaultButton>
        </div>
      </FocusZone>
    </Callout>
  );

  return (
    <ComboBox
      className={classNames({[globalStyles.filterIsGlobal]: props.globalFilter}, props.className)}
      id={id}
      text={comboBoxTitle}
      selectedKey={props.itemKey}
      placeholder={props.defaultPlaceholder}
      options={getOptions(props.options)}
      onChange={props.onChange}
      disabled={props.disabled}
      errorMessage={props.errorMessage}
      onRenderContainer={onRenderContainer}
      onRenderOption={props.onRenderOption}
      onRenderItem={props.onRenderItem}
      autoComplete="off"
    />
  );
};
