import { Panel, PrimaryButton, DefaultButton, PanelType, IPanelProps, IRenderFunction } from "@fluentui/react";
import { CommandbarCommands, PanelTab } from "constants/enums";
import { MessageBlock } from "components/common/MessageBlock";
import { Loading } from "components/common/LoadingWrapper";
import { ICommandBarAction } from "components/common/CommandBar";
import { PanelTabs } from "components/common/PanelTabs";
import { BasicPanelContent, BasicPanelActions, BasicPanelFilter, BasicPanelDetails } from "./components";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface IBasicPanelProps {
  className?: string;
  withFilter?: boolean;
  title: string;
  children: JSX.Element | JSX.Element[];
  submitButtonText?: string;
  dismissButtonText: string;
  submitButtonDisabled?: boolean;
  paneType?: PanelType;
  isLoading: boolean;
  actions?: ICommandBarAction[];
	tab?: PanelTab;
	setTab?: (tab: PanelTab) => void;
  errors?: string | string[];
  setActiveCommand?: (command: CommandbarCommands) => void;
  onSubmit?: () => void;
  mainTabTitle?: string;
  otherTabTitle?: string;
  onDismiss: () => void;
  onRenderHeader?: IRenderFunction<IPanelProps>;
  isWarning?: boolean;
}

const BasicPanel = (props: IBasicPanelProps) => {
  const onRenderFooterContent = () => (
    <div>
      {props.submitButtonText ? (
        <PrimaryButton
          onClick={props.onSubmit}
          disabled={props.submitButtonDisabled}
        >
          {props.submitButtonText}
        </PrimaryButton>
      ) : (
        <div />
      )}
      <DefaultButton onClick={props.onDismiss}>{props.dismissButtonText}</DefaultButton>
    </div>
  );
  
  return (
    <Panel
      className={classNames(styles.basicPanel, {["with-filter"]: props.withFilter})}
      isLightDismiss
      isOpen
      onDismiss={props.onDismiss}
      headerText={props.title}
      type={props.paneType || PanelType.extraLarge}
      onRenderFooterContent={onRenderFooterContent}
      isFooterAtBottom
      onRenderHeader={props.onRenderHeader}
    >
      <Loading isLoading={props.isLoading} errors={props.errors}>
        <>
          {props.actions ? (
            <BasicPanelActions actions={props.actions} setActiveCommand={props.setActiveCommand} />
          ) : null}
          {props.tab && props.mainTabTitle && props.otherTabTitle && props.setTab ? (
            <PanelTabs
              mainTabTitle={props.mainTabTitle}
              otherTabTitle={props.otherTabTitle}
              tab={props.tab}
              setTab={props.setTab}
            />
          ) : null}
          {props.children}
        </>
      </Loading>
      {/* // @TODO MODALS */}
      {props.errors?.length ? <MessageBlock messages={props.errors} isWarning={props.isWarning}/> : null}
    </Panel>
  );
};

export { BasicPanel, BasicPanelContent, BasicPanelFilter, BasicPanelDetails };
