import { useReducer } from "react";

type StateReducer<T> = (state: T, newValues: Partial<T>) => T;

export const usePartialUpdateState = <T = unknown>(
  initailState: T,
): [T, (newValues: Partial<T>) => void] => {
  const [state, setPartialState] = useReducer<StateReducer<T>>((state, newValues) => {
    return {
      ...state,
      ...newValues,
    };
  }, initailState);

  return [state, setPartialState];
};
