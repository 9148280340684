import { ScrollablePane, Spinner, SpinnerSize } from "@fluentui/react";
import classNames from "classnames";
import loc from "localization";
import styles from "./styles.module.scss";

interface IScrollableTableWrapperProps {
  loadingMore?: boolean;
  noData: boolean;
  children: JSX.Element;
  onPanel?: boolean;
}

export const ScrollableTableWrapper = (props: IScrollableTableWrapperProps) => {
  return (
    <ScrollablePane>
      {props.children}
      {props.noData && <div className="no-data">{loc.warnings.noData}</div>}
      {props.loadingMore && (
        <div className={classNames(styles.loadingMore, {[styles.loadingMoreOnPanel]: props.onPanel}, "loading-more")}>
          <Spinner size={SpinnerSize.large} />
        </div>
      )}
    </ScrollablePane>
  );
};
