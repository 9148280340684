import { DirectionalHint, FontIcon, TooltipHost } from "@fluentui/react";
import { useContext } from "react";
import { LanguageContext } from "context/languageContext";
import { map } from "lodash-es";
import { WHITE_COLOR } from "constants/colors";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { BasicToggle } from "../BasicToggle";

export type PositionType = 0 | 1 | 2 | 3;

export interface ILegend {
  status?: string;
  text: string;
  color: string;
  display?: boolean;
  border?: string;
  isStripes?: boolean;
  position?: PositionType;
  columnNumber?: number;
};

interface ILegendProps {
  onLegendRender?: (defaultRender: () => JSX.Element) => JSX.Element;
  isColorFilling?: boolean;
  setColorFilling?: (val: boolean) => void;
  legends?: {
    type: "line" | "point",
    items: ILegend[],
    header?: string,
  }[],
  servicePageLegend?: boolean;
}

const getMarker = (item: ILegend) => {
  return (
    <div key={item.text}>
      <div>
        <div
          className={styles.legendRectangular}
          style={{
            background: item.isStripes
              ? `repeating-linear-gradient(-45deg, ${item.color}, 4px, ${WHITE_COLOR} 4px, ${WHITE_COLOR} 7px)`
              : item.color,
          }}
        />
      </div>
      {item.text}
    </div>
  );
};

export const Legend = (props: ILegendProps) => {
  const { loc } = useContext(LanguageContext);

  const onRenderContent = () => (
    <>
      {props.setColorFilling && <BasicToggle
        value={props.isColorFilling || false}
        setValue={props.setColorFilling}
        onText={loc.labels.withColorFilling}
        offText={loc.labels.withoutColorFilling}
      />}
      {map(props.legends, legend => legend.type === "point" ? (
        <div key={legend.type}>
          {legend.header?.length ? <h2>{legend.header}</h2> : null}
          <div className={styles.legendContent}>
            {map(legend.items, item => (
              <div key={item.text}>
                <div>
                  <div
                    className={classNames(styles.legendMarker, "legendMarker")}
                    style={{
                      backgroundColor: item.color,
                      border: item.border,
                      boxSizing: item.border ? "border-box" : "initial",
                    }}
                  />
                </div>
                <div className={styles.legendText}>{item.text}</div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div key={legend.type}>
          {legend.header?.length ? <h2>{legend.header}</h2> : null}
          <div className={styles.legendContent}>
            {props.servicePageLegend ? (
              <div className={styles.servicePageLegend}>
                <div className={styles.legendColumn}>
                  {map(legend.items, item => {
                    return item.columnNumber === 1 ? (
                      <>{getMarker(item)}</>
                    ) : null;
                  })}
                </div>
                <div className={styles.legendColumn}>
                  {map(legend.items, item => {
                    return item.columnNumber === 2 ? (
                      <>{getMarker(item)}</>
                    ) : null;
                  })}
                </div>
                <div className={styles.legendColumn}>
                  {map(legend.items, item => {
                    return item.columnNumber === 3 ? (
                      <>{getMarker(item)}</>
                    ) : null;
                  })}
                </div>
                <div className={styles.legendColumn}>
                  {map(legend.items, item => {
                    return item.columnNumber === 4 ? (
                      <>{getMarker(item)}</>
                    ) : null;
                  })}
                </div>
              </div>
            ) : map(legend.items, (item) => {
              return (
                <div key={item.text}>
                  <div>
                    <div
                      className={styles.legendRectangular}
                      style={{
                        background: item.isStripes
                          ? `repeating-linear-gradient(-45deg, ${item.color}, 4px, ${WHITE_COLOR} 4px, ${WHITE_COLOR} 7px)`
                          : item.color,
                      }}
                    />
                  </div>
                  {item.text}
                </div>);

            })}
          </div>
        </div>
      ))}
    </>
  );

  return (
    <TooltipHost
      hostClassName={styles.legend}
      directionalHint={DirectionalHint.bottomRightEdge}
      tooltipProps={{ onRenderContent: props.onLegendRender?.bind(null, onRenderContent) || onRenderContent }}
      calloutProps={{
        isBeakVisible: !props.setColorFilling,
        className: props.servicePageLegend ? styles.serviceLegend : ""
      }}
      closeDelay={50000}
    >
      <FontIcon iconName="color" />
      {loc.legend.title}
    </TooltipHost>
  );
};