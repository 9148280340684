import { useEffect, useMemo, useState } from "react";
import { ListIterator } from "lodash";
import { IObjectWithKey, ISelection, Selection } from "@fluentui/react";
import { find, findIndex, forEach, isUndefined } from "lodash-es";

export interface IUseSelection<T> {
  selection: ISelection<IObjectWithKey>;
  selectedItem: T | undefined;
}

export const useSelection = <T>(): IUseSelection<T> => {
  const [selectedItem, setSelectedItem] = useState<T>();
  const selection: ISelection = useMemo(
    () =>
      new Selection({
        onSelectionChanged: () => setSelectedItem(selection.getSelection()[0] as T),
      }),
    [],
  );
  return { selection, selectedItem };
};

export interface IUseMultiSelection<T> {
  selection: ISelection<IObjectWithKey>;
  selectedItems: T[];
  selectAll: (val?: boolean) => void;
}

export const useMultiSelection = <T>(items: T[], idType?: keyof T, limitedSelection?: boolean): IUseMultiSelection<T> => {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);
  const createSelection: () => ISelection = () => new Selection({
    onSelectionChanged: () => setSelectedItems(selection?.getSelection() as T[]),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    canSelectItem: (item: any) => item?.nameEn !== "Total" && !(limitedSelection && item.isParent),
  });
  const [selection, setSelection] = useState<ISelection<IObjectWithKey>>(createSelection());
  const defineMultiselection = (selectedItems: T[]) => {
    selection.setAllSelected(false);
    forEach(selectedItems, (selectedItem: T) => {
      const item = find(items, {[String(idType)]: (selectedItem as T)[String(idType) as keyof T]});
      const index = !isUndefined(item) ? findIndex(items, item as ListIterator<T, boolean>) : -1;
      index !== -1 && selection.setIndexSelected(index, true, true);
    });
  };

  const selectAll = (val = true) => {
    selection.setAllSelected(val);
    setSelection(selection);
  };
  
  useEffect(() => {
    defineMultiselection(selectedItems);
  }, [items]);

  return { selection, selectedItems, selectAll };
};
