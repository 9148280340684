import { IColumn, Icon, TooltipHost } from "@fluentui/react";
import { SearchDeviceBrowseDto, SearchDeviceDocumentBrowseDto } from "generated-sources/openapi";
import loc from "localization";
import { GREEN_COLOR, RED_COLOR, SKY_BLUE_COLOR, YELLOW_COLOR } from "constants/colors";
import { transformDateToString } from "utils";
import { AppLanguage, DocumentCode } from "constants/enums";
import classNames from "classnames";
import invoiceStyles from "components/pages/InvoicesPage/styles.module.scss";
import registerIcon from "images/register-icon.svg";
import { Marker } from "components/common/Marker";
import { ILegend } from "components/common/Legend";
import styles from "../../../SearchBySerial/styles.module.scss";

export const getWarehousesColumns = (
  language: AppLanguage,
  openDeviceModal: (deviceId: number, firmId: number) => void
): IColumn[] => [
  {
    key: "areaName",
    name: loc.columns.status,
    fieldName: "areaName",
    minWidth: 110,
    maxWidth: 120,
    isResizable: true,
    onRender: ((item: SearchDeviceBrowseDto) => {
      return (
        <>
          {language === AppLanguage.English ? item.areaNameEn : item.areaNameRu}
        </>
      );
    })
  },
  {
    key: "company",
    name: loc.columns.company,
    fieldName: "counterpartyName",
    minWidth: 150,
    maxWidth: 150,
    isResizable: true,
  },
  {
    key: "owner",
    name: loc.columns.owner,
    fieldName: "deviceOwner",
    minWidth: 30,
    maxWidth: 30
  },
  {
    key: "sku",
    name: loc.columns.sku,
    fieldName: "sku",
    minWidth: 80,
    maxWidth: 80,
    isResizable: true,
  },
  {
    key: "se",
    name: loc.columns.se,
    fieldName: "se",
    minWidth: 60,
    maxWidth: 60,
    isResizable: true,
  },
  {
    key: "name",
    name: loc.columns.name,
    fieldName: language === AppLanguage.English ? "nameEn" : "nameRu",
    minWidth: 150,
    maxWidth: 150,
    isResizable: true,
  },
  {
    key: "serialN",
    name: loc.columns.serialN,
    fieldName: "serial",
    isResizable: true,
    minWidth: 100,
    maxWidth: 100,
    onRender: (item: SearchDeviceBrowseDto) => {
      return (
        <div
          className={styles.serialCell}
          onClick={() => openDeviceModal(item.replacedDeviceId || item.deviceId || 0, item.firmId || 0)}
        >
          {item.serial}
        </div>
      );
    }
  },
  {
    key: "newSerial",
    name: loc.columns.serialNew,
    fieldName: "newSerial",
    isResizable: true,
    minWidth: 150,
    maxWidth: 150,
  }
];

export const getCompaniesColumns = (): IColumn[] => [
  {
    key: "doc-code",
    name: "",
    minWidth: 15,
    maxWidth: 15,
    isResizable: false,
    onRender: (item: SearchDeviceDocumentBrowseDto) => {
      if (item.documentCode === DocumentCode.Incoming) {
        return (
          <TooltipHost content={loc.invoicesPage.incomingInvoices}>
            <Icon
              className={classNames(invoiceStyles.docStatusIcon, invoiceStyles.docStatusIconPlus)}
              iconName="CirclePlus"
            />
          </TooltipHost>
        );
      } else if (item.documentCode === DocumentCode.Outgoing) {
        return (
          <TooltipHost content={loc.invoicesPage.outgoingInvoices}>
            <Icon
              className={classNames(invoiceStyles.docStatusIcon, invoiceStyles.docStatusIconMinus)}
              iconName="SkypeCircleMinus"
            />
          </TooltipHost>
        );
      } else if (item.documentCode === DocumentCode.IncomingInvoiceInternal) {
        return (
          <TooltipHost content={loc.invoicesPage.iternalIncomingInvoices}>
            <Icon
              className={classNames(invoiceStyles.docStatusIcon, invoiceStyles.docStatusIconIternal)}
              iconName="CirclePlus"
            />
          </TooltipHost>
        );
      } else if (item.documentCode === DocumentCode.OutgoingInvoiceInternal) {
        return (
          <TooltipHost content={loc.invoicesPage.iternalOutgoingInvoices}>
            <Icon
              className={classNames(invoiceStyles.docStatusIcon, invoiceStyles.docStatusIconIternal)}
              iconName="SkypeCircleMinus"
            />
          </TooltipHost>
        );
      } else if (item.documentCode === DocumentCode.RMA) {
        return (
          <TooltipHost content={loc.invoicesPage.rma}>
            <img src={registerIcon} width={20}/>
          </TooltipHost>
        );
      } else {
        return null;
      }
    }
  },
  {
    key: "company",
    name: loc.columns.company,
    fieldName: "counterpartyName",
    minWidth: 150,
    maxWidth: 150,
    isResizable: true,
    onRender: (item: SearchDeviceDocumentBrowseDto) => {
      return (
        <>
          { item.documentCode === "99" && <Marker color={GREEN_COLOR} text={loc.searchBySerial.rma}/> }
          { item.isShipped && <Marker color={SKY_BLUE_COLOR} text={loc.searchBySerial.sentToDistributor}/> }
          { item.isRefund && <Marker color={RED_COLOR} text={loc.searchBySerial.returnFromCustomer}/> }
          { item.counterpartyName }
        </>
      );
    }
  },
  {
    key: "documentNumber",
    name: loc.columns.documentN,
    fieldName: "documentNumber",
    minWidth: 90,
    maxWidth: 110,
    isResizable: true,
  },
  {
    key: "documentDate",
    name: loc.columns.dateDocument,
    fieldName: "documentDate",
    minWidth: 120,
    maxWidth: 120,
    isResizable: true,
    onRender: (item) => {
      return transformDateToString(item.documentDate);
    },
  },
  {
    key: "receiveDate",
    name: loc.columns.receiveDate,
    fieldName: "receiveDate",
    minWidth: 110,
    maxWidth: 130,
    isResizable: true,
    onRender: (item) => {
      return transformDateToString(item.receiveDate);
    },
  },
  {
    key: "deliveryCompany",
    name: loc.labels.deliveryService,
    fieldName: "deliveryCompany",
    minWidth: 110,
    maxWidth: 130,
    isResizable: true
  },
  {
    key: "trackingNumber",
    name: loc.columns.trackingNumber,
    fieldName: "trackingNumber",
    minWidth: 90,
    maxWidth: 90,
    isResizable: true,
  }
];

export const getMarkerLegend = (firmId: string) => (item: SearchDeviceBrowseDto): ILegend[] => [
  {
    color: YELLOW_COLOR,
    text: loc.searchBySerial.externalSc,
    display: !!item.area && item.area !== firmId + "05"
  }
];