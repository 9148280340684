import { createContext, useContext, useEffect, useState } from "react";
import { filter } from "lodash-es";
import { AxiosResponse } from "axios";
import { useApi, useHttp } from "hooks";
import { CheckUpdatesContext, ISignalRNotificationData } from "./checkUpdatesContext";
import { SignalRNotificationType } from "constants/enums";
import { LockedDeviceBrowseDto } from "generated-sources/openapi";

export interface IProps {
  children: JSX.Element | JSX.Element[];
}

export interface ITodayEvent {
  type: string;
  data: {
    [key: string]: number;
  };
}

interface ITodayEventsContext {
  lockedDevices: LockedDeviceBrowseDto[];
  todayEvents: ITodayEvent[];
  addEvent: (event: ITodayEvent) => void;
}

export const TodayEventsContext = createContext<ITodayEventsContext>({
  lockedDevices: [],
  todayEvents: [],
  addEvent: () => undefined,
});

export const TodayEventsContextProvider = (props: IProps) => {
  const { todayEventsApi, devicesApi } = useApi();
  const { request } = useHttp();
  const { connection } = useContext(CheckUpdatesContext);
  const [todayEvents, setTodayEvents] = useState<ITodayEvent[]>([]);
  const [lockedDevices, setLockedDevices] = useState<LockedDeviceBrowseDto[]>([]);

  const getTodayEvents = () => {
    request(() => todayEventsApi.apiUtilsTodayEventsBrowseGet())
      .then((res: AxiosResponse) => setTodayEvents(res.data));
  };

  const getLockedDevices = () => {
    request(() => devicesApi.apiOpDevicesLockedListGet())
      .then((res: AxiosResponse) => setLockedDevices(res.data));
  };

  const addEvent = (event: ITodayEvent) => {
    setTodayEvents(events => [...events, event]);
  };

  useEffect(() => {
    getTodayEvents();
    getLockedDevices();
  }, []);

  useEffect(() => {
    if (connection) {
      connection?.on("NewEvent", (type: SignalRNotificationType, data: ISignalRNotificationData) => {
        console.log("NewEvent from today events context", type, data);
        addEvent({ type, data: data.payload });
        if (type === SignalRNotificationType.DeviceLock || type === SignalRNotificationType.DeviceUnlock) {
          // const deviceAlreadyLocked = Boolean(find(lockedDevices, device => (
          //   device.deviceId === data.payload.deviceId
          //   && device.firmId === data.payload.deviceFirmId
          // )));
          setLockedDevices(devices => {
            if (type === SignalRNotificationType.DeviceLock) {
              return [...devices, {
                deviceId: data.payload.deviceId,
                firmId: data.payload.deviceFirmId,
                tn: data.payload.tn,
                fio: data.payload.fio,
              }];
            }
            if (type === SignalRNotificationType.DeviceUnlock) {
              return filter(devices, device => !(
                device.deviceId === data.payload.deviceId
                && device.firmId === data.payload.deviceFirmId
              ));
            };
            return devices;
          });
        };
      });
    };
  }, [connection]);

  return (
    <TodayEventsContext.Provider value={{ lockedDevices, todayEvents, addEvent }}>
      {props.children}
    </TodayEventsContext.Provider>
  );
};
