import React, { Suspense, useContext } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import {
  ACCESS_DENIED_PAGE_PATH,
  DASHBOARD_PAGE_PATH,
  ROOT_PATH,
  REFERENCE_BOOKS_PATH,
  OPERATIONS_PATH,
  NOTIFICATIONS_PATH,
  REPORTS_PATH,
} from "constants/paths";
import { AuthContext } from "context/authContext";
import { PageWrapper } from "components/common/PageWrapper";
import { DashboardPage } from "components/pages/DashboardPage";
import { AccessDeniedPage } from "components/pages/AccessDeniedPage";
import { NotFoundPage } from "components/pages/NotFoundPage";
import { ProtectedRoute } from "./protectedRoute";

const ReferenceBooksRouter = React.lazy(() => import("./router.referenceBooks"));
const OperationsRouter = React.lazy(() => import("./router.operations"));
const NotificationsPage = React.lazy(() => import("components/pages/NotificationsPage").then(module => ({ default: module.NotificationsPage })));
const ReportsRouter = React.lazy(() => import("./router.reports"));

export const ApplicationRouter = () => {
  const { permissions } = useContext(AuthContext);
  return (
    <Router>
      <Suspense fallback={<PageWrapper />}>
        <PageWrapper>
          <Routes>
            <Route path={ROOT_PATH} element={<Navigate to={DASHBOARD_PAGE_PATH} />} />
            <Route
              path={DASHBOARD_PAGE_PATH}
              element={
                <ProtectedRoute isAllowed={permissions.dashboardPage}>
                  <DashboardPage />
                </ProtectedRoute>
              }
            />
            <Route path={`${REFERENCE_BOOKS_PATH}/*`} element={<ReferenceBooksRouter />} />
            <Route path={`${OPERATIONS_PATH}/*`} element={<OperationsRouter />} />
            <Route path={`${REPORTS_PATH}/*`} element={<ReportsRouter />} />
            <Route
              path={NOTIFICATIONS_PATH}
              element={
                <ProtectedRoute isAllowed={true}>
                  <NotificationsPage />
                </ProtectedRoute>
              }
            />
            <Route path={ACCESS_DENIED_PAGE_PATH} element={<AccessDeniedPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </PageWrapper>
      </Suspense>
    </Router>
  );
};
