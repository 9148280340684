import { RequiredField } from "components/common/RequiredField";
import styles from "./styles.module.scss";
import classNames from "classnames";

interface IInfoContainerProps {
  label?: string;
  isRequired?: boolean;
  children: JSX.Element;
  infoContainerLabelClassName?: string;
  infoContainerValueClassName?: string;
  infoContainerClassName?: string;
}

export const InfoContainer = (props: IInfoContainerProps) => {
  return (
    <div className={classNames(styles.infoContainer, props.infoContainerClassName) }>
      {props.label && (
        <div className={classNames(styles.infoContainerLabel, props.infoContainerLabelClassName)}>
          {props.isRequired ? <RequiredField text={props.label} /> : props.label}
        </div>
      )}
      <div className={classNames(styles.infoContainerValue, props.infoContainerValueClassName)}>{props.children}</div>
    </div>
  );
};
