import { AppLanguage } from "constants/enums";
import loc from "localization";

export const getColumns = (language: AppLanguage) => [
  {
    key: "sku",
    name: loc.columns.sku,
    fieldName: "sku",
    minWidth: 80,
    maxWidth: 80,
    isResizable: true,
  },
  {
    key: "se",
    name: loc.columns.se,
    fieldName: "se",
    minWidth: 60,
    maxWidth: 60,
    isResizable: true,
  },
  {
    key: "nameRu",
    name: loc.columns.name,
    fieldName: language === AppLanguage.Russian ? "nameRu" : "nameEn",
    minWidth: 220,
    maxWidth: 220,
    isResizable: true,
  },
];
