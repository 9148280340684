import React, { createContext, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { createBrowserHistory } from "history";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { APP_VERSION, INSTRUMENTATION_KEY } from "constants/appConstants";
import { AuthUserDto } from "generated-sources/openapi";

export interface Props {
  children: JSX.Element | JSX.Element[];
}

interface IAppInsightsContext {
  trackView: (user: AuthUserDto | null, message: string, props?: object) => void;
  trackTrace: (user: AuthUserDto | null, message: string, props?: object) => void;
  updateTraceId: () => void;
}

export const AppInsightsContext = createContext<IAppInsightsContext>({
  trackView: () => {
    throw new Error("Not implemented");
  },
  trackTrace: () => {
    throw new Error("Not implemented");
  },
  updateTraceId: () => {
    throw new Error("Not implemented");
  },
});

export const AppInsightsContextProvider = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [appInsights, setAppInsights] = useState<any>(null);
  const [traceId, setTraceId] = useState<string>(uuid());

  const updateTraceId = () => {
    const newTraceId = uuid();
    setTraceId(newTraceId);
  };

  const trackTrace = (user: AuthUserDto | null, message: string, props?: object) => {
    appInsights?.trackTrace(
      { message },
      {
        Application: "Yukon.Service.WebSpa",
        version: APP_VERSION,
        user: `${user?.workEmail} (${user?.workNumber})`,
        traceId,
        ...props,
      },
    );
  };

  const trackView = (user: AuthUserDto | null, name: string, props?: object) => {
    appInsights?.trackEvent(
      { name },
      {
        Application: "Yukon.Service.WebSpa",
        user: `${user?.workEmail} (${user?.workNumber})`,
        version: APP_VERSION,
        traceId,
        ...props,
      },
    );
  };

  useEffect(() => {
    const browserHistory = createBrowserHistory();
    const reactPlugin = new ReactPlugin();
    const appInsightsObj = new ApplicationInsights({
      config: {
        connectionString: [
          `InstrumentationKey=${INSTRUMENTATION_KEY}`,
          "IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/",
          "LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/",
        ].join(";"),
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
      },
    });
    // if (process.env.REACT_APP_ENV !== "DEV") {
    setAppInsights(appInsightsObj);
    // }
  }, []);

  useEffect(() => {
    if (appInsights) {
      appInsights?.loadAppInsights();
    }
    // appInsights.trackPageView();
  }, [appInsights]);

  return (
    <AppInsightsContext.Provider value={{ trackView, trackTrace, updateTraceId }}>
      {props.children}
    </AppInsightsContext.Provider>
  );
};
