import { NumberField } from "components/common/NumberField";
import { AppLanguage } from "constants/enums";
import { ReplacementCEDetailsBrowseDto } from "generated-sources/openapi";
import loc from "localization";

export const getColumns = (language: AppLanguage, onChange: (item: ReplacementCEDetailsBrowseDto) => void) => [
  {
    key: "se",
    name: loc.columns.se,
    fieldName: "se",
    minWidth: 60,
    maxWidth: 60,
    isResizable: true,
  },
  {
    key: "nameRu",
    name: loc.columns.name,
    fieldName: language === AppLanguage.Russian ? "nameRu" : "nameEn",
    minWidth: 300,
    maxWidth: 300,
    isResizable: true,
  },
  {
    key: "kol",
    name: loc.columns.qty,
    fieldName: "kol",
    minWidth: 100,
    maxWidth: 100,
    isResizable: true,
    onRender: (item: ReplacementCEDetailsBrowseDto) => (
      <NumberField
        item={item}
        name="kol"
        integer={10}
        fract={0}
        value={`${item.kol}`}
        onChange={(item: ReplacementCEDetailsBrowseDto, field: string, value?: string) => onChange({ ...item, [field]: value })}
      />
    ),
  },
];
