import { AxiosResponse } from "axios";
import { AppInsightsContext } from "context/appInsightsContext";
import { AuthContext } from "context/authContext";
import loc from "localization";
import { useCallback, useContext, useMemo, useState } from "react";
import { defineError, errorNotification } from "utils";

export interface IUseHttp<T> {
  isLoading: boolean;
  isFailed: boolean;
  isDone: boolean;
  error: unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorMessages: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request: (fn: () => Promise<AxiosResponse<T, any>>) => Promise<AxiosResponse<T, any>>;
}

interface IUseHttpProps {
  initialLoading?: boolean;
  showErrors?: boolean;
}
export const useHttp = <T>(props?: IUseHttpProps): IUseHttp<T> => {
  const { trackTrace } = useContext(AppInsightsContext);
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(!!props?.initialLoading);
  const [error, setError] = useState<unknown>(null);
  const errorMessages = useMemo(() => defineError(error), [error]);

  const request = useCallback(async (fn: () => Promise<AxiosResponse<T>>) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise<AxiosResponse<T>>(async (resolve) => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fn();
        resolve(response);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error("unhandled error", error);
        if (error?.response?.data instanceof Blob) {
          error?.response?.data.text()
            .then((res: string) => {
              setError(JSON.parse(res));
            });
        } else {
          setError(error);
        };
        if (error.response.status === 500 || (
          error.response.status === 400 && !Object.keys(loc.badOperations).includes(error.response?.data?.detail)
        )) {
          trackTrace(user, `Unhadled error: ${error.response?.data?.detail}`, {
            page: window.location.pathname,
            request: error.config.url,
            correlationId: error.response.data.CorrelationId,
          });
        };
        if (error.response.status === 500) {
          errorNotification(error);
        } else if (props?.showErrors) {
          const errorMessage = defineError(error);
          errorNotification(errorMessage ? new Error(errorMessage) : error);
        }
      } finally {
        setIsLoading(false);
      }
    });
  }, []);

  return {
    isLoading,
    isFailed: !!error,
    isDone: !isLoading && !error,
    error,
    errorMessages,
    request
  };
};
