import { useContext, useEffect, useMemo, useState } from "react";
import { map, reduce } from "lodash-es";
import { AxiosResponse } from "axios";
import { DefaultButton, SelectionMode } from "@fluentui/react";
import loc from "localization";
import { useApi, useHttp, useMultiSelection } from "hooks";
import { useFormStockAreas } from "hooks/useFormStockAreas";
import { namedSuccessNotification } from "utils";
import { DeviceAddedSeDto, StockAreaType } from "generated-sources/openapi";
import { AppLanguage, CommandbarCommands } from "constants/enums";
import { LanguageContext } from "context/languageContext";
import { ScrollableTableWrapper, Table } from "components/common/Table";
import { onRenderRow } from "components/pages/PermissionsRMAPage/RMAPanel/config";
import { CommandWrapper } from "components/common/CommandWrapper";
import { DeleteModal } from "../../DeleteModal";
import { getColumns } from "./config";
import styles from "../ReplacedSes/styles.module.scss";

export interface IReplacedSesProps {
  basicPayload: { firmId: number, deviceId: number };
  addedSes: DeviceAddedSeDto[];
  setAddedSes: (callback: (defects: DeviceAddedSeDto[]) => DeviceAddedSeDto[]) => void;
  setActiveCommand: (command: CommandbarCommands) => void;
  updateState: () => void;
  replacedSesSum: number;
  readonly?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
};

export const AddedSes = (props: IReplacedSesProps) => {
  const { language } = useContext(LanguageContext);
  const { stockAreas, getStockAreas, isLoading } = useFormStockAreas();
  const { devicesApi } = useApi();
  const devicesHttp = useHttp();
  const updateDeviceHttp = useHttp({showErrors: true});
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [tableIsOpen, setTableIsOpen] = useState<boolean>(false);
  const [sumHasBeenChanged, setSumHasBeenChanged] = useState<boolean>(false);
  const { selection, selectedItems } = useMultiSelection<DeviceAddedSeDto>(props.addedSes);

  const onChange = (item: DeviceAddedSeDto) => {
    props.setAddedSes(addedSes => map(
      map(addedSes, addedSe => addedSe.seK === item.seK ? item : addedSe),
      (addedSe, _, array) => addedSe.nameEn === "Total"
        ? {
          ...addedSe,
          kol: reduce(array, (prev, next, i) => i === array.length - 1 ? prev : prev + Number(next.kol), 0),
          sum: reduce(array, (prev, next, i) => i === array.length - 1 ? prev : prev + Number(next.price) * Number(next.kol), 0),
        }
        : addedSe
    ));
  };

  const onBlur = (item: DeviceAddedSeDto) => {
    devicesHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdAddedCeSePut({
      ...props.basicPayload,
      se: `${item.seK}`,
      deviceUpdateAddedCEPayload: {
        kol: item.kol,
        skl: item.skl,
        price: item.price,
      },
    }))
      .then((res: AxiosResponse) => {
        if (res.status === 200) {
          props.updateState();
          setSumHasBeenChanged(true);
        };
      });
  };

  const setRepairCost = () => {
    props.formik.setFieldValue("previousCostRem", props.formik.values.stoimRem);
    updateDeviceHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesSetRepairCostPost({
      ...props.basicPayload,
      deviceSetRepairCostPayload: {
        stoimRem: ((props.addedSes[props.addedSes.length - 1] as DeviceAddedSeDto & { sum: number })?.sum + (props.replacedSesSum || 0)),
        valRem: props.formik.values.valRem
      },
    })).then((res) => {
      namedSuccessNotification(res, loc.notifications.changeSavedSuccessfully);
      props.updateState();
      setSumHasBeenChanged(false);
    });
  };

  const cancelInvoice = () => {
    updateDeviceHttp.request(() => devicesApi.apiOpDevicesDeviceDeviceIdFirmFirmIdFeaturesCancelInvoicePost({
      ...props.basicPayload
    }))
      .then((res) => {
        namedSuccessNotification(res, loc.notifications.changeSavedSuccessfully);
        props.formik.setFieldValue("schet", false);
        props.formik.handleSubmit();
        setSumHasBeenChanged(false);
        props.updateState();
      });
  };
  
  useEffect(() => {
    getStockAreas(StockAreaType.InnerManufacturing);
  }, []);

  const totalSum = useMemo(() => (
    (props.addedSes[props.addedSes.length - 1] as DeviceAddedSeDto & { sum: number })?.sum + (props.replacedSesSum || 0)
  ), [props.addedSes, props.replacedSesSum]);

  const stockAreasOptions = useMemo(() => map(stockAreas, area => ({
    key: `${area.skl}`,
    text: `${area.skl} ${language === AppLanguage.Russian ? area.naimRu : area.naimEn}`,
  })), [stockAreas, language]);

  const columns = useMemo(() => getColumns(
    language,
    stockAreasOptions,
    isLoading,
    onChange,
    onBlur,
    props.readonly
  ), [language, stockAreasOptions]);

  return (
    <div>
      <div className="table-title">
        <div>
          <DefaultButton
            className="show-table"
            iconProps={{ iconName: "Send" }}
            onClick={() => setTableIsOpen(!tableIsOpen)}
          />
          <b onClick={() => setTableIsOpen(!tableIsOpen)}>{loc.labels.additionalCe}</b>
        </div>
        {tableIsOpen ? (
          <>
            <DefaultButton
              iconProps={{ iconName: "add" }}
              onClick={() => props.setActiveCommand(CommandbarCommands.AddedList)}
              disabled={props.readonly}
            />
            <DefaultButton
              iconProps={{ iconName: "delete" }}
              disabled={!selectedItems.length || props.readonly}
              onClick={() => setDeleteModal(true)}
            />
          </>
        ) : null}
      </div>
      {tableIsOpen ? (
        <>
          <div className="scrollable-defects-wrapper">
            <ScrollableTableWrapper noData={!props.addedSes?.length}>
              <Table
                items={props.addedSes}
                columns={columns}
                onRenderRow={onRenderRow}
                selectionMode={SelectionMode.multiple}
                selection={selection}
              />
            </ScrollableTableWrapper>
          </div>
          {!props.formik.values.warranty && (
            <div className={styles.saveButtonWrapper}>
              <DefaultButton text={loc.buttons.save} disabled={!sumHasBeenChanged} onClick={() => Number(totalSum) ? setRepairCost() : cancelInvoice()} />
            </div>
          )}
        </>
      ) : null}
      <CommandWrapper isActive={deleteModal}>
        <DeleteModal
          text={loc.deviceModal.deleteAddedSeTitle}
          type="added"
          payload={{ ses: map(selectedItems, item => item.seK) }}
          basicPayload={props.basicPayload}
          updateState={() => {
            setSumHasBeenChanged(true);
            props.updateState();
          }}
          onDismiss={() => setDeleteModal(false)}
        />
      </CommandWrapper>
    </div>
  );
};