import { useContext } from "react";
import { LanguageContext } from "context/languageContext";
import { PageWrapperContent } from "components/common/PageWrapper/Content";
import styles from "./styles.module.scss";

export const AccessDeniedPage = () => {
  const { loc } = useContext(LanguageContext);
  return (
    <PageWrapperContent>
      <h5 className={styles.errorMessage}>{loc.warnings.accessDenied}</h5>
    </PageWrapperContent>
  );
};
