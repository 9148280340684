import { TooltipHost } from "@fluentui/react";
import { isUndefined } from "lodash-es";
import styles from "./styles.module.scss";
import { LIGHT_GREY_COLOR } from "constants/colors";
import { PositionType } from "../Legend";

export interface IMarkerProps {
  text?: string | null;
  color?: string;
  position?: PositionType;
  display?: boolean;
  isStripes?: boolean;
}

export const Marker = (props: IMarkerProps) => {
  const display = isUndefined(props.display) ? true : props.display;
  return display ? (
    <div className={styles.markerWrapper} style={{ zIndex: 10 - (props.position || 0) }}>
      <TooltipHost
        content={props.text ? props.text : ""}
        hostClassName={styles.marker}
        styles={{ root: {
          backgroundColor: props.color,
          background: props.isStripes ? `
          repeating-linear-gradient(-45deg, ${props.color || LIGHT_GREY_COLOR}, ${props.color || LIGHT_GREY_COLOR} 4px, ${LIGHT_GREY_COLOR} 4px, ${LIGHT_GREY_COLOR} 7px);
        ` : undefined,
          marginLeft: props.position ? (props.position * 6) : 0,
        } }}
      />
    </div>
  ) : null;
};
